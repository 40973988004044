import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { getPurchasedItems, sellItem } from "../../redux/actions/purchase";
import CartList from "./make-sales/CartList";
import ItemSelection from "./make-sales/ItemSelection";
import moment from "moment";
import {
  CASH,
  MODES_OF_PAYMENT,
  PAYABLE,
  STORE,
  TRANSACTION_TYPES,
} from "../../constants";
import { useSelector } from "react-redux";
import { saveTransaction } from "../../redux/actions/transactions";
import {
  chargeCustomer,
  saveCustomerDepositTxnToCache,
  saveCustomerTxnToCache,
  saveNewCustomer,
} from "../../redux/actions/customer";
import { v4 as UUIDV4 } from "uuid";
import NewCustomerModal from "./make-sales/NewCustomerModal";
import { useHistory } from "react-router";
import { getStoresList } from "../../redux/actions/stores";

function MakeSale() {
  const buz_id = useSelector(
    (state) => state.auth.activeBusiness.business_admin
  );
  const user_id = useSelector((state) => state.auth.user);
  const check = parseInt(buz_id) === parseInt(user_id.id);
  const [activeStore, setActiveStore] = useState(user_id.branch_name);
  const initialForm = useMemo(
    () => ({
      item_name: "",
      quantity_sold: "1",
      store_name: activeStore,
    }),
    [activeStore]
  );

  const qttyRef = useRef();
  const itemNameRef = useRef();
  const amountPaidRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const [filterText, setFilterText] = useState("");
  const itemList = useSelector((state) => state.purchase.purchaseList);
  const [cart, setCart] = useState([]);
  const [form, setForm] = useState(initialForm);
  const [selectedCustomer, setSelectedCustomer] = useState({});

  const [otherInfo, setOtherInfo] = useState({
    discount: 0,
    modeOfPayment: MODES_OF_PAYMENT.CASH,
    amountPaidDefault: 0,
    amountPaid: null,
    total: 0,
  });

  const [isNewCustomerModalOpen, setIsNewCustomerModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [submitting, setSubmitting] = useState(false);

  const activeBusiness = useSelector((state) => state.auth.activeBusiness);
  // const user = useSelector((state) => state.auth.user);

  const handleDelete = (i) => {
    let newVal = cart.filter((item, index) => i !== index);
    setCart(newVal);
  };
  const options = useSelector((state) => state.stores.storeList);
  useEffect(() => {
    dispatch(getStoresList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPurchasedItems(activeStore));
  }, [dispatch, activeStore]);

  const selectItem = (item) => {
    setForm((p) => ({ ...p, ...item, store_name: item.storeName }));
    // qttyRef.current.focus();
    qttyRef.current.select();
  };

  const formIsValid =
    form.item_name &&
    form.quantity_sold !== "0" &&
    form.quantity_sold !== 0 &&
    form.quantity_sold !== "";

  const addToCart = useCallback(() => {
    console.log("adding to cart", form);
    if (formIsValid) {
      if (parseInt(form.quantity_sold) > parseInt(form.quantity)) {
        alert(`Quantity requested is more than the quantity available.`);
      } else {
        // let selling_price = parseFloat(form.cost) + parseFloat(form.markup)
        console.error({ selectedCustomer: selectedCustomer });
        let total = form.selling_price * parseFloat(form.quantity_sold);
        let id = UUIDV4();
        let itemObj = {
          id,
          patientAcc: otherInfo.accountNo,
          accName: selectedCustomer.accName,
          name: selectedCustomer.accName,
          price: form.selling_price,
          quantity: form.quantity_sold,
          balance: form.quantity,
          item_name: form.item_name,
          item_category: form.itemCategory,
          amount: total,
          customerId: selectedCustomer.id,
          item_id: form._id,
          salesFrom: form.store_name,
          item_code: form.item_code,
          discount: otherInfo.discount,
          imeiText: form.imeiText,
          imeiImage: form.imeiImage,
          truckNo: form.truckNo,
          waybillNo: form.waybillNo,
          supplier_code: form.supplier_code,
          supplier_name: form.supplierName,
          supplierName: form.supplierName,
          expiry_date: form.expiry_date,
          activeStore,
        };
        setCart((p) => [...p, itemObj]);
        setForm(initialForm);
        itemNameRef.current?.focus();
      }
    } else {
      console.log("Item not selected");
    }
  }, [
    form,
    initialForm,
    formIsValid,
    otherInfo,
    selectedCustomer,
    activeStore,
  ]);

  const checkout = useCallback(() => {
    console.log("Called checkout...........");
    setIsNewCustomerModalOpen(false);
    setSubmitting(true);
    let receiptNo = moment().format("YYMDhms");
    let totalAmount = parseFloat(otherInfo.amountPaid);
    // let sumIndividualDiscount = data.reduce(
    //   (a, b) => a + parseFloat(b.discount),
    //   0
    // )
    let totalDiscount = parseFloat(otherInfo.discount);
    let txn = [];
    cart.forEach((item, index) => {
      let lastIndex = cart.length - 1;
      let itemList = cart.map((i) => i.item_name).join(", ");
      txn.push({
        _id: item.id,
        source: STORE,
        product_code: item.item_name,
        dr: parseFloat(item.amount),
        amount: parseFloat(item.amount),
        selling_price: item.price,
        cr: 0,
        business_bank_acc_no: activeBusiness.id,
        destination: CASH,
        quantity: item.quantity,
        description: item.item_name,
        category: item.item_category,
        receiptNo,
        discount: index === lastIndex ? totalDiscount : "0",
        customerId: selectedCustomer.id,
        clientAccount: selectedCustomer.accountNo,
        customerName: selectedCustomer.accName,
        transaction_type: "NEW_SALE",
        branch_name: item.salesFrom,
        amountPaid: otherInfo.amountPaid,
        totalAmount: txn.total,
        modeOfPayment: otherInfo.modeOfPayment,
        imeiText: item.imeiText,
        imeiImage: item.imeiImage,
        truckNo: item.truckNo,
        waybillNo: item.waybillNo,
        itemList,
        txn_type:
          parseInt(otherInfo.amountPaid) < parseInt(txn.total)
            ? "Part Payment"
            : "Full Payment",
        supplier_code: item.supplier_code,
        supplierName: item.supplierName,
        supplier_name: item.supplier_name,
        item_id: item.item_id,
        salesFrom: item.salesFrom,
        item_code: item.item_code,
        expiry_date: item.expiry_date,
        expiring_date: item.expiry_date,
      });
    });
    console.error({ selectedCustomer });
    saveTransaction(
      txn,
      () => {
        setSubmitting(false);
        // saveCustomerTxnToCache(selectedCustomer, cart, totalAmount);
        // sellItem(cart, () => {
        //   history.push(
        //     `/app/sales?tab=Make Sales&transaction_id=${receiptNo}&customerName=${
        //       selectedCustomer.accName || form.customerName
        //     }`
        //   );
        // });

        // if (selectedCustomer.accountNo) {
        //   chargeCustomer(selectedCustomer.accountNo, totalAmount);
        // }
      },
      (err) => {
        setSubmitting(false);
        console.log("An error occured", err);
      },
      TRANSACTION_TYPES.NEW_SALE
    );
  }, [
    activeBusiness,
    // activeStore,
    cart,
    // form,
    // history,
    otherInfo,
    selectedCustomer,
  ]);

  const handleSubmit = useCallback(() => {
    console.error({ selectedCustomer });
    otherInfo.amountPaid = otherInfo.amountPaid
      ? otherInfo.amountPaid
      : otherInfo.amountPaidDefault;
    if (!otherInfo.amountPaid) {
      alert("Please enter the amount paid");
      amountPaidRef.current.focus();
    } else if (!selectedCustomer.accName) {
      setIsNewCustomerModalOpen(true);
    } else {
      checkout();
    }
  }, [selectedCustomer, otherInfo, checkout]);

  const handleKeyPress = useCallback(
    (e) => {
      switch (e.key) {
        case "Enter":
          return addToCart();
        case "F10":
          return handleSubmit();

        default:
          return null;
      }
    },
    [addToCart, handleSubmit]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleChange = ({ target: { name, value } }) => {
    console.log({ name, value });
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleOtherInfoChange = ({ target: { name, value, placeholder } }) => {
    setOtherInfo((p) => ({ ...p, [name]: value }));
  };

  const toggleCustomerForm = () => setIsNewCustomerModalOpen((p) => !p);

  const createCustomerAndCheckout = (customer) => {
    setLoading(true);
    let receiptNo = moment().format("YYMDhms");
    let txn = [];
    let customerId = UUIDV4();
    txn.push({
      _id: UUIDV4(),
      source: CASH,
      product_code: "",
      amount: 0,
      amountPaid: otherInfo.amountPaid,
      destination: PAYABLE,
      quantity: 0,
      description: "Customer Deposit",
      receiptNo,
      narration: "CUSTOMER DEPOSIT",
      modeOfPayment: otherInfo.modeOfPayment,
      customerId: customerId,
      patientAcc: selectedCustomer.accountNo,
      transaction_type: TRANSACTION_TYPES.CUSTOMER_DEPOSIT,
    });
    console.log({ txn });
    saveTransaction(
      txn,
      () => {
        saveCustomerDepositTxnToCache(selectedCustomer, txn, 0);
        toggleCustomerForm();
        checkout();
      },
      (err) => {
        console.log(err);
      },
      TRANSACTION_TYPES.CUSTOMER_DEPOSIT
    );
    customer.store = activeStore;
    setForm((p) => ({ ...p, accName: customer.customerName }));
    if (customer.customerName !== "" || customer.customerName !== null) {
      saveNewCustomer(
        customer,
        (data) => {
          setLoading(false);
          console.log(data);
        },
        (err) => {
          setLoading(false);
          console.log(err);
          // alert('error')
        }
      );
    }
    setLoading(false);
  };

  return (
    <Row className="p-0 m-0">
      {/* {JSON.stringify({name: form.store_name,form,cart })} */}
      <Col md={8} className="px-1">
        <ItemSelection
          itemNameRef={itemNameRef}
          qttyRef={qttyRef}
          selectItem={selectItem}
          itemList={itemList}
          form={form}
          addToCart={addToCart}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setFilterText={setFilterText}
          filterText={filterText}
          options={options}
          disabled={!check}
          user_id={user_id}
          activeStore={activeStore}
          setActiveStore={setActiveStore}
        />
        {/* {JSON.stringify(form)} */}
      </Col>
      <Col md={4} className="px-1">
        <CartList
          setSelectedCustomer={setSelectedCustomer}
          list={cart}
          otherInfo={otherInfo}
          handleOtherInfoChange={handleOtherInfoChange}
          handleSubmit={handleSubmit}
          amountPaidRef={amountPaidRef}
          handleDelete={handleDelete}
          submitting={submitting}
        />
      </Col>
      <NewCustomerModal
        isOpen={isNewCustomerModalOpen}
        onSkipClicked={checkout}
        onSubmit={createCustomerAndCheckout}
        toggle={toggleCustomerForm}
        loading={loading}
      />
    </Row>
  );
}

export default MakeSale;
