// auth actions
export const LOGIN = 'LOGIN'
export const LOGGING_IN = 'LOGGING_IN'
export const LOGOUT = 'LOGOUT'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const SET_PROFILE = 'SET_PROFILE'
export const RESTORE_TOKEN = 'RESTORE_TOKEN'
export const SIGN_UP_LOADING = 'SIGN_UP_LOADING'
export const SET_APP_THEME = 'SET_APP_THEME'
export const SET_ACTIVE_BUSINESS = 'SET_ACTIVE_BUSINESS'
export const SET_FORM_SETUP = 'SET_FORM_SETUP'
export const CREATING_USER = 'CREATING_USER'
export const  LOADING = 'LOADING'
export const GET_PENDING_ITEMS='GET_PENDING_ITEMS'
export const  ERROR ='ERROR-U089SUVN8US89UNM'
export const  ERROR_CREATING_USER = 'ERROR_CREATING_USER'
export const  CREATE_ACC_HEAD = 'CREATE_ACC_HEAD'
export const  SALES = 'IOJAIJS98I-KO'
export const  GETTING_TOTAL_SALES_BY_USER = 'GETTING_TOTAL_SALES_BY_USER'
export const  GET_ACC_HEAD = 'GET_ACC_HEAD'
export const  GET_AMOUNT_HANDEDOVER = 'GET_AMOUNT_HANDEDOVER'
export const  GET_AMOUNT_RECEIVED = 'GET_AMOUNT_RECEIVED'
export const  GET_EXPENDITURE_ACC_REPORT = 'GET_EXPENDITURE_ACC_REPORT'
export const  GET_EXPENSES_ACC_HEAD = 'GET_EXPENSES_ACC_HEAD'
export const  GET_GENERAL_ACC_REPORT = 'GET_GENERAL_ACC_REPORT'
export const  GET_GENERAL_ACC_REPORT_LOADING = 'GET_GENERAL_ACC_REPORT_LOADING'
export const  GET_REVENUE_ACC_REPORT = 'GET_REVENUE_ACC_REPORT'
export const  GET_REV_ACC_HEAD = 'GET_REV_ACC_HEAD'
export const  GET_TOTAL_SALES_BY_USER = 'GET_TOTAL_SALES_BY_USER'

export const SET_NEW_DAILY_SALES = 'SET_NEW_DAILY_SALES';
export const  SET_NEW_DAILY_EXPENSES = 'SET_NEW_DAILY_EXPENSES';
export const  GET_REPRINT ='GET_REPRINT'; 
export const  GET_STORE_ALERTS = 'GET_STORE_ALERTS';
export const  GET_SHELF_ALERTS = 'GET_SHELF_ALERTS';
export const CREATE_ACC_HEAD_LOADING="CREATE_ACC_HEAD_LOADING" 
export const GETTING_PATIENT_ACC_STMT="GETTING_PATIENT_ACC_STMT"
export const GET_PATIENT_ACC_STMT="GET_PATIENT_ACC_STMT"
export const GET_EXPENSES_ACC_HEAD_LOADING="GET_EXPENSES_ACC_HEAD_LOADING"
export const GET_ACC_HEAD_LOADING="GET_ACC_HEAD_LOADING"
export const SET_SUPPLIER_LIST = 'SET_SUPPLIER_LIST'
export const SET_PURCHASED_ITEMS_LIST = 'SET_PURCHASED_ITEMS_LIST'
export const SET_EXPENSES_LIST = 'SET_EXPENSES_LIST'
export const SET_NEW_CUSTOMER = 'SET_NEW_CUSTOMER'
export const SET_CUSTOMERS_LIST = 'SET_CUSTOMERS_LIST'
export const SET_PURCHASED_BY_SUPPLIER = 'SET_PURCHASED_BY_SUPPLIER'
export const SET_PURCHASED_ITEMS_GROUP = 'SET_PURCHASED_ITEMS_GROUP'

export const SET_NEW_UOM = 'SET_NEW_UOM'
export const GETTING_ACC_CHART = 'GETTING_ACC_CHART'
export const GET_ACC_CHART_TREE = 'GET_ACC_CHART_TREE'
export const CREATE_ACC_CHART_TREE = 'CREATE_ACC_CHART_TREE'
export const NEW_ACCT_HEAD = 'NEW_ACCT_HEAD'
export const SET_NEW_CUSTOMER_CAT = 'SET_NEW_CUSTOMER_CAT'
export const SET_NEW_ITEMS_CATEGORY = 'SET_NEW_ITEMS_CATEGORY'
export const SET_NEW_BANK = 'SET_NEW_BANK'
export const SET_NEW_BANK_NEW = 'SET_NEW_BANK_NEW'

// db
export const SYNC_STARTED = 'SYNC_STARTED'
export const SYNC_ACTIVE = 'SYNC_ACTIVE'
export const SYNC_PAUSED = 'SYNC_PAUSED'
export const SYNC_ERROR = 'SYNC_PAUSED'
export const SYNC_DENIED = 'SYNC_DENIED'
export const SYNC_COMPLETED = 'SYNC_COMPLETED'

// store
export const SET_STORE_LIST = 'SET_STORE_LIST'

//printers
export const REMOVE_PRINTER = 'REMOVE_PRINTER'
export const PRINTER_LIST = 'PRINTER_LIST'
export const PRINTER_SELECTED = 'PRINTER_SELECTED'

// reports
export const SET_REORDER_LEVEL_ALERT = 'SET_REORDER_LEVEL_ALERT'
export const SET_EXPIRY_ALERT = 'SET_EXPIRY_ALERT'
export const SET_FAST_SELLING_ITEMS = 'SET_FAST_SELLING_ITEMS'
export const SET_DAILY_SALES_REPORT_UNFORMATTED =
  'SET_DAILY_SALES_REPORT_UNFORMATTED'
export const SET_DAILY_SALES_REPORT = 'SET_DAILY_SALES_REPORT'

export const CLEAR_SUPPLIER_STATEMENT = 'CLEAR_SUPPLIER_STATEMENT'
export const SET_CUSTOMER_STATEMENT = 'SET_CUSTOMER_STATEMENT'
export const SET_CLIENT_STATEMENT = 'SET_CLIENT_STATEMENT'
export const CLEAR_CLIENT_STATEMENT = 'CLEAR_CLIENT_STATEMENT'

// account action types
export const MY_SUPPLIERS ='MY_SUPPLIERS-0RT-000000-'
export const MY_ITEMS ='MY_ITEMS-T-000000-XE'
// requests action types
export const SUBMITTIN_REQUEST = 'SUBMITTIN_REQUEST';
export const LOADING_PENDING_REQUESTS = 'LOADING_PENDING_REQUESTS';
export const GET_PENDING_REQUESTS = 'GET_PENDING_REQUESTS';
export const ACTIVE_TAB = 'ACTIVE_TAB';


// purchase action types
export const LOADING_PURCHASES = 'LOADING_PURCHASES';
export const GET_PURCHASES = 'GET_PURCHASES';
export const LOADING_PENDING_PURCHASES = 'LOADING_PENDING_PURCHASES';
export const GET_PENDING_PURCHASES = 'GET_PENDING_PURCHASES';
export const GET_SUPPLIER_STATEMENT="GET_SUPPLIER_STATEMENT";
export const AMOUNT_PAID ="AMOUNT_PAID";
