import React from "react";
import { formatNumber } from "../../../app/utilities";
import ItemAvatar from "./ItemAvatar";

function ItemsList({ list = [], selectItem = (f) => f, filterText = "" }) {
  // const [filterText, setFilterText] = useState("");
  const rows = [];

  list
    .filter((i) => i.quantity > 0)
    .forEach((item) => {
      if (
        item.item_name &&
        item.item_name.toLowerCase().indexOf(filterText.toLowerCase()) === -1
      )
        return;

      rows.push(item);
    });

  return (
    <div>
      {/* <SearchBar
        placeholder="Search for items by code or name"
        filterText={filterText}
        onFilterTextChange={setFilterText}
      /> */}
      {/* {JSON.stringify(list)} */}

      <div style={{ height: "65vh", overflow: "scroll" }}>
        <div className='row m-0'>
          {rows.map((item, i) => (
            <Item key={i} item={item} selectItem={selectItem} />
          ))}
        </div>
        {/* <Table bordered size="sm">
          <thead>
            <tr>
              <th className='text-center'>Item </th>
              <th className='text-center'>Qty Available </th>
              <th className='text-center'>Price</th>
              <th className='text-center'>Action</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((item, i) => {
              let selling_price =
                parseFloat(item.cost) + parseFloat(item.markup);
              return (
                <tr key={i}>
                  <td>{item.item_name}</td>
                  <td className='text-right'>{formatNumber(item.quantity)}</td>
                  <td className='text-right'>{formatNumber(selling_price)}</td>
                  <td className="text-center">
                    <Button
                      color="primary"
                      size="sm"
                      // onClick={() => setHide(false)}
                      className="m-1"
                    >
                      <FaStreetView size="20" />
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
       */}
      </div>
    </div>
  );
}

function Item({ item = {}, selectItem = (f) => f }) {
  // let selling_price = parseFloat(item.cost) + parseFloat(item.markup);
  //   const activeBusiness = useSelector((state) => state.auth.activeBusiness);
  return (
    <div
      className='card card-body p-0  col-md-4 col-lg-3'
      style={{ cursor: "pointer" }}
      onClick={() => selectItem(item)}
    >
      <div className='p-1 border border-bottom-primary'>
        <ItemAvatar item={item} value={item.item_name} />
      </div>
      <div className='p-1'>
        <div className='font-weight-bold text-center'>{item.item_name}</div>
        <div className='font-weight-bold text-center'>
          ₦ {formatNumber(item.selling_price)}
        </div>

        <div className=' text-center'>
          {item.quantity} {item.uom} available
        </div>
        {/* <CustomButton size="sm">Select</CustomButton> */}
      </div>
    </div>
  );
}

export default ItemsList;
