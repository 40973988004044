import React, { Fragment, useCallback, useEffect } from "react";
import { useState } from "react";
import { getPurchasedItems } from "../../redux/actions/purchase";
import { useDispatch, useSelector } from "react-redux";
import CustomTypeahead from "../../components/CustomTypeahead";

function SearchFromBranchStore(props) {
  const [, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.purchase.purchaseList);
  const final = data.filter((i) => i.quantity > 0);
  let activeStore = props.activeStore?props.activeStore:null
  const getPurchaseList = useCallback(() => {
    // alert(props.activeStore)
    setIsLoading(true);
    dispatch(
      getPurchasedItems(
        activeStore,
        () => setIsLoading(false),
        () => setIsLoading(false)
      )
    );
  }, [dispatch, activeStore]);

  useEffect(() => {
    getPurchaseList();
  }, [getPurchaseList]);

  return (
    <>
    {/* {JSON.stringify(data)} */}
      <CustomTypeahead
        {...props}
        _ref={props._ref}
        allowNew={props.allowNew}
        label={props.label || ""}
        placeholder="Search items by name"
        labelkey={props.labelkey}
        labelKey="item_name"
        options={final}
        onInputChange={(v) => {
          if (v.length) {
            console.log(v, "KDDDDDDDK");
            props.onInputChange(v);
          }
        }}
        onChange={(v) => {
          if (v.length) {
            props.onChange(v[0]);
            console.log(v[0], "KDDDDDDDK");
          }
        }}
      />
    </>
  );
}

export default SearchFromBranchStore;
