import rules  from "validator";

const validator = ({user_id, password}) =>{
    const errors = {};
    if(!rules.isEmpty(user_id) ){
        if(!rules.isAlphanumeric(user_id)){
            errors.user_id = "Invalid User ID";
        }else if(!rules.isLength(user_id,5,32)){
            errors.user_id ="Verify your User ID";
        }
    }
    if(!rules.isEmpty(password) ){
        if(!rules.isLength(password,6,32)){
            errors.password = "Enter your password";
        }else if(!rules.isAlphanumeric(password)){
            errors.password = "Invalid  password";
        }
    }
    return errors;
}

export default validator;