import React, { useCallback, useEffect, useState } from 'react'
import { FaEdit } from 'react-icons/fa'
import { GiCancel } from 'react-icons/gi'
import { useDispatch, useSelector } from 'react-redux'

import { Alert, Button, Table } from 'reactstrap'
import {
  getStoresList,
  pullStoresChanges,
  pushStoresChanges,
} from '../../../redux/actions/stores'
import EmptyList from '../../components/EmptyList'
import Loading from '../../components/Loading'

export default function StoresTable({
  onEdit = (f) => f,
  onDelete = (f) => f,
}) {
  const dispatch = useDispatch()
  const storesList = useSelector((state) => state.stores.storeList)
  const [loading, setLoading] = useState(false)

  const getStores = useCallback(() => {
    setLoading(true)
    dispatch(
      getStoresList(
        () => setLoading(false),
        () => setLoading(false),
      ),
    )
  }, [dispatch])

  useEffect(() => {
    getStores()
    // pushStoresChanges(() => pullStoresChanges())
  }, [getStores])

  return (
    <>
      {loading && <Loading />}

      <Table bordered size="sm">
        <thead>
          <tr>
            <th>S/N</th>
            <th>Store</th>
            <th>Location</th>
            <th>Phone Number</th>
            <th>Store Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {storesList.map((store, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{store.branch_name}</td>
              <td>{store.address}</td>
              <td>{store.phone}</td>
              <td>{store.store_type}</td>

              <td className="text-center">
                <Button
                  color="success"
                  size="sm"
                  className="m-1"
                  onClick={() => onEdit(store)}
                >
                  <FaEdit size="20" /> Edit
                </Button>
                <Button
                  color="danger"
                  size="sm"
                  disabled
                  onClick={() => onDelete(store)}
                >
                  <GiCancel size="20" /> Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!storesList.length && (
        <EmptyList text="You have not created a store yet, get started with the form above" />
      )}
    </>
  )
}
