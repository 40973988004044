import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTypeahead from "../../../components/CustomTypeahead";
import { getCustomers } from "../../../redux/actions/customer";

function SearchCustomerInput(props) {
  const dispatch = useDispatch();
  const options = useSelector((state) => state.customer.customerList) || [];

  const getList = useCallback(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  useEffect(() => {
    getList();
  }, [getList]);
  // if (options) {
  //   options.forEach((o) => {
  //     o.customerName = o.surname + " " + o.firstname;
  //   });
  // }
  return (
    <>
      {/* {JSON.stringify(options)} */}
      <CustomTypeahead
        _ref={props.ref}
        placeholder="Search customer by name"
        options={options}
        labelKey={(i) => i.accName}
        onInputChange={(i) => i.accName}
        onChange={(v) => {
          if (v.length) {
            props.onChange(v);
          }
        }}
        {...props}
      />
    </>
  );
}

export default SearchCustomerInput;
