import React, { useState, useEffect } from 'react';
import { Pane, Checkbox } from 'evergreen-ui';

export default function CheckBoxItem({ label, name, handleCheck, value = [] }) {
  const [checked, setChecked] = useState(
    value.indexOf(label) !== -1 ? true : false,
  );
  useEffect(() => setChecked(value.indexOf(label) !== -1 ? true : false), [
    value,
    label,
  ]);
  return (
    <Pane>
      <Checkbox
        checked={checked}
        label={label}
        onChange={({ target }) => {
          handleCheck(name, target.checked, label);
          setChecked(target.checked);
        }}
      />
    </Pane>
  );
}
