import { useState } from 'react';
import { login } from '../../redux/actions/auth';
import { useDispatch } from 'react-redux';
import { useHistory,
	//  useLocation
	 } from 'react-router-dom';

const useLogin = validate =>{
    const dispatch = useDispatch();
    const history = useHistory();
    // const location = useLocation();
    // const { from } = location.state || { from: { pathname: '/' } };
    const [values,setValues] = useState({
        user_id:'',
        password:''
    });
    const [loading, setLoading] = useState(false);
    const errors = validate(values);
    const count_err = Object.values(errors)?.length;
    const count_val = Object.values(values)?.length

    const handleChange = ({target}) =>{
        const {name, value} = target;
        setValues({
            ...values,
            [name]:value
        });
        validate(values);
    }

    async function  handleSubmit (e){
        e.preventDefault();
        setLoading(true)
        console.log({count_err,count_val})
        // if(count_err<1 && count_val>1){ 
           await dispatch(login({phone:values.user_id, password:values.password},
            ()=>{
                setLoading(false)
                history.push('/app/');  
            }, (err)=>{
                setLoading(false)
                console.log({ERROR:"Login error"});
                // setLoading(false)
                console.log(err)
            }));    
        // }
    }

  return {errors,values,handleChange,handleSubmit,loading}
}
export default useLogin;