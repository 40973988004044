import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Table, Col, Button, Row, Input } from "reactstrap";
// import { _fetchApi } from "../../redux/actions/api";
import SearchBar from "../components/SearchBar";
import { useParams } from "react-router";
import DaterangeSelector from "../../app/components/DaterangeSelector";
import { getAllReport } from "../../redux/actions/reports";
import CustomCard from "../../components/CustomCard";
import useQuery from "../hooks/useQuery";
import CustomScrollbar from "../../components/CustomScrollbar";
import { formatNumber } from "../../app/utilities";
import CustomButton from "../../app/components/Button";
import Checkbox from "../components/CheckBox";
import SearchStoresInput from "../../app/admin/stores/SearchStores";
import { getStoresList } from "../../redux/actions/stores";

export default function SupplierReport() {
  // const today = moment().format('YYYY-MM-DD')
  const query = useQuery();
  const fromDate = query.get("from");
  const toDate = query.get("to");
  const dispatch = useDispatch();
  const [reports, setReports] = useState([]);
  const [list, setList] = useState([]); 
  const [branch, setBranch] = useState(''); 
  const [filterText, setFilterText] = useState(""); 
  const { query_type } = useParams();
  const [showAllPurchase, setShowAllPurchase] = useState(false);
  const [range, setRange] = useState({
    from: fromDate,
    to: toDate,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRange((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [form, setForm] = useState({
    branch_name: ""
  });
  const getReports = useCallback(() => {
    dispatch(
      getAllReport(
        setReports,
        range.from,
        range.to,
        query_type.split(" ")[0] + " category summary"
      )
    );
  }, [dispatch, range, query_type]);

  useEffect(() => {
    getReports();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [0, getReports]);
  useEffect(() => {
    dispatch(getStoresList());
  }, [dispatch]);


  const retrieveList = useCallback(() => {
    setList(
      filterText.length > 3 && reports.length
        ? reports.filter((item) => {
            return item.description
              ?.toLowerCase()
              ?.includes(filterText.toLowerCase()
              );
          })
        : reports
    );
  }, [reports, filterText]);
 
  const sycSearch = useCallback(() => {
    setList(
      branch.length > 3 && reports.length
        ? reports.filter((item) => {
          console.error({branch_name:item});
            return  item.branch_name?.toLowerCase()
            ?.includes(branch.toLowerCase()
              );
          })
        : reports
    );
  }, [reports,branch]);

  useEffect(() => {
    retrieveList();
  }, [retrieveList]);

  useEffect(() => {
    sycSearch();
  }, [sycSearch]);
  const tt =
    list &&
    list.length &&
    list.map((item) => parseInt(item.qty) * parseInt(item.selling_price));

  let total = tt && tt.reduce((total, num) => total + num);
  const tt2 =
    list &&
    list.length &&
    list
      .map((item) => parseInt(item.amount))
      .reduce((total, num) => total + num);
  return (
    <CustomCard
      container="shadow container p-0"
      body="p-0"
      back
      header={"Total " + query_type && query_type.split(" ")[0] + " reports"}
    >
      {/* {JSON.stringify(list)} */}
      <div className="m-2">
        <DaterangeSelector
          handleChange={handleChange}
          from={range.from}
          to={range.to}
        />
        <Row clasName="mb-3">
          <Col md={8}>
            <SearchBar
              filterText={filterText}
              placeholder='Search items'
              onFilterTextChange={(input) => setFilterText(input)}
            />
          </Col>
          <Col className="d-flex flex-direction-row align-items-center">
          <SearchStoresInput
            onChange={(selected) =>
              setBranch(selected.storeName)
            }
            onInputChange={v=>{setBranch(v)}}
            clearButton
          />
          </Col>
          <Col className="d-flex flex-direction-row align-items-center">
            <Checkbox
              label="Show All"
              checked={showAllPurchase}
              onChange={() => setShowAllPurchase((p) => !p)}
            />
          </Col>
        </Row>
      </div>
      <CustomScrollbar height="65vh">
        {/* {JSON.stringify(branch)} */}
        <Table bordered size="sm">
          {(query_type && query_type.split(" ")[0]) === "Expenses" ||
          (query_type && query_type.split(" ")[0]) === "Debt" ? (
            <thead>
              <tr>
                <th className="text-center">S/N</th>
                <th className="text-center">Date</th>
                <th className="text-center">Description</th>
                <th className="text-center">Amount</th>
              </tr>
            </thead>
          ) : (
            <thead>
              <tr>
                <th rowSpan={2} className="text-center">
                  S/N
                </th>
                <th rowSpan={2} className="text-center">
                  Date
                </th>
                <th rowSpan={2} className="text-center">
                  Description
                </th>
                <th colSpan={3} className="text-center">
                  Item Qty & Cost
                </th>
              </tr>
              <tr>
                <th className="text-center">Qty Purchased</th>
                <th className="text-center">Cost Price</th>
                <th className="text-center">Total</th>
              </tr>
            </thead>
          )}
          {(query_type && query_type.split(" ")[0] === "Expenses") ||
          (query_type && query_type.split(" ")[0] === "Debt") ? (
            <tbody>
              {list &&
                list.map((item, i) => (
                  <tr key={i}>
                    <th className="text-center">{i + 1}</th>
                    <td>{moment(item.created).format("DD-MM-YYYY")}</td>
                    <td>{item.description}</td>
                    <td className="text-center">{formatNumber(item.amount)}</td>
                  </tr>
                ))}
              <tr>
                <th scope="row" colSpan="3" className="text-right">
                  Total
                </th>
                <th className="text-center">{formatNumber(tt2)}</th>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {list &&
                list.map((item, i) => (
                  <tr key={i}>
                    <th className="text-center">{i + 1}</th>
                    <td>{moment(item.receive_date).format("DD-MM-YYYY")}</td>
                    <td>{item.description}</td>
                    <td className="text-center">{formatNumber(item.qty)}</td>
                    <td className="text-right">
                      {formatNumber(item.selling_price)}
                    </td>
                    <td className="text-right">
                      {formatNumber(
                        parseInt(item.qty) * parseInt(item.selling_price)
                      )}
                    </td>
                  </tr>
                ))}
              <tr>
                <th scope="row" colSpan="5" className="text-right">
                  Total
                </th>
                <th className="text-right">{formatNumber(total)}</th>
              </tr>
            </tbody>
          )}
        </Table>
      </CustomScrollbar>
    </CustomCard>
  );
}
