import formSetupLocal, { remoteFormSetup } from "../../pouchdb/formSetup";
import { SET_FORM_SETUP, SYNC_STARTED } from "./actionTypes";
import store from "../store";
import { v4 as UUIDV4 } from "uuid";

// const _id = 'form_setup'
export const saveFormSetup = (
  obj = {},
  callback = (f) => f,
  error = (f) => f,
) => {
  const user = store.getState().auth.user;
  let uuid = UUIDV4();
  obj._id = uuid;
  obj.createdAt = new Date().toISOString();
  obj.facilityID = user.facilityId;
  obj.userId = user.id;

  formSetupLocal
    .put(obj)
    .then((resp) => {
      console.log("Form Setup created successfully");
      callback();
      pushFormSetupChanges(() => pullFormSetupChanges());
    })
    .catch((err) => {
      console.log("Error: ", err);
      error(err);
    });
};

export const formSettingSetup = (
  type,
  callback = (f) => f,
  error = (f) => f,
) => {
  return (dispatch) => {
    const user = store.getState().auth.user;
    formSetupLocal
      .createIndex({ index: { fields: ["facilityID", "createdAt", "type"] } })
      .then(() => {
        return formSetupLocal.find({
          selector: {
            facilityID: {
              $eq: user.facilityId,
            },
            createdAt: {
              $gt: null,
            },
            type,
          },
        });
      })
      .then((resp) => {
        let data = resp.docs;
        // console.log(data, '======================================================')
        callback(data);
        dispatch({ type: SET_FORM_SETUP, payload: data });
      })
      .catch((err) => {
        error(err);
        console.log(err);
      });
  };
};

export const updateFormSetup = (
  _id = "",
  data = {},
  callback = (f) => f,
  error = (f) => f,
) => {
  console.log(_id, data);

  formSetupLocal
    .get(_id)
    .then((doc) => {
      formSetupLocal
        .put({
          ...doc,
          ...data,
        })
        .then((resp) => {
          console.log("Form Setup Successfully updated item info", resp);
          callback();
        })
        .catch((err) => console.log(err));
    })
    .catch((err) => {
      console.log("Error: ", err);

      error(err);
    });
};

export const syncFormSetup = () => {
  return (dispatch) => {
    dispatch({ type: SYNC_STARTED });

    // let opts = { live: true, retry: true }

    // const onSyncChange = (info) => {
    //   // console.log(TAG, 'FormSetup_DB sync onChange', info)
    // }

    // const onSyncPaused = (err) => {
    //   // console.log(TAG, 'FormSetup_DB sync onPaused', err)
    // }

    const onSyncError = (err) => {
      // console.log(TAG, 'FormSetup_DB sync onError', err)
    };

    // do one way, one-off sync from the server until completion
    formSetupLocal.replicate
      .from(remoteFormSetup)
      .on("complete", function (info) {
        console.log("one way replication completed", info);
        // then two-way, continuous, retriable sync
        // FormSetup.sync(remoteFormSetup, opts)
        //   .on('change', onSyncChange)
        //   .on('paused', onSyncPaused)
        //   .on('error', onSyncError)
      })
      .on("error", onSyncError);
  };
};

export const pushFormSetupChanges = (onComplete = (f) => f) => {
  console.log("start pushing FormSetup updates");
  formSetupLocal.replicate
    .to(remoteFormSetup)
    .on("complete", (info) => {
      console.log("pushed changes to FormSetup");
      console.log(info);
      onComplete();
    })
    .on("error", (err) => {
      console.log("error pushing changes to FormSetup db", err);
    });
};

export const pullFormSetupChanges = (onComplete = (f) => f) => {
  console.log("start pulling FormSetup updates");
  formSetupLocal.replicate
    .from(remoteFormSetup)
    .on("complete", (info) => {
      console.log("pushed changes to FormSetup");
      console.log(info);
      onComplete();
    })
    .on("error", (err) => {
      console.log("error pushing changes to FormSetup db", err);
    });
};
