import React from "react";
import ReciveForm from "./transfer/ReciveForm";
import MakeSale from "./MakeSale";
import PostSalePage from "./make-sales/PostSalePage";
import ReturnItem from "./return-goods/ReturnItem";
import TabWrapper from "../components/tab-wrapper/TabWrapper";
import Transfer from "./Transfer";
// import PendingSales from './pendingSales'
// import { useParams } from "react-router";
import useQuery from "../hooks/useQuery";
import PendingSales from "./pendingSales";

function Sales() {
  const query = useQuery();
  const salesTab = query.get("tab");
  const transaction_id = query.get("transaction_id");
  const arr = [
    "Make Sales",
    "Transfer Form",
    "Return Item",
    "Receive Form",
    "Pending Sales",
  ];
  const navigate = () => {
    switch (salesTab) {
      case arr[0]:
        return <MakeSale />;

      case arr[1]:
        return <Transfer />;

      case arr[2]:
        return <ReturnItem />;

      case arr[3]:
        return <ReciveForm />;

      case arr[4]:
        return <PendingSales />;

      default:
        return <Transfer />;
    }
  };
  return (
    <>
      {arr.includes(salesTab) && transaction_id === null ? (
        <div>
          <TabWrapper steps={arr} route="/app/sales" step={salesTab}>
            {navigate()}
          </TabWrapper>
        </div>
      ) : (
        <PostSalePage />
      )}
    </>
  );
}

export default Sales;
