import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import CustomForm from "../components/CustomForm";
import { FaSave } from "react-icons/fa";
import CustomButton from "../../app/components/Button";
import BankTable from "./BankTable";
import {
  getbank,
  pullbankChanges,
  pushbankChanges,
  saveNewbank,
} from "../../redux/actions/add_bank";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../app/components/Loading";
import CustomCard from "../../components/CustomCard";

export default function Bank() {
  let _form = {
    bank_name: "",
    acct_no: "",
  };
  const [form, setForm] = useState(_form);
  const [loading, setLoading] = useState(false);
  const [_loading, _setLoading] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((d) => d.bank.new_bankList);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({
      ...p,
      [name]: value,
    }));
  };

  const fields = [
    {
      label: "Bank Name",
      name: "bank_name",
      required: true,
      value: form.bank_name,
      col: 6,
    },

    {
      label: "Account Number",
      name: "acct_no",
      required: true,
      value: form.acct_no,
      col: 6,
    },
  ];

  const getBankList = useCallback(() => {
    _setLoading(true);
    dispatch(getbank(() => _setLoading(false)));
  }, [dispatch]);

  useEffect(() => {
    getBankList();
    pushbankChanges(() => pullbankChanges());
  }, [getBankList]);

  const handleSubmit = () => {
    setLoading(true);
    saveNewbank(
      form,
      () => {
        setLoading(false);
        dispatch(getbank());
        setForm(_form);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      },
    );
  };

  return (
    <CustomCard>
      {JSON.stringify(data)}
      <Card>
        <CardHeader>Bank </CardHeader>
        <CardBody>
          <CustomForm fields={fields} handleChange={handleChange} />
          <CardHeader>
            <center>
              <CustomButton onClick={handleSubmit} loading={loading}>
                <FaSave />
                Submit
              </CustomButton>
            </center>
          </CardHeader>
        </CardBody>
        {_loading && <Loading />}
        <BankTable data={data} />
      </Card>
    </CustomCard>
  );
}
