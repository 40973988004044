import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Table } from "reactstrap";
// import SearchBar from "../../app/components/SearchBar";
import DaterangeSelector from "../../app/components/DaterangeSelector";
import useQuery from "../hooks/useQuery";
import { getPurchasedItems } from "../../redux/actions/purchase";
import { getSupplierStatment } from "../../redux/actions/suppliers";
import { formatNumber } from "../../app/utilities";
import Loading from "../../app/components/Loading";
// import CustomCard from '../components/CustomCard'
import CustomScrollbar from "../../components/CustomScrollbar";
// import CustomAlert from "../../components/CustomAlert";
import CustomCard from "../../components/CustomCard";

export default function SupplierReport() {
  const today = moment().format("YYYY-MM-DD");
  const aMonthAgo = moment().subtract(1, "month").format("YYYY-MM-DD");
  const dispatch = useDispatch();
  const query = useQuery();
  const name = query.get("supplier_name");
  const _id = query.get("supplier_id");
  const balance = query.get("balance");

  const [form, setForm] = useState({
    from: aMonthAgo,
    to: today,
    searchTxt: "",
  });

  const { from, to } = form;
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const [list, setList] = useState({});
  const [loading, setLoading] = useState(false);
  const getPurchaseList = useCallback(() => {
    setLoading(true);
    dispatch(
      getPurchasedItems(
        null,
        () => setLoading(false),
        () => setLoading(false)
      )
    );
  }, [dispatch]);

  const _getSupplierStatement = useCallback(() => {
    console.error({ data: _id });
    getSupplierStatment(_id, from, to, (data) => {
      setList(data);
      console.log(data);
    });
  }, [_id, from, to]);

  useEffect(() => {
    getPurchaseList();
    _getSupplierStatement();
  }, [getPurchaseList, _getSupplierStatement]);

  // const showWayBill =
  //   useSelector(
  //     (state) => state.auth.activeBusiness.business_includes_logistics
  //   ) || false;
  const balance_ = list["-"] ? list["-"][0] : {};
  const val_ = list["-"] ? list["-"].map((i) => i.balance) : {};
  const openingBal = Object.keys(list).map((l) => list[l].find((item, i) => item.description === "Opening Balance"))[0]
  return (
    <CustomCard back header={"Supplier Report"}>
      <DaterangeSelector
        handleChange={(e) => handleChange(e)}
        from={from}
        to={to}
      />
      <Col md={12}>
        <div
          className={
            // balanceIsNegative
            // ? "text-danger font-weight-bold"
            // :
            " font-weight-bold"
          }
        >
          Supplier Name: <span>{name}</span>
        </div>
      </Col>
      <Col
        md={12}
        className="d-flex mt-1 flex-direction-row justify-content-between"
      >
          {/* <div
          className={
            // balanceIsNegative
            //   ? "text-danger font-weight-bold"
            // :
            " font-weight-bold"
          }
        >
          Total Balance: ₦{formatNumber(balance_.closing)}
        </div>{" "} */}
      
        <div
          className={
            // balanceIsNegative
            //   ? "text-danger font-weight-bold"
            // :
            " font-weight-bold"
          }
        >
          Opening Balance: ₦{formatNumber(openingBal&&openingBal.total)}
        </div>
        <div
          className={
            // balanceIsNegative
            // ? "text-danger font-weight-bold"
            // :
            " font-weight-bold"
          }
        >
          Total Balance: ₦{formatNumber(balance)}
        </div>
      </Col>

      {/* <SearchBar
        name="supplier"
        placeholder="Select supplier..."
        value={form.supplier}
        onChange={(e) => setForm({ searchTxt: e[0].name })}
      /> */}

      {loading ? <Loading /> : false}
      <div>
        {/* {JSON.stringify({val_,balance_, list,openingBal})} */}

        <CustomScrollbar height="55vh">
          {/* {list.length ? ( */}
          <Table bordered className="mt-3" size="sm">
            <thead>
              <tr>
                <th className="text-center">S/N</th>
                <th className="text-center">Date</th>
                <th className="text-center">Description</th>
                <th className="text-center">Quantity</th>
                <th className="text-center">Cost Of Items(₦)</th>
                <th className="text-center">Amount Paid(₦)</th>
                {/* <th className="text-center">Total(₦)</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={8} className="text-right font-weight-bold">
                  Opening Balance:₦{formatNumber(openingBal&&openingBal.total)}
                </td>
              </tr>
              {Object.keys(list).map((l) =>
                list[l].map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td className="text-left">
                        {moment(item.created).format("DD/MM/YYYY")}
                      </td>
                      <td className="text-left">{item.description}</td>
                      <td className="text-center">
                        {item.quantity === 0 || item.quantity === null
                          ? "-"
                          : formatNumber(item.quantity)}
                      </td>
                      <td className="text-right">
                        {item.dr === 0 || item.dr === null
                          ? "-"
                          : formatNumber(item.dr)}
                      </td>
                      <td className="text-right">
                        {item.cr === 0 || item.cr === null
                          ? "-"
                          : formatNumber(item.cr)}
                      </td>
                      {/* <td className="text-right">{formatNumber(item.total)}</td> */}
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
          {/* ) : ( */}
          {/* <CustomAlert>
              No report found at this time, please check back later.
            </CustomAlert> */}
          {/* )} */}
        </CustomScrollbar>
      </div>
      {/* {Object.keys(list).map((item, idx) => {
        let totalDebit = list[item].reduce((a, b) => a + parseInt(b.dr), 0);
        let totalCredit = list[item].reduce((a, b) => a + parseInt(b.cr), 0);
        let balance = totalCredit - totalDebit;
        // return (

        // );
      })} */}
    </CustomCard>
  );
}
