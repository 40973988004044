import React from "react";
// import { useHistory } from "react-router";
import CustomButton from "../../../app/components/Button";
import CustomCard from "../../../components/CustomCard";
import ItemsList from "./ItemsList";
import SalesForm from "./SalesForm";

function ItemSelection({
  disabled,
  form = {
    filterText:''
  },
  itemList = {},
  addToCart = (f) => f,
  selectItem = (f) => f,
  qttyRef,
  itemNameRef,
  handleChange,
  handleSubmit = (f) => f,
  filterText,
  setFilterText,
  options,
  user_id,activeStore,
  setActiveStore
}) {
  // const history = useHistory()
  //   const activeBusiness = useSelector((state) => state.auth.activeBusiness);
  return (
    
    <CustomCard container='p-0' body='p-2'>
      {/* <CardHeader className='h6'>{hide === true ? "Point of Sales" : "Cart List"}</CardHeader> */}
      {/* <CardBody className='p-1'> */}
      {/* {JSON.stringify(form)} */}
      {/* {hide === true ? (*/}
      <>
        <SalesForm
        options={options}
          form={form}
          qttyRef={qttyRef}
          itemNameRef={itemNameRef}
          handleChange={handleChange}
          setFilterText={setFilterText}
          disabled={disabled}
          user_id={user_id}activeStore={activeStore}
          setActiveStore={setActiveStore}
        />
        <ItemsList
          filterText={filterText}
          selectItem={selectItem}
          list={itemList}
        />
        <div className=''>
          <div
            // color="primary"
            className='btn btn-secondary'
            //   onClick={handleSubmit}
          >
            Press F10 to submit
          </div>
          {/* <button onClick={() => history.push('/app/sales/receipt-preview?transaction_id=2110163234')}>check</button> */}
          <CustomButton
            color='primary'
            className='float-right'
            onClick={addToCart}
          >
            Press Enter to Add To Cart
          </CustomButton>
        </div>
      </>
      {/* ) : (
            <div>
              <h5 style={{ float: "right" }}>Total: __________</h5>

              <Table bordered size="sm">
                <thead>
                  <tr>
                    <th>Item </th>
                    <th>Qty Available </th>
                    <th>Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">milo</td>
                    <td className="text-center">88</td>
                    <td className="text-center">$7678</td>
                    <td className="text-center">$87787678</td>
                  </tr>
                </tbody>
              </Table>
              
              <center>
                <Button color="primary" className="mt-2">
                  Submit
                </Button>
              </center>
            </div>
          )} */}
      {/* </CardBody> */}
    </CustomCard>
  );
}

export default ItemSelection;
