import React from "react";
import { InputGroup, InputGroupAddon, InputGroupText, Label } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import "./search.css";
import { useSelector } from "react-redux";
export default function SearchBar({
  placeholder = "",
  filterText = "",
  onFilterTextChange = (f) => f,
  _ref = null,
  label = "Search Item",
}) {
  const activeBusiness = useSelector((state) => state.auth.activeBusiness);
  const handleFilterTextChange = (e) => {
    onFilterTextChange(e.target.value);
  };

  return (
    <div className="my-2">
      <Label className="font-weight-bold">{label}</Label>
      <InputGroup>
        <InputGroupAddon
          addonType="prepend"
          style={{
            borderWidth: 2,
            borderColor: activeBusiness.primary_color,
          }}
        >
          <InputGroupText>
            <FaSearch />
          </InputGroupText>
        </InputGroupAddon>
        <input
          ref={_ref}
          name="filterText"
          value={filterText}
          onChange={handleFilterTextChange}
          type="text"
          className="form-control"
          placeholder={placeholder !== "" ? placeholder : "Search..."}
          style={{
            borderWidth: 2,
            borderColor: activeBusiness.primary_color,
          }}
        />
      </InputGroup>
    </div>
  );
}
