import React from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
// import { primaryColor } from '../../theme';

function CustomButton(props) {
  const activeBusiness = useSelector((state) => state.auth.activeBusiness);
  return (
    <Button
      // color="primary"
      style={{
        backgroundColor: activeBusiness?.primary_color,
        borderColor: activeBusiness?.primary_color,
      }}
      className={props.className}
      onClick={props.handleSubmit}
      {...props}
      disabled={props.loading || props.disabled}
    >
      {props.loading && (
        <span
          className='spinner-border spinner-border-sm mr-2'
          role='status'
          aria-hidden='true'
        />
      )}
      {props.children}
    </Button>
  );
}

export default CustomButton;
