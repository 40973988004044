import React from "react";
import { Row, Col } from "reactstrap";
import { Switch, Route, Redirect } from "react-router-dom";

import VerticalMenu from "../../components/vertical-menu/VerticalMenu";
import HorizontalMenu from "../../components/vertical-menu/HorizontalMenu";

import { FaArchway } from "react-icons/fa";

import CustomerTable from "./CustomerTable";
import CustomerRegistartion from "./CustomerRegistration";
import CustomerDeposit from "./CustomerDeposit";
import ViewCustomer from "./ViewCustomer";

const Tabs = () => {
  return (
    <React.Fragment>
       <VerticalMenu title="Menu Items">
        <HorizontalMenu route="/app/customer/index">
          <FaArchway size={26} style={{ marginRight: 5 }} />
          Customers
        </HorizontalMenu>
        {/* <HorizontalMenu route="/app/purchase/create-supplier">
          <FaArchway size={26} style={{ marginRight: 5 }} />
          Create Supplier
        </HorizontalMe<VerticalMenunu> */}
      </VerticalMenu>
    </React.Fragment>
  );
};

export default function CustomerIndex() {
  // alert("SDFHFFH")
  return (
    <Row className="m-0 p-0">
      <Col md={3} className="">
        <Tabs />
      </Col>
      <Col md={9} className="">
        <Switch>
          <Redirect exact from="/app/customer" to="/app/customer/index" />
          <Route exact path="/app/customer/index" component={CustomerTable} />
          <Route
            exact
            path="/app/customer/customer-registration"
            component={CustomerRegistartion}
          />
          <Route
            exact
            path="/app/customer/customer-deposit"
            component={CustomerDeposit}
          />
          <Route
            exact
            path="/app/customer/customer-view"
            component={ViewCustomer}
          />
        </Switch>
      </Col>
    </Row>
  );
}
