export const gotoApp = (access, history) => {
  let _access = access //.split(',');
  console.error(_access[0])
  switch (_access[0]) {
    case 'Purchases':
      return history.push('/app/purchase')
    case 'Inventory':
      return history.push('/app/inventory')
    case 'Sales':
      return history.push('/app/sales')
    case 'Reports':
      return history.push('/app/account')
    case 'Production':
      return history.push('/app/production')
    case 'Customers':
      return history.push('/app/customer')
    case 'Admin':
      return history.push('/app/admin')
    default:
      return history.push('/app')
  }
}
