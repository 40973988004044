import React from 'react'
import { Row, Col } from 'reactstrap'
import { Switch, Route, Redirect } from 'react-router-dom'
import SupplierForm from './suppliers/SupplierForm'
import VerticalMenu from '../components/vertical-menu/VerticalMenu'
import HorizontalMenu from '../components/vertical-menu/HorizontalMenu'
// import SupplierPerformance from '../app/account/SupplierPerformance';
// import InvertoryOverView from '../app/account/InventoryOverview';
import { FaArchway } from 'react-icons/fa'
// import DailySale from '../app/account/DailySales';
import CreatePurchase from './purchase/CreatePurchase'
import SupplierTable from './suppliers/Index'
import SupplierPayment from './suppliers/SupplierPayment'
import PurchaseTable from './purchase/PurchaseTable'
import SupplierReport from './suppliers/SupplierReport'
import { useSelector } from 'react-redux'
import { hasAccess } from '../app/utilities'
// import SupplierReport from './suppliers/SupplierReport';

const Tabs = () =>{
  const {user} = useSelector(s=>s.auth);
  return (
    <React.Fragment>
      <VerticalMenu title="Menu Items">
        <HorizontalMenu route="/app/purchase/purchase-list">
          <FaArchway size={26} style={{ marginRight: 5 }} />
          Purchase List
        </HorizontalMenu>
        {user.accessTo
            ? hasAccess(user,['Supplier']) &&(
        <HorizontalMenu route="/app/purchase/suppliers">
          <FaArchway size={26} style={{ marginRight: 5 }} />
          Suppliers
        </HorizontalMenu>)
        :null}
        {/* <HorizontalMenu route="/app/purchase/create-supplier">
          <FaArchway size={26} style={{ marginRight: 5 }} />
          Create Supplier
        </HorizontalMenu> */}
      </VerticalMenu>
    </React.Fragment>
  )
}

function Purchase() {
  return (
    <Row className="m-0 p-0">
      <Col md={3} className="m-0 p-0">
        <Tabs />
      </Col>
      <Col md={9} className="">
        <Switch> 
          <Redirect
            exact
            from="/app/purchase"
            to="/app/purchase/purchase-list"
          />
          <Route
            exact
            path="/app/purchase/suppliers/new"
            component={SupplierForm}
          />
          <Route
            exact
            path="/app/purchase/suppliers/payment"
            component={SupplierPayment}
          />
          <Route
            exact
            path="/app/purchase/suppliers/report/:id?"
            component={SupplierReport}
          />
          {/* <Route
            exact
            path="/app/purchase/returned-goods"
            component={ReturnedGoodsReport}
          /> */}
          {/* <Route exact path="/app/purchase/daily/chart" component={DailySale} /> */}
          <Route
            exact
            path="/app/purchase/suppliers"
            component={SupplierTable}
          />
          {/* <Route
            exact
            path="/app/purchase/supplier/performance"
            component={SupplierPerformance}
          /> */}
          <Route
            exact
            path="/app/purchase/purchase-list/new"
            component={CreatePurchase}
          />
          <Route
            exact
            path="/app/purchase/purchase-list"
            component={PurchaseTable}
          />{' '}
          <Route
            exact
            path="/app/purchase/suppliers-report/:query_type"
            component={SupplierReport}
          />
        </Switch>
      </Col>
    </Row>
  )
}

export default Purchase
