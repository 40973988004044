import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import {
  CASH,
  MODES_OF_PAYMENT,
  PAYABLE,
  TRANSACTION_TYPES,
} from "../../constants";
import SimpleInput from "../../inventria/components/SimpleInput";
import {
  fundCustomer,
  saveCustomerDepositTxnToCache,
  saveNewCustomer,
} from "../../redux/actions/customer";
import { saveTransaction } from "../../redux/actions/transactions";
import { formatNumber } from "../utilities";
import moment from "moment";
import { v4 as UUIDV4 } from "uuid";
import { useHistory } from "react-router";
import CustomButton from "../components/Button";
// import BackButton from '../components/BackButton'
import CustomCard from "../../components/CustomCard";
import SearchCustomerInput from "./components/SearchCustomerInput";
import CustomSuccessAlert from "../../inventria/components/CustomSuccessAlert";
// import CustomCard from '../../inventria/components/CustomCard'

export default function CustomerDeposit() {
  const history = useHistory();
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [form, setForm] = useState({
    modeOfPayment: MODES_OF_PAYMENT.CASH,
  });
  const [loading, setLoading] = useState(false);

  const fields = [
    {
      label: `Amount Paid (${formatNumber(form.amount)})`,
      type: "number",
      name: "amount",
      value: form.amount,
    },
    // { label: "Date", type: "date", name: "date", value: null },
    {
      label: "Mode of payment",
      type: "select",
      name: "modeOfPayment",
      options: Object.values(MODES_OF_PAYMENT),
      value: form.modeOfPayment,
    },
    {
      label: "Narration",
      type: "text",
      name: "narration",
      value: form.narration,
    },
  ];

  const handleFormChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  const handleSubmit = () => {
    setLoading(true);
    let receiptNo = moment().format("YYMDhms");
    let txn = {
      _id: UUIDV4(),
      source: CASH,
      product_code: "",
      depositAmount: form.amount,
      destination: PAYABLE,
      quantity: 0,
      deposite: true,
      description: form.narration || "Customer Deposit",
      receiptNo,
      patientAcc: selectedCustomer.accountNo,
      narration: form.narration || "CUSTOMER DEPOSIT",
      modeOfPayment: form.modeOfPayment,
      clientAccount: selectedCustomer[0].accountNo,
      customerId: selectedCustomer[0]._id,
      customerName: selectedCustomer[0].accName,
      name: selectedCustomer[0].accName,
      transaction_type: TRANSACTION_TYPES.CUSTOMER_DEPOSIT,
    };

    saveNewCustomer(
      txn,
      () => {
        // saveCustomerDepositTxnToCache(selectedCustomer, txn, form.amount);
        console.log("account funded");
        setLoading(false);
        //   showMessage({
        //     message: 'Fund Deposited Successfully',
        //     type: 'success',
        //   })
        CustomSuccessAlert("Fund Deposited Successfully");
        history.push("/app/customer/index");
        //   navigation.navigate('CustomerCredit')

        console.log("saved transaction");
        // fundCustomer(
        //   selectedCustomer._id,
        //   form.amount,
        //   () => {},
        //   (err) => {
        //     console.log(err);
        //     setLoading(false);
        //   }
        // );
      },
      () => {
        CustomSuccessAlert("Error Occurred");
      },
      TRANSACTION_TYPES.CUSTOMER_DEPOSIT
    );
  };

  // const formIsValid =
  //   selectedCustomer._id &&
  //   selectedCustomer._id !== "" &&
  //   form.amount !== 0 &&
  //   form.amount !== "0" &&
  //   form.amount !== "";

  return (
    <CustomCard back header={"Customer Deposit"}>
      {/* {JSON.stringify(selectedCustomer)} */}
      <Row className="m-0">
        <Col md={3}>
          <SearchCustomerInput
            label="Select Customer"
            onChange={setSelectedCustomer}
          />
        </Col>
        <Col md={3}>
          <label>Balance</label>
          <label className="form-control">
            ₦{" "}
            {selectedCustomer[0]
              ? formatNumber(selectedCustomer[0].balance)
              : 0}
          </label>
        </Col>
        {fields.map((field, i) => (
          <SimpleInput
            key={i}
            field={field}
            size="6"
            handleChange={handleFormChange}
          />
        ))}
      </Row>
      <center className="mt-1">
        <CustomButton
          //   disabled={!formIsValid}
          onClick={handleSubmit}
          loading={loading}
          size="2"
        >
          Submit
        </CustomButton>
      </center>
    </CustomCard>
  );
}
