import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import { useHistory } from "react-router";
import { Card, CardHeader, CardBody, Table, Button } from "reactstrap";
import CustomButton from "../../app/components/Button";
import DaterangeSelector from "../../app/components/DaterangeSelector";
import SearchBar from "../../app/components/SearchBar";
import { formatNumber, today } from "../../app/utilities";
import { _postApi } from "../../redux/actions/api";

export default function TransactionReport() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const aMonthAgo = moment().subtract(1, "month").format("YYYY-MM-DD");
  const [range, setRange] = useState({
    from: aMonthAgo,
    to: today,
  });
  const handleChange = ({ target: { name, value } }) => {
    setRange((p) => ({ ...p, [name]: value }));
  };
  const getData = useCallback(() => {
    _postApi(
      "/customer/get-invoice",
      { query_type: "select", date_from: aMonthAgo, date_to: today },
      (data) => {
        setData(data.results);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [aMonthAgo]);

  useEffect(() => {
    getData();
  }, [getData]);

  let rows = [];
  data &&
    data.forEach((item, i) => {
      if (
        item.invoice_no.toLowerCase().indexOf(filterText.toLowerCase()) ===
          -1 &&
        item.name.toLowerCase().indexOf(filterText.toLowerCase()) === -1
      )
        return;

      rows.push(item);
    });

  return (
    <>
      {/* {JSON.stringify(data)} */}
      <CustomButton
        onClick={() => history.push("/app/report/invoice-report/new")}
        className="m-2"
      >
        <FiPlus /> Add New Invoice
      </CustomButton>
      <Card style={{ border: "1px solid green" }}>
        <CardHeader className="text-center font-weight-bold">
          Invoices
        </CardHeader>
        <CardBody>
          <DaterangeSelector
            handleChange={handleChange}
            from={range.from}
            to={range.to}
          />
          <SearchBar
            placeholder="Search by invoice or customer name"
            onFilterTextChange={(val) => setFilterText(val)}
            filterText={filterText}
          />
          <Table bordered>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Invoice No</th>
                <th>Date</th>
                <th>Custormer Name</th>
                <th>Description</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((item, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>{item.invoice_no}</td>
                  <td>{moment(item.created_at).format("DD-MM-YYYY")}</td>
                  <td>{item.name}</td>
                  <td>{item.item_name}</td>
                  <td>{formatNumber(item.amount)}</td>
                  <td className="text-right">
                    <Button
                      color="success"
                      size="sm"
                      onClick={() =>
                        history.push(
                          `/app/report/invoice-report/view?invoice_no=${item.invoice_no}`
                        )
                      }
                    >
                      <AiFillEye className="mr-1" />
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
}
