/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODES_OF_PAYMENT, TRANSACTION_TYPES } from "../../constants";
import { _customNotify } from "../../redux/helper";
import { saveTransaction } from "../../redux/actions/transactions";
import { v4 as UUIDV4 } from "uuid";
import moment from "moment";
import CustomForm from "../components/CustomForm";
import CustomButton from "../../app/components/Button";
import { getSuppliers } from "../../redux/actions/suppliers";
import CustomCard from "../../components/CustomCard";
import CustomTypeahead from "../../components/CustomTypeahead";
import { useHistory } from "react-router";
// import BackButton from '../../app/components/BackButton'
// import CustomCard from '../components/CustomCard'

export default function SupplierPayment() {
  const { supplierList } = useSelector((d) => d.suppliers) || [];
  const [loading, setLoading] = useState(false);
  const [supplierName, setSupplierName] = useState("");
  const [amount, setAmount] = useState(0);
  const { new_bankList } = useSelector((d) => d.bank.new_bankList);
  // const bankList = new_bankList.length? new_bankList:  [{bank_name:'Access bank', acctNo:'0766263664'}]
  const [mode, setMode] = useState(MODES_OF_PAYMENT.CASH);
  const initForm = {
    balance: "",
    modeOfPayment: MODES_OF_PAYMENT.CASH,
    account: "",
    bank: "",
    narration: "",
    chequeNo: "",
    selectedSupplier: "",
    source_account: new_bankList,
    id: "",
  };
  const [form, setForm] = useState(initForm);

  const [selectedAccount, setSelectedAccount] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSuppliers());
  }, [dispatch]);

  const fields = [
    {
      label: "Select Supplier",
      name: "selectedSupplier",
      // options: .map((s) => s.name),
      value: form.selectedSupplier,
      col: 5,
      type: "custom",
      component: () => (
        <CustomTypeahead
          label="Select Supplier"
          labelKey="name"
          options={supplierList}
          onChange={(s) => {
            if (s.length) {
              console.log(s);
              setForm((p) => ({
                ...p,
                name: s[0].name,
                supplier_code: s[0].supplier_code,
              }));
            }
          }}
          onInputChange={(v) => {
            if (v.length) {
              console.log(v, "KDDDDDDDK");
            }
          }}
        />
      ),
    },
    {
      label: "Mode Of Payment",
      type: "select",
      options: ["Cash", "POS", "Transfer", "Credit", "Cheque"],
      name: "modeOfPayment",
      value: form.modeOfPayment,
      col: 3,
    },
    {
      label: "Source Account",
      type: form.account.length > 0 ? "text" : "select",
      options:
        /*new_bankList && new_bankList.length>0?new_bankList.map(b=>`${b.bank_name}(${b.acctNo})`): */ [
          { bank_name: "Access bank", acctNo: "0766263664" },
        ].map((item) => `${item.bank_name}(${item.acctNo})`),
      name: "account",
      value: form.account,
      col: 4,
      switch: false,
      area_hidden: true,
      show: form.modeOfPayment.toLowerCase() === "bank transfer" ? false : true,
    },
    {
      label: "Payment Account",
      type: form.bank && form.bank.length ? "text" : "select",
      options: selectedAccount,
      name: "bank",
      value: form.bank,
      col: 5,
      area_hidden: true,
      switch: false,
      show: form.modeOfPayment.toLowerCase() === "bank transfer" ? false : true,
    },
    {
      label: "Amount",
      name: "amount",
      type: "number",
      value: form.amount,
      col: 3,
    },
    {
      label: "Narration",
      name: "narration",
      type: "text",
      value: form.narration,
      col: 4,
    },
  ];

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({
      ...p,
      [name]: value,
    }));
    if (name === "modeOfPayment" && value.toLowerCase() === "bank transfer") {
      console.info(name, value);
    } else if (name === "bank" || name === "account") {
      let ext0 = value.split("(")[1];
      let ext1 = ext0 && ext0.length && ext0.split(")")[0];
      console.log({ ext1 });
      setForm((p) => ({
        ...p,
        [name]: value.length ? ext1 : "",
      }));
    } else if (name === "selectedSupplier") {
      setSelectedAccount(
        supplierList
          .filter((item) => item.name === value)[0]
          ?.element.map((a) => `${a.bank_name}(${a.acctNo})`)
      );
    }
  };
  let receiptNo = moment().format("YYMDhms");
  const handleSubmit = () => {
    setLoading(true);
    let txn = [];
    txn.push({
      _id: UUIDV4(),
      amount: form.amount,
      quantity: 0,
      description: "Supplier Payment",
      receiptNo,
      narration: form.narration,
      modeOfPayment: mode,
      supplierId: form.supplier_code,
      supplierName,
      transaction_type: TRANSACTION_TYPES.SUPPLIER_PAYMENT,
      account: form.account,
      supplierAccount: form.bank,
      chequeNo: form.chequeNo,
    });

    saveTransaction(
      txn,
      () => {
        console.log("saved transaction");
        setLoading(false);
        _customNotify("Supplier Payment Recorded Successfully");
        setForm(initForm);
        history.push("/app/purchase/suppliers");
      },
      (err) => {
        console.log(err);
        setLoading(false);
      },
      TRANSACTION_TYPES.SUPPLIER_PAYMENT
    );
  };

  const formIsValid =
    form._id &&
    form._id !== "" &&
    amount !== 0 &&
    amount !== "0" &&
    amount !== "";

  return (
    <CustomCard
      back
      header={"Supplier Payment"}
      headerRight={<h6>Receipt No.: {receiptNo}</h6>}
    >
      {/* {JSON.stringify(form)} */}
      <CustomForm fields={fields} handleChange={handleChange} />
      {/* <ModeOfPayment
            mode={mode}
            setMode={setMode}
            bankAcct={form.account}
            setBankAcct={(v) => handleChange("account", v)}
            onBankSelect={setSelectedAccount}
            chequeNo={form.chequeNo}
            setChequeNo={(v) => handleChange("chequeNo", v)}
          /> */}
      <center>
        <CustomButton onClick={handleSubmit} loading={loading}>
          Submit
        </CustomButton>
      </center>
    </CustomCard>
  );
}
