import React, { useState } from "react";
import { Form, Row, FormGroup } from "reactstrap";
import { getSuppliers, saveNewSupplier } from "../../redux/actions/suppliers";
import CustomButton from "../../app/components/Button";
import { FaSave } from "react-icons/fa";
// import BackButton from '../../app/components/BackButton'
import { useDispatch, useSelector } from "react-redux";
import CustomForm from "../components/CustomForm";
import TextInput from "../components/TextInput";
import SelectInput from "../components/SelectInput";
import { useHistory } from "react-router";
import CustomCard from "../../components/CustomCard";
// import CustomCard from '../components/CustomCard'
// import { useSelector, useDispatch } from "react-redux";

export default function SupplierForm() {
  const [bank, addBank] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankDetails, addBankDetails] = useState([
    { acctName: "", acctNo: "", bank_name: "" },
  ]);
  const history = useHistory();
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    website: "",
    balance: 0,
  });
  const dispatch = useDispatch();

  const handleAdd = () => {
    if (bank) {
      addBankDetails((p) => [
        ...p,
        {
          acctName: "",
          acctNo: "",
          bank_name: "",
        },
      ]);
    }
    addBank(true);
  };
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({
      ...p,
      [name]: value,
    }));
  };

  const fields = [
    {
      label: "Supplier Name",
      name: "name",
      required: true,
      value: form.name,
    },
    {
      label: "Supplier Address",
      name: "address",
      required: false,
      value: form.address,
    },
    {
      label: "Phone Number",
      name: "phone",
      required: false,
      type: "number",
      value: form.phone,
    },
    {
      label: "Email",
      name: "email",
      required: false,
      value: form.email,
    },
    {
      label: "Website",
      name: "website",
      required: false,
      value: form.website,
    },
    {
      label: "Opening Balance",
      name: "balance",
      required: false,
      value: form.balance,
      type: "number",
    },
  ];

  const handleBankDetails = (key, value, index) => {
    let newList = [];
    bankDetails.forEach((item, i) => {
      if (index === i) {
        newList.push({ ...item, [key]: value });
      } else {
        newList.push(item);
      }
    });
    addBankDetails(newList);
  };
  const success_callback = () => {
    setLoading(false);
    dispatch(getSuppliers());
    history.push("/app/purchase/suppliers");
  };

  const handleSubmit = () => {
    setLoading(true);
    let element = [];
    if (bankDetails[0].acctName !== "" || bankDetails[0].acctNo !== "") {
      for (let index = 0; index < bankDetails.length; index++) {
        element.push(bankDetails[index]);
      }
    }
    let obj = {
      ...form,
      element,
    };
    console.log(obj);
    saveNewSupplier(
      obj,
      () => {
        success_callback();
      },
      (err) => {
        setLoading(false);
        alert("An error occured!");
      }
    );
  };
  const data = useSelector((d) => d.bank.bankList);
  return (
    <CustomCard
      back
      header={"Supplier Form"}
      // footer={
      //   <center>
      //     <CustomButton
      //       className="px-5"
      //       loading={loading}
      //       onClick={handleSubmit}
      //     >
      //       <FaSave /> Save
      //     </CustomButton>
      //   </center>
      // }
    >
      <Row>
        <CustomForm fields={fields} handleChange={handleChange} />
      </Row>
      {/* {JSON.stringify({ form })} */}
      <div className="row m-2">
        <CustomButton onClick={() => handleAdd()} className="offset-md-10">
          Add Bank Details
        </CustomButton>
      </div>
      {bank && (
        <Form>
          <div className="row">
            <p className="col-md-4 font-weight-bold">Account Name</p>
            <p className="col-md-4 font-weight-bold">Account No</p>
            <p className="col-md-4 font-weight-bold">Bank Name</p>
          </div>
          {bankDetails.map((item, index) => (
            <FormGroup row className="p-0 m-0 mb-1" key={index}>
              <TextInput
                container="col-md-4"
                className="mb-2"
                value={item.acctName}
                onChange={(e) => {
                  handleBankDetails("acctName", e.target.value, index);
                  // handleBankDetails('account_name', e.target.value, index)
                }}
              />
              <TextInput
                container="col-md-4"
                className="mb-2"
                value={item.acctNo}
                type="number"
                onChange={(e) => {
                  handleBankDetails("acctNo", e.target.value, index);
                  // handleBankDetails('account_number', e.target.value, index)
                }}
              />
              <SelectInput
                container="col-md-4"
                className="mb-2"
                options={data}
                value={item.bank_name}
                onChange={(e) =>
                  handleBankDetails("bank_name", e.target.value, index)
                }
              />
            </FormGroup>
          ))}
        </Form>
      )}

      <center>
        <CustomButton className="px-5" loading={loading} disabled={loading} onClick={() => handleSubmit()}>
          <FaSave /> Save
        </CustomButton>
      </center>
    </CustomCard>
  );
}
