import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTypeahead from "../../../components/CustomTypeahead";
import { getItemList } from "../../../redux/actions/purchase";

function SearchItemInput(props) {
  const dispatch = useDispatch();
  const itemList = useSelector((state) => state.purchase.purchaseList);
  const final = itemList.filter((i) => i.quantity > 0);
  const arr = final.filter(
    (v, i, a) => a.findIndex((t) => t.item_name === v.item_name) === i
  );
  const getList = useCallback(() => {
    dispatch(getItemList());
  }, [dispatch]);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <>
      {/* {JSON.stringify(final)} */}
      <CustomTypeahead
        {...props}
        _ref={props._ref}
        allowNew={props.allowNew}
        placeholder="Search items by name"
        // labelkey={props.labelkey?props.labelkey:"item_name"}
        labelKey="item_name"
        options={arr}
        onInputChange={(v) => {
          if (v.length) {
            console.log(v, "KDDDDDDDK");
            props.onInputChange(v);
          }
        }}
        onChange={(v) => {
          if (v.length) {
            props.onChange(v[0]);
            console.log(v[0], "KDDDDDDDK");
          }
        }}
      />
    </>
  );
}

export default SearchItemInput;
