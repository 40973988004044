import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Table, Col, Row, Button } from "reactstrap";
import useQuery from "../../inventria/hooks/useQuery";
import { getCustomerById, getCustomers } from "../../redux/actions/customer";
import { clearClientStmt } from "../../redux/actions/reports";
import DaterangeSelector from "../components/DaterangeSelector";
// import BackButton from '../components/BackButton'
import { formatNumber } from "../utilities";
import {
  getAccountStatement,
  getAccountStatementTotal,
  getClientAccBalance,
} from "./helpers";
import CustomCard from "../../components/CustomCard";
import SearchCustomerInput from "./components/SearchCustomerInput";
import { FiPrinter } from "react-icons/fi";
import PrintWrapper from "../../inventria/components/PrintWrapper";
import Loading from "../components/Loading";

export default function ViewCustomer() {
  const customerInputRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const customerId = query.get("id");
  const __balance = query.get("balance");
  const today = moment.utc().format("YYYY-MM-DD");
  const yearStart = moment().startOf("year").format("YYYY-MM-DD");
  const [range, setRange] = useState({
    from: yearStart,
    to: today,
  });

  const [selectedCustomer, setSelectedCustomer] = useState({});
  // const [openingBalance, setOpeningBalance] = useState(0);
  // const [closingBalance, setClosingBalance] = useState(0);

  const [statement, setStatement] = useState([]);

  // const statement = useSelector((state) => state.reports.clientAccountStatement)
  const [, setTotal] = useState(0);
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);

  const _getClientAccBalance = useCallback(() => {
    setLoading(true);
    getClientAccBalance(
      customerId,
      range.from,
      range.to,
      (data) => {
        setBalance(data);
        setLoading(false);
      },
      () => setLoading(false)
    );
  }, [range.from, range.to, customerId]);

  const _getAccountStatementTotal = useCallback(() => {
    setLoading(true);
    getAccountStatementTotal(
      customerId,
      range.from,
      range.to,
      (data) => {
        setTotal(data);
        setLoading(false);
      },
      () => setLoading(false)
    );
  }, [customerId, range.from, range.to]);

  const _getAccountStatement = useCallback(() => {
    setLoading(true);
    if (customerId && customerId !== "") {
      getAccountStatement(
        customerId,
        range.from,
        range.to,
        (data) => {
          if (data.length) {
            // console.log(data, '==========================')
            setStatement(data);
            // setOpeningBalance(data[0]['Open_Balance'])
            // setClosingBalance(data[data.length-1]['balance'])
            setLoading(false);
            // dispatch({ type: SET_CLIENT_STATEMENT, payload: data})
            // getStatementFromCache()
          }
        },
        () => {
          // getStatementFromCache()
          setLoading(false);
        }
      );
    }

    // getStatementFromCache()
  }, [customerId, range.from, range.to]);

  const getRecords = useCallback(() => {
    console.log("calling getrecords");
    // _getAccountStatement();
    _getAccountStatementTotal();
    _getClientAccBalance();
  }, [_getAccountStatementTotal, _getClientAccBalance]);

  // const totalDebit = statement.reduce((a, b) => a + parseFloat(b.debit), 0);
  // const totalCredit = statement.reduce((a, b) => a + parseFloat(b.credit), 0);

  const balanceIsNegative = parseFloat(balance) < 0;
  const formatCurrency = (val) => {
    if (parseInt(val) < 0) {
      return `(₦${formatNumber(Math.abs(balance))})`;
    } else {
      return `₦${formatNumber(balance)}`;
    }
  };

  useEffect(() => {
    _getAccountStatement();
  }, [_getAccountStatement]);
  // const showWayBill =
  //   useSelector(
  //     (state) => state.auth.activeBusiness.business_includes_logistics
  //   ) || false;
  // const defa = useSelector(state => state.auth.activeBusiness.business_includes_logistics)

  useEffect(() => {
    // showMessage({
    //   type: 'info',
    //   textStyle: {fontWeight: 'bold'},
    //   message: 'This report is offline and may not be update, connect to internet and click on "Get Report" button',
    //   duration: 3000,
    //   hideOnPress: true
    // })

    if (customerId) {
      getCustomerById("details", customerId, (customer) => {
        setSelectedCustomer(customer);
      });
      // customerInputRef.current.setState({
      //   text: selectedCustomer.customerName,
      // });
      dispatch(clearClientStmt());
      getRecords();
    }
  }, [dispatch, getRecords, customerId]);

  // const openingBalance = statement.length ? statement[0]['Open_Balance'] : 0
  // const closingBalance = statement.length
  //   ? statement[statement.length - 1].balance
  //   : 0
  const balance_ = statement[0] ? statement[0] : {};
  const _balance = statement ? statement[statement.length - 1] : {};
  const printReportPDF = () => {
    window.frames["print_frame"].document.body.innerHTML =
      document.getElementById("reportsPDF").innerHTML;
    window.frames["print_frame"].window.focus();
    window.frames["print_frame"].window.print();
  };

  const getList = useCallback(() => {
    setLoading(true);
    dispatch(
      getCustomers(
        () => setLoading(false),
        () => setLoading(false)
      )
    );
  }, [dispatch]);

  useEffect(() => {
    getList();
  }, [getList]);
  const customers = useSelector((state) => state.customer.customerList);
  let newArr = customers.find((i) => i.accountNo === customerId);
  return (
    <CustomCard
      back
      header="Customer Overview"
      headerRight={
        <Button onClick={printReportPDF} color="light">
          <FiPrinter className="mr-2" />
          Print Now
        </Button>
      }
    >
      {loading ? <Loading /> : false}
      {/* {JSON.stringify(newArr)} */}
      {/* {JSON.stringify(_balance)} */}
      <DaterangeSelector
        from={range.from}
        to={range.to}
        handleChange={({ target: { name, value } }) =>
          setRange((p) => ({ ...p, [name]: value }))
        }
      />

      <Col md={12} className="m-0 p-0">
        <SearchCustomerInput
          _ref={customerInputRef}
          inline
          label={"Select Customer"}
          // onChange={setSelectedCustomer}
          onChange={(selected) => {
            if (selected[0]) {
              history.push(
                `/app/customer/customer-view?id=${selected[0].accountNo}`
              );
            }
          }}
        />
      </Col>
      <div id="reportsPDF">
        <PrintWrapper title="Customer Report" from={range.from} to={range.to}>
          <Row clasName="mb-3 ">
            <Col
              md={12}
              className="align-items-center d-flex justify-content-between m-1 "
            >
              <div className="font-weight-bold mr-2">
                Name: {newArr.accName}
              </div>
              <div className="font-weight-bold mr-2">
                Phone: {newArr.contactPhone}
              </div>
              <div className="font-weight-bold">
                Address: {newArr.contactAddress}
              </div>
            </Col>
            <Col
              md={12}
              className="d-flex flex-direction-row justify-content-between m-1"
            >
              <div
                className={
                  balanceIsNegative
                    ? "text-danger font-weight-bold"
                    : " font-weight-bold"
                }
              >
                Opening Balance: ₦{formatNumber(balance_.dr)}
              </div>
              <div
                className={
                  balanceIsNegative
                    ? "text-danger font-weight-bold"
                    : " font-weight-bold"
                }
              >
                Closing Balance: ₦{formatNumber(__balance)}
              </div>
              <div
                className={
                  balanceIsNegative
                    ? "text-danger font-weight-bold"
                    : " font-weight-bold"
                }
              >
                Total Balance: ₦{formatNumber(__balance)}
              </div>
            </Col>
            {/* <Col md={6}>
              <Label>Address</Label>
              <Input
                readonly="readonly"
                value={selectedCustomer.address}
              />
            </Col>

            <Col md={6}>
              <Label for="exampleSelect">Phone</Label>
              <Input
                readonly="readonly"
                value={selectedCustomer.phone}
              />
            </Col>
            <Col md={6}>
              <Label>Email</Label>
              <Input
                readOnly="readonly"
                value={selectedCustomer.email}
              />
            </Col> */}
          </Row>
          <div style={{ height: "50vh", overflow: "scroll" }}>
            <Table bordered size="sm" className="mt-1">
              <thead>
                <tr>
                  <th className="text-center">S/N</th>
                  <th className="text-center">Date</th>
                  <th className="text-center">Description</th>
                  <th className="text-center">Quantity</th>
                  <th className="text-center">DR(₦)</th>
                  <th className="text-center">CR(₦)</th>
                  {/* <th className="text-center">Total</th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={8} className="text-right font-weight-bold">
                    Opening Balance: ₦{formatNumber(balance_.opening)}
                  </td>
                </tr>
                {statement.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>
                    <td>{item.description}</td>
                    {/* <td>{formatNumber(item.quantity)}</td> */}
                    <td className="text-center">
                      {item.quantity === 0 ? "-" : formatNumber(item.quantity)}
                    </td>
                    <td className="text-right">{formatNumber(item.cr)}</td>
                    <td className="text-right">{formatNumber(item.dr)}</td>
                    {/* <td className="text-right">{formatNumber(item.total)}</td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </PrintWrapper>
      </div>
      {/* {JSON.stringify({s:statement[0], openingBalance, closingBalance})} */}
      <iframe
        title="print_report"
        name="print_frame"
        width="0"
        height="0"
        src="about:blank"
        // style={styles}
      />
    </CustomCard>
  );
}
