import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Form, Table, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaCartPlus } from "react-icons/fa";
import CustomButton from "../../app/components/Button";
import CustomForm from "../components/CustomForm";
import validator from "validator";
import {
  CASH,
  CUSTOMER_TYPES,
  MODES_OF_PAYMENT,
  STORE,
  TRANSACTION_TYPES,
} from "../../constants";
import { v4 as UUIDV4 } from "uuid";
import "@blueprintjs/core/lib/css/blueprint.css";
import { getSuppliers } from "../../redux/actions/suppliers";
import { FiDelete } from "react-icons/fi";
import { saveTransaction } from "../../redux/actions/transactions";
import moment from "moment";
import {
  getPurchasedItems,
  saveNewPurchase,
} from "../../redux/actions/purchase";
import { useHistory, useLocation } from "react-router";
// import BackButton from '../../app/components/BackButton'
import CustomCard from "../../components/CustomCard";
// import SearchSuppliersInput from '../suppliers/SearchSuppliers'
import SearchSupplierInput from "../suppliers/SearchSupplier";
// import SearchStoresInput from "../../app/admin/stores/SearchStores";
import SearchItemInput from "../sales/make-sales/SearchItem";
import {
  formSettingSetup,
  saveFormSetup,
  updateFormSetup,
} from "../../redux/actions/formSetup";
import { formatNumber } from "../../app/utilities";
import StoreSelection from "../../app/admin/stores/StoreSelection";
import CustomSuccessAlert from "../components/CustomSuccessAlert";
import CustomNotifyAlert from "../components/CustomNotifyAlert";
import useQuery from "../hooks/useQuery";
// import { _fetchApi } from "../../redux/actions/api";
// import CustomCard from '../components/CustomCard'

const initialForm = {
  uom: "Unit",
  quantity: "",
  cost: "",
  expiry_date: "",
  // receivedTo: '',
  reorder: "0",
  // customerCategory: '',
  saveAsNewItem: true,
  account: "",
  modeOfPayment: MODES_OF_PAYMENT.CASH,
  // itemCategory: "",
  selling_price: "",
  item_code: "",
  truckNo: "",
  otherDetails: "",
  waybillNo: "",
  item_name: "",
  source_account: "",
  barcode: "",
  bank: "",
};
export default function CreatePurchase() {
  // const receivingStoreRef = useRef();
  // const itemNameRef=useRef()
  const store = useQuery().get("store");

  const dispatch = useDispatch();
  const formSetup = useSelector(
    (state) => state.formSettingSetup.purchaseSetup
  );
  const storeList = useSelector((state) => state.stores.storeList);
  const item_nameRef = useRef();

  useEffect(() => {
    dispatch(formSettingSetup("Purchase Form"));
  }, [dispatch]);
  const _formsetUp = formSetup.length ? formSetup[0] : {};

  const location = useLocation();
  const setupCond = location.pathname === "/app/setting/page";
  const history = useHistory();
  const [form, setForm] = useState(initialForm);
  const [setUp, setSetup] = useState(_formsetUp);
  const [data, setData] = useState([]);
  // const [alert, setAlert] = useState({ text: "", isOpen: false });
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [summiting, setSummiting] = useState(false);
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({
      ...p,
      [name]: value,
    }));
  };

  const user = useSelector((state) => state.auth.user);

  // const { new_bankList } = useSelector((d) => d.bank);
  // const bankList = new_bankList.length? new_bankList:  [{bank_name:'Access bank', acctNo:'0766263664'}]
  // const quantityIsValid = form.quantity && form.quantity !== "";
  // const costIsValid = form.cost && form.cost !== "";

  const formIsValid =
    form.item_name !== "" && form.quantity !== "" && form.cost !== "";

  const saveItem = (saveAsNew = true) => {
    // setLoading(true);
    form._id = UUIDV4();
    form.transaction_id = UUIDV4();
    let _markup = parseFloat(form.selling_price) - parseFloat(form.cost);
    let _markupTypes = [
      { customerCategory: CUSTOMER_TYPES.WALKIN, markup: _markup },
    ];
    if (!form.receivedTo || form.receivedTo === "") {
      if (storeList && storeList.length) {
        form.receivedTo = storeList[0]?.storeName;
        form.storeId = storeList[0]._id;
        setForm((p) => ({
          ...p,
          receivedTo: storeList[0]?.storeName,
          storeId: storeList[0]._id,
        }));
      }
    }

    setData((p) => [
      ...p,
      {
        _id: form._id,
        supplierName: form.supplier_name,
        item_name: item_name,
        item_category: form.itemCategory,
        uom: uom,
        quantity: quantity,
        quantity_available: quantity,
        propose_quantity: quantity,
        cost: cost,
        price: selling_price,
        markup: _markup && _markup !== "" ? _markup : 0,
        mark_up: _markup && _markup !== "" ? _markup : 0,
        item_code: form.item_code !== "" ? form.item_code : form._id,
        expiry_date: expiry_date,
        reorder: reorder && reorder !== "" ? reorder : 0,
        busName: user.busName ? user.busName : user.facilityId,
        branch_name: form.receivedTo ? form.receivedTo : user.branch_name,
        receivedTo: form.receivedTo || store,
        markupTypes: _markupTypes,
        saveAsNewItem: saveAsNew ? form.saveAsNewItem : false,
        exisitingId: null,
        truckNo: form.truckNo,
        waybillNo: form.waybillNo,
        otherDetails: form.otherDetails,
        modeOfPayment: form.modeOfPayment,
        source_account: form.source_account,
        bank: form.bank,
        account: form.account,
        supplier_code: form.supplier_code
          ? form.supplier_code
          : form.new_supplier_code,
        supplier_name: form.supplier_name,
        barcode: form.barcode,
        storeId: form.storeId,
      },
    ]);

    setForm((p) => ({ ...p, ...initialForm }));
    item_nameRef.current.clear();
  };

  const handleAdd = () => {
    if (
      validator.isEmpty(form.item_name) ||
      !validator.isLength(form.item_name, 2, 50)
    ) {
      CustomNotifyAlert("Invalid item name");
    } else if (validator.isEmpty(form.supplier_name)) {
      CustomNotifyAlert("Select supplier");
    } else if (!validator.isNumeric(form.cost)) {
      CustomNotifyAlert("Cost Price is not valid");
    } else if (!validator.isNumeric(form.selling_price)) {
      CustomNotifyAlert("Selling Price is not valid");
    } else if (!validator.isNumeric(form.quantity)) {
      CustomNotifyAlert("Quantity is not valid");
    } else if (
      form.receivedTo === "" ||
      form.receivedTo === null ||
      form.receivedTo === undefined
    ) {
      CustomNotifyAlert("Receiving store must be enter");
    } else if (formIsValid) {
      // setLoading(true);
      saveItem(true);
      // setForm({
      //   uom: 'Unit',
      //   quantity: '',
      //   cost: '',
      //   expiry_date: '',
      //   reorder: '0',
      //   customerCategory: '',
      //   saveAsNewItem: true,
      //   account: '',
      //   modeOfPayment: MODES_OF_PAYMENT.CASH,
      //   itemCategory: '',
      //   selling_price: '',
      //   truckNo: '',
      //   otherDetails: '',
      //   waybillNo: '',
      //   item_name: '',
      //   source_account: '',
      //   barcode: '',
      // })
    } else {
      CustomNotifyAlert("Please complete the form");
    }
  };

  const getSupplierList = useCallback(() => {
    dispatch(getSuppliers());
  }, [dispatch]);

  useEffect(() => {
    getSupplierList();
    setForm((p) => ({
      ...p,
      receivedTo: store,
    }));
  }, [getSupplierList, store]);

  const handleDelete = (i) => {
    const new_items = data.filter((item, index) => i !== index);
    setData(new_items);
  };
  // const { supplierList } = useSelector((state) => state.suppliers);
  // const storesList = useSelector((state) => state.stores.storeList)
  // console.log({ supplierList });
  // console.log({
  //   DDDDDDDDDDDDDATA:
  //     supplierList &&
  //     supplierList
  //       .filter((spl) => spl.name === form.supplier_name)[0]
  //       ?.element?.map((a) => `${a.bank_name}(${a.acctNo})`),
  // });
  let total = data.reduce(
    (it, id) => it + parseFloat(parseInt(id.cost) * parseInt(id.quantity)),
    0
  );
  const {
    uom,
    quantity,
    cost,
    expiry_date,
    reorder,
    itemCategory,
    selling_price,
    modeOfPayment,
    receivedTo,
    truckNo,
    otherDetails,
    item_name,
    waybillNo,
    // source_account,
    // bank,
  } = form;

  const fields = [
    {
      label: "Supplier Name",
      name: "supplier_name",
      type: "custom",
      component: () => (
        <SearchSupplierInput
          label="Supplier Name"
          onInputChange={(v) =>
            setForm((p) => ({
              ...p,
              supplier_name: v,
              supplier_code: UUIDV4(),
              // new_supplier_code: UUIDV4(),
            }))
          }
          onChange={(s) =>
            setForm((p) => ({
              ...p,
              supplier_name: s.name,
              supplier_code: s.supplier_code,
              // new_supplier_code: UUIDV4(),
            }))
          }
        />
      ),
      col: 3,
      switch: setupCond ? true : false,
      required: true,
    },
    {
      label: "Item Category",
      labelkey: "item",
      name: "itemCategory",
      options: [{ item: "--select item name--" }, { item: "Item" }],
      value: itemCategory,
      col: 3,
      switch: setupCond ? true : false,
      // show: setUp.itemCategory ? false : true,
    },
    {
      label: "Item Name",
      name: "item_name",
      type: "custom",
      required: true,
      component: () => (
        <SearchItemInput
          labelkey="item_name"
          label="Item Name"
          clearBtn
          _ref={item_nameRef}
          onInputChange={(v) =>
            setForm((p) => ({
              ...p,
              item_name: v,
              item_code: v,
            }))
          }
          onChange={(v) => {
            console.log(v);
            setForm((p) => ({
              ...p,
              item_name: v.item_name,
              item_code: v.item_code,
            }));
          }}
        />
      ),
      col: 3,
    },
    {
      label: "Unit of Measurement",
      labelkey: "label",
      options: [{ label: "--unit--" }, { label: "Other" }],
      name: "uom",
      value: uom,
      col: 3,
      switch: setupCond ? true : false,
      // show: setUp.uom ? false : true,
    },
    {
      label: "Bar code number",
      name: "barcode",
      value: form.barcode,
      col: 3,
      type: "text",
      // show: setUp.uom ? false : true,
    },
    {
      label: "Cost Price",
      type: "number",
      name: "cost",
      value: form.cost,
      col: 3,
      required: true,
    },
    {
      label: "Quantity",
      type: "number",
      name: "quantity",
      placeholder: "QTY",
      value: quantity,
      required: true,
      col: 3,
    },
    {
      label: "Selling Price",
      type: "number",
      name: "selling_price",
      value: selling_price,
      required: true,
      col: 3,
    },
    {
      label: "Reorder Level",
      type: "number",
      name: "reorder",
      value: reorder,
      placeholder: "0",
      col: 3,
      switch: setupCond ? true : false,
      // show: setUp.reorder ? false : true,
    },
    {
      label: "Expiry Date",
      type: "date",
      name: "expiry_date",
      value: expiry_date,
      col: 3,
      switch: setupCond ? true : false,
      // show: setUp.expiry_date ? false : true,
    },
    {
      label: "Mode Of Payment",
      type: "select",
      options: Object.values(MODES_OF_PAYMENT),
      name: "modeOfPayment",
      value: modeOfPayment,
      col: 3,
      switch: setupCond ? true : false,
      // show: setUp.modeOfPayment ? false : true,
    },

    // {
    //   label: "Source Account",
    //   type: form.source_account.length>0?'text': "select",
    //   options: bankList && bankList.length>0?bankList.map(b=>`${b.bank_name}(${b.acctNo})`):[],
    //   name: "source_account",
    //   value: source_account,
    //   col: 3,
    //   switch: setupCond ? true : false,
    //   show: modeOfPayment.toLowerCase()==='bank transfer' ? false: true
    // },
    // {
    //   label: "Payment Account",
    //   type: form.bank && form.bank.length?'text':"select",
    //   options:supplierList && supplierList.filter(spl=>spl.name===form.supplier_name)[0]?.element?.map(a=>`${a.bank_name}(${a.acctNo})`),
    //   name: "bank",
    //   value: bank,
    //   col: 3,
    //   switch: setupCond ? true : false,
    //   show: modeOfPayment.toLowerCase()==='bank transfer' ? false: true
    // },
    {
      label: "Receiving Store",
      type: "custom",
      required: true,
      component: () => (
        <StoreSelection
          label="Receiving Store"
          // selectDefault
          activeStore={form.receivedTo || store}
          selectStore={(s) => {
            if (s && s.storeName)
              setForm((p) => ({
                ...p,
                receivedTo: s.storeName,
                storeId: s._id,
              }));
          }}
          onDefaultSelect={(v) => {
            if (v && v.storeName) {
              setForm((p) => ({
                ...p,
                receivedTo: v.storeName,
                storeId: v._id,
              }));
            }
          }}
        />
        // <SearchStoresInput
        //   _ref={receivingStoreRef}
        //   label="Receiving Store"
        //   onChange={(s) =>
        //     setForm((p) => ({
        //       ...p,
        //       receivedTo: s.storeName,
        //       storeId: s._id,
        //     }))
        //   }
        //   selectDefault
        //   // onDefaultSelect={(i) => {
        //   //   // alert(i.storeName)
        //   //   // receivingStoreRef.current.setState({ text: i.storeName })
        //   //   setForm((p) => ({
        //   //     ...p,
        //   //     receivedTo: i.storeName,
        //   //     storeId: i._id,
        //   //   }))
        //   // }}
        // />
      ),
      name: "receivedTo",
      value: receivedTo,
      col: 3,
      switch: setupCond ? true : false,
      // show: setUp.receivedTo ? false : true,
    },
    {
      label: "Truck No.",
      name: "truckNo",
      value: truckNo,
      placeholder: "Enter truck number",
      col: 3,
      switch: setupCond ? true : false,
      // show: setUp.truckNo ? false : true,
    },
    {
      label: "Waybill No.",
      name: "waybillNo",
      value: waybillNo,
      placeholder: "Enter truck number",
      col: 3,
      switch: setupCond ? true : false,
      // show: setUp.waybillNo ? false : true,
    },
    {
      label: "Other Details",
      name: "otherDetails",
      size: 4,
      value: otherDetails,
      placeholder: "Other details if any...",
      col: 3,
      switch: setupCond ? true : false,
      // show: setUp.otherDetails ? false : true,
    },
  ];
  const success_callback = () => {
    setLoading(false);
    dispatch(getPurchasedItems(store));
    getPurchasedItems(store);
    console.log("err");
    setSummiting(false);
  };

  const handleSubmitSetUp = () => {
    const success_callback = () => {
      dispatch(formSettingSetup("Purchase Form"));
      CustomSuccessAlert("Successfully Update");
    };
    if (setUp._id) {
      updateFormSetup(setUp._id, setUp, success_callback, (e) =>
        console.log(e)
      );
    } else {
      saveFormSetup(
        { type: "Purchase Form", ...setUp },
        success_callback,
        (e) => console.log(e)
      );
    }
  };

  const handleSubmit = () => {
    // dispatch(
    setLoading(true);
    let receiptNo = moment().format("YYMDhms");
    let purchase_list = data.map((i) => ({
      ...i,
      reference_no: receiptNo,
      supplierTotalAmt: total,
      query_type: "received",
      version_id: UUIDV4(),
    }));

    saveNewPurchase(purchase_list, () => {
      // saveNewPurchase(data, () => {
      let txn = [];
      data.forEach((item) => {
        txn.push({
          _id: item.transaction_id,
          source: STORE,
          product_code: item.item_name,
          dr: item.cost,
          cr: 0,
          destination: CASH,
          quantity: item.quantity,
          description: item.item_name,
          markup: item.markup,
          trn: item.trn,
          receiptNo,
          transaction_type: "NEW_PURCHASE",
          supplier_code: item.supplier_code,
          truckNo: item.truckNo,
          waybillNo: item.waybillNo,
          otherDetails: item.otherDetails,
          supplierTotalAmt: total,
          item_code: item.item_code || "",
        });
      });
      saveTransaction(
        txn,
        () => {
          console.log("saved transaction");
          success_callback();
          setSummiting(false);
        },
        (err) => {
          console.log(err);
          setLoading(false);
        },
        TRANSACTION_TYPES.NEW_PURCHASE
      );
      setData([]);
      getPurchasedItems(store);
      history.push("/app/purchase/purchase-list");
    });

    // let obj = {
    //   element: [],
    //   name: form.supplier_name,
    //   supplier_code: form.new_supplier_code,
    //   address: "",
    //   phone: "",
    //   email: "",
    //   website: "",
    //   balance: -total,
    // };
    // console.log(obj);
    // if (
    //   form.supplier_code === "" ||
    //   form.supplier_code === undefined ||
    //   form.supplier_code === null
    // ) {
    //   saveNewSupplier(
    //     obj,
    //     () => {
    //       success_callback();
    //     },
    //     (err) => {
    //       setLoading(false);
    //       alert("An error occured!");
    //     }
    //   );
    // }
    setSummiting(false);
    setLoading(false);
  };

  return (
    <CustomCard back header={setupCond ? "" : "Stock Purchase"}>
      {/* {JSON.stringify({ form })} */}
      <Form>
        <Row>
          {/* {JSON.stringify(data)} */}
          <CustomForm
            fields={fields}
            handleChange={handleChange}
            setState={setSetup}
            state={setUp}
          />
        </Row>
        {/* <Alert
          canEscapeKeyCancel={true}
          canOutsideClickCancel={true}
          confirmButtonText="Okay"
          isOpen={alert.isOpen}
          loading={false}
          onConfirm={() => {
            setAlert((p) => ({ ...p, isOpen: false }));
          }}
          onCancel={() => {
            setAlert((p) => ({ ...p, isOpen: false }));
          }}
        >
          <p>{alert.text}</p>
        </Alert> */}
        {!setupCond ? (
          <center>
            <CustomButton onClick={() => handleAdd()} className="mb-2 px-5">
              <FaCartPlus />
              Add to Cart
            </CustomButton>
          </center>
        ) : (
          <center>
            <CustomButton onClick={handleSubmitSetUp}>
              Save the changes
            </CustomButton>
          </center>
        )}
        {/* {JSON.stringify({ form })} */}
        {/* <CustomButton onClick={() => {
          // receivingStoreRef.current.setState({ text: 'i.storeName' })
          setForm((p) => ({
            ...p,
            receivedTo: 'i.storeName',
            storeId: 'i._id',
          }))
        }}>Test</CustomButton> */}
        <h5 className="text-right">Total: {formatNumber(total)}</h5>
        {/* {JSON.stringify({ form, data })} */}
        {/* {JSON.stringify(data)} */}
        {data.length ? (
          <Table bordered striped size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Item name</th>
                <th>QTY</th>
                <th>Price</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item.item_name}</td>
                  <td className="text-center">{formatNumber(item.quantity)}</td>
                  <td className="text-right">{formatNumber(item.cost)}</td>
                  <td className="text-right">
                    {formatNumber(
                      parseInt(item.cost) * parseInt(item.quantity)
                    )}
                  </td>
                  <td className="text-center">
                    <Button
                      size="sm"
                      href="#delete"
                      onClick={() => handleDelete(i)}
                      className="btn btn-danger"
                    >
                      <FiDelete />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          ""
        )}

        {data.length ? (
          <div className="text-center">
            <CustomButton
              onClick={handleSubmit}
              loading={loading}
              className="px-5"
            >
              <FaCartPlus />{" "}
              {data.length < 1 && data.length === 0 ? "" : data.length} Submit
            </CustomButton>
          </div>
        ) : (
          ""
        )}
      </Form>
    </CustomCard>
  );
}
