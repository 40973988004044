import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
// import { Card, CardHeader, CardBody } from 'reactstrap'
import CustomCard from "../../components/CustomCard";
// import CustomCard from '../../inventria/components/CustomCard'
import { getCustomers, saveNewCustomer } from "../../redux/actions/customer";
// import BackButton from '../components/BackButton'
import CustomButton from "../components/Button";
import CustomerForm from "./components/CustomerForm";

export default function CustomerRegistartion() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const users = useSelector((state) => state.auth.user);
  const stores = users.store;
  const handleSubmit = () => {
    form.store = stores;
    setLoading(true);
    if (form.customerName !== "") {
      console.error({ form });
      saveNewCustomer(
        form,
        () => {
          setLoading(false);
          dispatch(getCustomers());
          // navigation.goBack();
          history.push("/app/customer/index");
        },
        (err) => {
          setLoading(false);
          console.log(err);
          // alert('error')
        }
      );
    }
  };

  const handleFormChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  return (
    <CustomCard back header={"Customer Registration"}>
      <CustomerForm form={form} handleFormChange={handleFormChange} />
      <center className="mt-1">
        <CustomButton
          loading={loading}
          // color="primary"
          size="2"
          onClick={handleSubmit}
        >
          Save Customer
        </CustomButton>
      </center>
    </CustomCard>
  );
}
