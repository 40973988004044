import moment from "moment";
import { _fetchApi } from "../../redux/actions/api";
import { getDataFromCache, saveDataToCache } from "../../redux/actions/reports";
import store from "../../redux/store";

export const getClientAccBalance = (
  client,
  from = "",
  to = "",
  callback = (f) => f,
  error = (f) => f
) => {
  let from_date = moment(from).format("YYYY-MM-DD");
  let to_date = moment(to).format("YYYY-MM-DD");
  const facilityId = store.getState().auth.activeBusiness.id;
  _fetchApi(
    `/api/account/get-client-statement?query_type=balance&clientId=${client}&from=${from}&to=${to}&facilityId=${facilityId}`,
    (data) => {
      // console.log(data, 'balance')
      if (data.results && data.results.length) {
        callback(data.results[0].balance);
        saveDataToCache(
          `stmt_bal_${client}_${facilityId}`,
          data.results[0].balance
        );
      }
    },
    () => {
      getDataFromCache(
        `stmt_bal_${client}_${facilityId}`,
        (data) => {
          callback(data);
        },
        (err) => {
          error(err);
          console.log("An error occured", err);
        }
      );
      // console.log('An error occured')
    }
  );
};

export const getAccountStatementTotal = (
  client = "",
  from = "",
  to = "",
  callback = (f) => f,
  error = (f) => f
) => {
  let from_date = moment(from).format("YYYY-MM-DD");
  let to_date = moment(to).format("YYYY-MM-DD");
  const facilityId = store.getState().auth.activeBusiness.id;
  _fetchApi(
    `/api/account/get-client-statement?query_type=total&clientId=${client}&from=${from_date}&to=${to_date}&facilityId=${facilityId}`,
    (data) => {
      // console.log(data, 'total')
      if (data.results && data.results.length) {
        // console.log(data.results)
        callback(data.results[0].total);
        saveDataToCache(
          `stmt_total_${client}_${facilityId}`,
          data.results[0].total
        );
      }
    },
    () => {
      getDataFromCache(
        `stmt_total_${client}_${facilityId}`,
        (data) => {
          callback(data);
        },
        (err) => {
          error(err);
          console.log("An error occured", err);
        }
      );
      // console.log('An error occured')
    }
  );
};

export const getAccountStatement = (
  client = "",
  from = "",
  to = "",
  callback = (f) => f,
  error = (f) => f
) => {
  console.log("calling account statement");
  let from_date = moment(from).format("YYYY-MM-DD");
  let to_date = moment(to).format("YYYY-MM-DD");
  const facilityId = store.getState().auth.activeBusiness.id;

  getDataFromCache(
    `stmt_${client}_${facilityId}`,
    (data) => {
      callback(data);
    },
    (err) => {
      error(err);
      console.log("An error occured", err);
    }
  );
  _fetchApi(
    `/api/account/get-client-statement?query_type=list&clientId=${client}&from=${from_date}&to=${to_date}&facilityId=${facilityId}`,
    (data) => {
      // console.log(data, 'stmts')
      if (data.results && data.results.length) {
        callback(data.results);
        saveDataToCache(`stmt_${client}_${facilityId}`, data.results);
      }
    },
    () => {
      console.log("Data was not fetched, trying to fetch from cache");
      // getDataFromCache(
      //   `stmt_${client}_${facilityId}`,
      //   (data) => {
      //     callback(data)
      //   },
      //   (err) => {
      //     error(err)
      //     console.log('An error occured', err)
      //   }
      // )
    }
  );
};
