import React from 'react'
import Nav from './Nav'
import Hero from './Hero'
import About from './About'
import Services from './Services'
import Footer from './Footer'
import Contact from './Contact'
import './styles.css'
import { withRouter } from 'react-router'

function LandingPage({ history }) {
  return (
    <div id="page-top">
      <Nav history={history} />
      <Hero />
      <About />
      <Services />
      <Contact />
      <Footer />
    </div>
  )
}

export default withRouter(LandingPage)
