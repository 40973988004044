import React from 'react'
import { useState } from 'react'
import { Col, Input, Label, Row } from 'reactstrap'
import CustomButton from '../../app/components/Button'
import CustomCard from '../components/CustomCard'

export default function SetupChartOfAccount() {
    const _form = {
        select_account_heard: "",
        search: "",
        account_subhead_code: "",
        account_subhead_description: "",
    }
    const [form, setForm] = useState(_form)
    const [data, setData] = useState([])

    const handleChange = ({ target: { name, value } }) => {
        setForm((p) => ({ ...p, [name]: value }))
    }
    const handleAdd = () => {
        // _postApi("classes", form, () => {
        setForm(_form)
        // },
        // (err) => console.log(err)
        // )
        console.log(_form)
    }
    return (
        <div>
            <CustomCard header="Chart of Account Setup">
                <Row>
                    <Col md="6">
                        <Label>Select Account Head</Label>
                        <Input type='text' name='select_account_heard'
                            value={form.select_account_heard}
                            onChange={handleChange} />
                    </Col>
                    {/* <Col md="2"></Col> */}
                    <Col md="6">
                        <Col md="6"></Col>
                        <Label></Label>
                        <Input type='search' placeholder='search...' name='search'
                            value={form.search}
                            onChange={handleChange} />
                    </Col>
                    <Col md="6">
                        <Label>Account Subhead Code</Label>
                        <Input type='text' name='account_subhead_code'
                            value={form.account_subhead_code}
                            onChange={handleChange} />
                    </Col>
                    <Col md="6"></Col>
                    <Col md="6">
                        <Label>Account Subhead Description</Label>
                        <Input type='text' name='account_subhead_description'
                            value={form.account_subhead_description}
                            onChange={handleChange} />
                    </Col>
                </Row>
                <CustomButton
                 className="mt-3"
                 onClick={handleAdd}
                 >
                    Create
                    </CustomButton>
            </CustomCard>
        </div>
    )
}
