import React from "react";
import { Table } from "reactstrap";
export default function BankTable({ data }) {
  return (
    <>
      <Table bordered size='sm'>
        <thead>
          <tr>
            <th>S/N</th>
            <th>Bank Name</th>
            <th>Account Number</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{item.bank_name}</td>
              <td>{item.acct_no}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
