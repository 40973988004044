import React from "react";
import { useSelector } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";

function SelectInput(props) {
  const { label, options, className, container, required = false } = props;
  const activeBusiness = useSelector((state) => state.auth.activeBusiness);

  return (
    <FormGroup className={`${container} my-2`}>
      {label && label !== "" ? (
        <Label className="font-weight-bold">
          {label} {required && <span className="text-danger">*</span>}
        </Label>
      ) : null}
      <Input
        type="select"
        className={`form-control-alternative ${className}`}
        {...props}
        style={{
          borderWidth: 2,
          borderColor: activeBusiness.primary_color,
        }}
      >
        <option>--select--</option>
        {options &&
          options.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
      </Input>
    </FormGroup>
  );
}

export default SelectInput;
