import React from 'react'

export default function AccountSetup() {
  
  return (
    <div>
      <h1>Account Setup Coming Soon</h1>
    </div>
  )
}
