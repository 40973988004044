import React from "react";
import { Row, Table } from "reactstrap";
import CustomButton from "../../../app/components/Button";
import { formatNumber } from "../../../app/utilities";
import CustomCard from "../../../components/CustomCard";
import { MODES_OF_PAYMENT } from "../../../constants";
import SimpleInput from "../../components/SimpleInput";
import { MdDeleteForever } from "react-icons/md";
import SearchCustomerInput from "../../../app/customer/components/SearchCustomerInput";

function CartList({
  list = [],
  setSelectedCustomer = {},
  otherInfo = {},
  handleOtherInfoChange = (f) => f,
  handleSubmit = (f) => f,
  amountPaidRef,
  handleDelete = (f) => f,
  submitting,
}) {
  let total = list.reduce((a, b) => a + parseFloat(b.amount), 0);
  let change = parseFloat(otherInfo.amountPaid) - total;
  let checkout = `Submit ₦ ${formatNumber(
    otherInfo.amountPaid > 0 ? otherInfo.amountPaid : total
  )}`;

  if (
    otherInfo.amountPaid === null ||
    otherInfo.amountPaid === 0 ||
    (otherInfo.amountPaid > 0 && otherInfo.amountPaid !== total)
  ) {
    otherInfo.amountPaidDefault =
      otherInfo.amountPaid > 0 ? otherInfo.amountPaid : total;
    otherInfo.total = total;
  }
  return (
    <CustomCard outline container="p-0" body="p-0">
      {/* <CardTitle className="text-center">Cart List</CardTitle> */}
      <div className="p-1">
        <SearchCustomerInput
          label="Customer Name"
          onChange={(v) => {
            setSelectedCustomer({ ...v[0] });
          }}
        />
        <div className="text-right font-weight-bold">
          Total Amount: {formatNumber(total)}
        </div>
        <div style={{ height: "50vh", overflow: "scroll" }}>
          <Table size="sm">
            <thead>
              <tr>
                <th className="text-center">Item</th>
                <th className="text-center">Qty</th>
                <th className="text-center">Price</th>
                <th className="text-center">Total</th>
                <th className="text-center">X</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, i) => (
                <tr key={i}>
                  <td>{item.item_name}</td>
                  <td className="text-center">{formatNumber(item.quantity)}</td>
                  <td className="text-right">{formatNumber(item.price)}</td>
                  <td className="text-right">{formatNumber(item.amount)}</td>
                  <td
                    className="text-right text-danger"
                    onClick={() => handleDelete(i)}
                  >
                    <MdDeleteForever size="20" />{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Row clas="m-0">
          <SimpleInput
            size={6}
            field={{
              type: "number",
              label: "Amount Paid ",
              name: "amountPaid",
              placeholder: total,
              value: otherInfo.amountPaid,
              size: "sm",
            }}
            handleChange={handleOtherInfoChange}
            _ref={amountPaidRef}
          />
          <SimpleInput
            size={6}
            field={{
              type: "read-only",
              label: change <= 0 ? "Balance" : "Change",
              name: "change",
              value: Math.abs(change) || 0,
              size: "sm",
            }}
            // handleChange={handleOtherInfoChange}
            // _ref={amountPaidRef}
          />
          <SimpleInput
            size={6}
            field={{
              type: "number",
              label: "Discount",
              name: "discount",
              value: otherInfo.discount,
              onFocus: (e) => e.target.select(),
              size: "sm",
            }}
            handleChange={handleOtherInfoChange}
          />
          <SimpleInput
            size={6}
            field={{
              type: "select",
              label: "Mode of Payment",
              name: "modeOfPayment",
              options: Object.values(MODES_OF_PAYMENT),
              value: otherInfo.modeOfPayment,
              size: "sm",
            }}
            handleChange={handleOtherInfoChange}
          />
          {/* <SimpleInput
            size={6}
            field={{
              type: "select",
              label: "Sale From",
              name: "salesFrom",
              options: Object.values(MODES_OF_PAYMENT),
              value: otherInfo.modeOfPayment,
              size:'sm'
            }}
            handleChange={handleOtherInfoChange}
          /> */}
        </Row>
        <center className="mt-1">
          <CustomButton
            loading={submitting}
            // disabled={total <= 0}
            onClick={(e) => {
              handleSubmit(e);
            }}
            className="px-5"
          >
            {checkout}
          </CustomButton>
        </center>
      </div>
    </CustomCard>
  );
}

export default CartList;
