import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'
// import { primaryColor } from '../../theme';

function HorizontalMenu(props) {
  const location = useLocation()
  const active = location.pathname.includes(props.route)
  const _active=props.active?props:active
  const activeBusiness = useSelector((state) => state.auth.activeBusiness)
  return (
    <Link
      {...props}
      to={props.route}
      className="list-group-item list-group-item-action"
      style={
        _active
          ? {
              border: `1px solid ${activeBusiness.primary_color}`,
              backgroundColor: '#eee',
              borderLeft: `5px solid ${activeBusiness.primary_color}`,
            }
          : { border: `1px solid ${activeBusiness.primary_color}` }
      }
    >
      {props.children}
    </Link>
  )
}

export default HorizontalMenu
