import React, { useState } from "react";
import "./login.css";
// import { login } from "../../api/authAPI";
import validator from "./loginValidator";
import logo from "../../images/logo.png";
import useLogin from "./useLogin";
import { Link } from "react-router-dom";
import CustomButton from "../../app/components/Button";
import { Card, Col, Input, Row } from "reactstrap";
import TextInput from "../components/TextInput";
import loginBg from "../../images/login-bg.png";
import { BiShow } from "react-icons/bi";
import { BsEyeSlashFill } from "react-icons/bs";
import { useSelector } from "react-redux";

const Login = () => {
  const { errors, values, handleChange, handleSubmit, loading } =
    useLogin(validator);

  const hasError = (field) => field && "error-input";
  const [showPass, setShowPass] = useState(false);
  const showPassword = (e) => {
    e.preventDefault();
    setShowPass((p) => !p);
  };
  const activeBusiness = useSelector((state) => state.auth.activeBusiness);
  return (
    <Row className="m-0">
      <Col
        style={{
          backgroundImage: `url(${loginBg})`,
          backgroundSize: "cover",
          opacity: 0.7,
        }}
      ></Col>
      {/* <Col md={4} className=''> */}
      <Card
        color=""
        className="col-md-4 d-flex flex-column justify-content-x m-0"
        style={{ height: "100vh" }}
      >
        <div
          style={{
            height: "40vh",
            display: "flex",
            flexDirection: "row",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link to="/" className="text-center">
            <img src={logo} className="logo" alt="inventria_logo" />
          </Link>
        </div>
        <form onSubmit={handleSubmit} className="d-flex flex-column ">
          {/* <h1 >Login</h1> */}
          <div className="px-md-5">
            <p className="h6 mb-4" style={{ textAlign: "center" }}>
              Enter your credentials to login
            </p>
            <TextInput
              type="text"
              onChange={handleChange}
              className={`${hasError(errors.user_id)} p-2 py-4 shadow`}
              style={{ fontSize: 20 }}
              name="user_id"
              value={values.user_id}
              placeholder="Phone number"
            />
            {errors.user_id && (
              <span className="text-danger error-txt">{errors.user_id}</span>
            )}

            <div class="input-group mb-3">
              <Input
                style={{
                  fontSize: 20,
                  borderWidth: 2,
                  borderColor: activeBusiness?.primary_color,
                }}
                type={!showPass ? "password" : "text"}
                name="password"
                value={values.password}
                placeholder="Password"
                onChange={handleChange}
                className={`${hasError(
                  errors.user_id
                )} p-2 py-4 shadow form-control`}
              />
              <div class="input-group-append">
                <CustomButton
                  class="btn btn-success size-sm"
                  onClick={showPassword}
                >
                  {showPass ? <BiShow /> : <BsEyeSlashFill />}
                </CustomButton>
              </div>
            </div>

            {errors.password && (
              <span className="text-danger error-txt">{errors.password}</span>
            )}
            {/* <div> */}
            <CustomButton
              type="submit"
              className="col-md-12 mb-3 py-2 shadow"
              loading={loading}
            >
              Login
            </CustomButton>
            {/* </div> */}
          </div>
          <div className="text-center">
            <p>
              <a className="forgot text-" href="/signup">
                Forgot password?
              </a>
            </p>
            <p>
              New user?{" "}
              <a className="forgot text-" href="/signup">
                Sign up here
              </a>
            </p>
          </div>
          {/* <input type="submit" name="" value="Login" /> */}
        </form>
      </Card>
      {/* </Col> */}
    </Row>
  );
};
export default Login;
