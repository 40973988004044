import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col,  Label,  Row } from "reactstrap";
// import SearchStoresInput from "../../../app/admin/stores/SearchStores";
import SimpleInput from "../../../inventria/components/SimpleInput";
import { getPurchasedItems } from "../../../redux/actions/purchase";
// import store from "../../../redux/store";
import SearchBar from "../../components/SearchBar";
import ActiveStoresList from "../../purchase/ActiveStoresList";

function SalesForm({
  disabled,
  form,
  handleChange = (f) => f,
  qttyRef,
  itemNameRef,
  setFilterText = (f) => f,
  options,
  user_id,
  activeStore,
  setActiveStore,
}) {
  const showImei = true;
  const [text, setText] = useState("");
  const [selected_store, setSelected_store] = useState("");
  const dispatch = useDispatch();
  const getPurchaseList = useCallback(() => {
    // alert(activeStore)
    dispatch(
      getPurchasedItems(selected_store, () => console.log("Loading..."))
    );
  }, [dispatch, selected_store]);
  useEffect(() => {
    getPurchaseList();
  }, [getPurchaseList]);

  return (
    <Row className="mb-1">
      {/* {JSON.stringify(activeBusiness)} */}
      <Col md={3}>
        <SearchBar
          _ref={itemNameRef}
          // placeholder="Search for items by code or name"
          filterText={text}
          onFilterTextChange={(v) => {
            setFilterText(v);
            setText(v);
          }}
        />
      </Col>
      {/* {JSON.stringify(form)} */}
      {/* <SimpleInput
        size={8}
        label="Item"
        field={{
          type: "number",
          value: form.item_name,
          name: "item_name",
          onChange: handleChange,
        }}
      /> */}

      <SimpleInput
        size={3}
        _ref={qttyRef}
        field={{
          label: "Enter Quantity",
          type: "number",
          value: form.quantity_sold,
          name: "quantity_sold",

          onChange: handleChange,
          // onFocus: (e) => qttyRef.current.select(),
        }}
      />
      {/* <Col md={3}> */}
      {/* <Label>Select Store</Label> 
      <SimpleInput
        className="form-control"
        handleChange={handleChange}
        size={3}
        field={{
          label: "Select Store",
          type: "select",
          value: form.quantity_sold,
          disabled:disabled,
          name: "store_name",
          options: disabled?[user_id.store]:['Show All Stores',...options.map((item) => item.storeName)]  || [],
          // onChange: handleChange,
          // onFocus: (e) => qttyRef.current.select(),
        }}
        // ref={qttyRef}
        // type="select"
        // value={form.quantity_sold}
        // name="quantity_sold"
        // onChange={handleChange}
      />
      {/* </Col> */}
      <Col>
        {/* <label>Select Store</label> */}
        <ActiveStoresList label='Select Store'
          activeStore={activeStore}
          setActiveStore={setActiveStore}
        />
      </Col>
      {showImei && (
        <SimpleInput
          size={3}
          field={{
            disabled: disabled,
            label: "IMEI Number",
            type: "number",
            value: form.imeiText,
            name: "imeiText",
            onChange: handleChange,
          }}
        />
      )}
    </Row>
  );
}

export default SalesForm;
