import React from "react"
import { AiOutlineShareAlt } from "react-icons/ai"
import { Card, CardHeader, CardBody,Table,Button} from "reactstrap"
import DaterangeSelector from "../../app/components/DaterangeSelector"
import SearchBar from "../../app/components/SearchBar"
export default function BankReport() {
    return (
        <>
         <Card style={{border:"1px solid green"}}>
             <CardHeader className="text-center font-weight-bold">Bank Report</CardHeader>
             <CardBody>
                 {/* <Button style={{float:"right"}} color="success" className="mb-5"><AiOutlineShareAlt />Export</Button> */}
                 <DaterangeSelector />
                 <SearchBar placeholder="search for daily report" />
                   <Table bordered>
                  <tr>
                      <th>S/N</th>
                      <th>Date</th>
                      <th>Custormer Name</th>
                      <th>Bank Name</th>
                      <th>Charges</th>
                  </tr>
                  <tr>
                      <td>1</td>
                      <td>2/2/12</td>
                      <td>none</td>
                      <td>100</td>
                      <td>100</td>
                  </tr>
                   </Table>
             </CardBody>
         </Card>
        </>
    )
}