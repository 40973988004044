import React, { useState } from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import CustomForm from "../../inventria/components/CustomForm";
import CustomButton from "../components/Button";
import { Tree } from "./Tree";

export const AccountChart = () => {
  const [form, setForm] = useState({});
  const onChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const fields = [
    {
      label: "Select Account Head",
      name: "account_head",
      value: form.account_head,
      col: 12,
      type: "text",
    },
    {
      label: "Select Account Head",
      name: "sub_head",
      value: form.sub_head,
      type: "text",
      col: 12,
    },
    {
      label: "Select Account Head",
      name: "description",
      value: form.description,
      type: "text",
      col: 12,
    },
  ];
  return (
    <Card>
      <CardHeader>
        <h5>Chart of Account Setup</h5>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={6}>
            <CustomForm fields={fields} handleChange={onChange} />
            <CustomButton>Save</CustomButton>
          </Col>
          <Col md={6}>
            {/* <Tree /> */}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
