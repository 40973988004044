import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import CustomForm from "../components/CustomForm";
import { FaPlus } from "react-icons/fa";
import CustomButton from "../../app/components/Button";
import UnitTable from "./UnitTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getUOM,
  pullUOMChanges,
  pushUOMChanges,
  saveNewUOM,
} from "../../redux/actions/uom";
import CustomScrollbar from "../../components/CustomScrollbar";
export default function UnitOfMeasure() {
  const _form = {
    unitName: "",
    measure: "",
    quantity: "",
  };

  const [form, setForm] = useState(_form);
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({
      ...p,
      [name]: value,
    }));
  };

  const fields = [
    {
      label: "Unit Name",
      name: "unitName",
      required: true,
      value: form.unitName,
    },

    { label: "Measure", name: "measure", required: true, value: form.measure },

    {
      label: "Quantity In Unit",
      name: "quantity",
      required: true,
      value: form.quantity,
    },
  ];

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const data = useSelector((d) => d.uom.uomList);

  const handleSubmit = () => {
    setLoading(true);
    saveNewUOM(
      form,
      () => {
        setLoading(false);
        dispatch(getUOM());
        setForm(_form);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  };
  const getUOMList = useCallback(() => {
    dispatch(getUOM());
  }, [dispatch]);

  useEffect(() => {
    getUOMList();
    pushUOMChanges(() => pullUOMChanges());
  }, [getUOMList]);

  return (
    <CustomScrollbar height="65vh" className='border shadow'>
      <Card>
        <CardHeader>Unit Of Measurement </CardHeader>
        <CardBody>
          <CustomForm fields={fields} handleChange={handleChange} />
          <CardHeader>
            <center>
              <CustomButton onClick={handleSubmit} loading={loading}>
                <FaPlus />
                Add New
              </CustomButton>
            </center>
          </CardHeader>
        </CardBody>

        <UnitTable data={data} />
      </Card>
    </CustomScrollbar>
  );
}
