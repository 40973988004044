import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTypeahead from "../../components/CustomTypeahead";
import { getSuppliers } from "../../redux/actions/suppliers";

export default function SearchSupplierInput(props) {
  const dispatch = useDispatch();
  const options = useSelector((state) => state.suppliers.supplierList);

  const getList = useCallback(() => {
    dispatch(getSuppliers());
  }, [dispatch]);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <CustomTypeahead
      {...props}
      options={options}
      labelKey='name'
      onInputChange={(v) => {
        if (v.length) {
          console.log(v, "KDDDDDDDK");
          props.onInputChange(v);
        }
      }}
      onChange={(v) => {
        if(v.length) {
          props.onChange(v[0])
        }
      }}
    />
  );
}
