import React from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { withRouter } from "react-router";
import CustomButton from "./Button";

function BackButton({ text = "Back", history, className }) {
  return (
    <CustomButton className={`btn btn-default ${className}`} onClick={() => history.goBack()}>
      <span className='d-flex flex-direction-row align-items-center px-3'>
        <AiOutlineLeft className='mr-1' size={20} />
        {text}
      </span>
    </CustomButton>
  );
}

export default withRouter(BackButton);
