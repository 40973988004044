import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaCcPaypal, FaPlus } from "react-icons/fa";
import { Button, Table } from "reactstrap";
import { useHistory } from "react-router";
import SearchBar from "../../app/components/SearchBar";
import CustomButton from "../../app/components/Button";
import { getSuppliers, saveNewSupplier } from "../../redux/actions/suppliers";
import { formatNumber } from "../../app/utilities";
import CustomScrollbar from "../../components/CustomScrollbar";
import CustomCard from "../../components/CustomCard";
import CustomModal from "../components/CustomModal";
import { _customNotify, _warningNotify } from "../../redux/helper";

export default function SupplierTable() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.suppliers.supplierList);
  const [searchText, setSearchText] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false)
  const [toggle, setToggle] = useState(false)
  const history = useHistory();

  const toggleModal = useCallback(() => {
    setToggle(!toggle);
    setIsOpen(!isOpen)
  }, [setIsOpen, setToggle, isOpen, toggle])

  const getSupplierList = useCallback(() => {
    setLoading(true);
    dispatch(
      getSuppliers(
        () => setLoading(false),
        () => setLoading(false)
      )
    );
  }, [dispatch]);
  useEffect(() => {
    getSupplierList();
  }, [getSupplierList]);

  const handleDelete = (item) => {
    setLoading(true);
    if(window.confirm(`Are you sure you want to delete  "${item.name}" supplier?`)===true){
    saveNewSupplier(
      { ...item, query_type: 'delete' },
      (res) => {
        setLoading(false);
        _customNotify("Deleted");
        getSupplierList()
        toggleModal();
      },
      (err) => {
        setLoading(false);
        _warningNotify("Error: " + err.toString());
        toggleModal();
      }
    );
  }
  }
  const rows = [];
  data.length &&
    data.forEach((item, index) => {
      if (
        item.name.toString().toLowerCase().indexOf(searchText.toLowerCase()) ===
        -1 &&
        item.phone
          .toString()
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) === -1 &&
        item.email
          .toString()
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) === -1 &&
        item.address
          .toString()
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) === -1
      )
        return;
    });

  return (
    <CustomCard header={"Suppliers"}>
      <CustomButton
        color="primary"
        className="mb-3 mr-3"
        onClick={() => history.push("/app/purchase/suppliers/new")}
      >
        <FaPlus />
        Add New Supplier
      </CustomButton>
      {/* {JSON.stringify(data)} */}
      <CustomButton
        color="primary"
        className="mb-3"
        onClick={() => history.push("/app/purchase/suppliers/payment")}
      >
        <FaCcPaypal /> Add Supplier payment
      </CustomButton>
      <SearchBar
        placeholder="Search by supplier name"
        onFilterTextChange={(val) => setSearchText(val)}
        filterText={searchText}
      />

      <CustomScrollbar height="62vh">
        {/* {JSON.stringify(data)} */}
        <Table bordered>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Supplier Name </th>
              <th>Phone</th>
              <th>Email</th>
              <th>Address</th>
              <th>Current Balance (₦)</th>
              <th colSpan={2}>Action</th>
            </tr>
          </thead>
          <tbody>

          {data.length ? data.map((item,index)=>(
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{item.name}</td>
          <td>{item.phone}</td>
          <td>{item.email}</td>
          <td>{item.address}</td>
          <td className="text-right">{formatNumber(item.balance)}</td>
          <td>
            <CustomButton
              size="sm"
              color="primary"
              onClick={() =>
                history.push(
                  `/app/purchase/suppliers/report?supplier_id=${item.supplier_code}&supplier_name=${item.name}&balance=${item.balance}`
                )
              }
            >
              View
            </CustomButton>
          </td>
          <td>
            <Button
              size="sm"
              color="danger" onClick={() => handleDelete(item)}>
              Delete
            </Button>
          
          </td>
        </tr>)):(
            <h4 className="text-secondary">No data please come back later</h4>
          )}
        </tbody>        
        </Table>
      </CustomScrollbar>
    </CustomCard>
  );
}
