// import { Tab } from "evergreen-ui";
import { PDFViewer } from "@react-pdf/renderer";
import moment from "moment";
import React, { useState } from "react";
import { FiDelete } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, Table } from "reactstrap";
import CustomButton from "../../app/components/Button";
import { formatNumber } from "../../app/utilities";
import CustomCard from "../../components/CustomCard";
import { _postApi } from "../../redux/actions/api";
import { _customNotify } from "../../redux/helper";
import CustomForm from "../components/CustomForm";
import FinalInvoice from "../sales/InvoiceTemp/FinalInvoice";

export default function InvoiceForm() {
  const [form, setForm] = useState({
    name: "",
    phone: "",
    address: "",
    item_name: "",
    quantity: 0,
    amount: "",
    cost: "",
  });
  const [data, setData] = useState([]);
  const [preview, setPreview] = useState(false);

  let receiptNo = moment().format("MMhhssDDYYms");
  const users = useSelector((state) => state.auth.user);
  const user = useSelector((state) => state.auth.activeBusiness);
  const history = useHistory();

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({
      ...p,
      [name]: value,
    }));
  };

  const handleDelete = (index) => {
    let newData = data.filter((i, id) => index !== id);
    setData(newData);
  };

  const arr = [
    "Sales Invoice",
    "Transfer Invoice",
    "Pucharse Invoice",
    "Expense Invoice",
    "Other Invoice",
  ];
  const fields = [
    {
      label: "Invoice type",
      name: "type",
      required: true,
      value: form.type,
      type: "select",
      options: arr,
      col: 6,
    },
    {
      label: "Name",
      name: "name",
      required: true,
      value: form.name,
      col: 6,
    },
    {
      label: "Address",
      name: "address",
      required: false,
      value: form.address,
      col: 6,
    },
    {
      label: "Phone Number",
      name: "phone",
      required: false,
      type: "number",
      value: form.phone,
      col: 6,
    },
  ];

  const fields2 = [
    {
      label: "Description",
      name: "item_name",
      required: false,
      value: form.item_name,
    },
    {
      label: "Quantity",
      name: "quantity",
      required: false,
      value: form.quantity,
    },
    {
      label: "Cost Price",
      name: "cost",
      required: false,
      value: form.cost,
      type: "number",
      handleChange: ({ target: { value } }) => {
        setForm((p) => ({
          ...p,
          cost: value,
          amount: parseInt(form.quantity) * parseInt(value),
        }));
      },
    },
    {
      label: "Amount",
      name: "amount",
      required: false,
      value: form.amount,
      type: "number",
      disabled: true,
    },
  ];

  const handleAdd = () => {
    setData((p) => [...p, form]);
    setForm((p) => ({
      ...p,
      item_name: "",
      quantity: 0,
      amount: "",
      cost: 0,
    }));
  };

  const handleSubmit = () => {
    let newData = [];
    data.forEach((i) =>
      newData.push({
        ...i,
        invoice_no: receiptNo,
        facilityId: user.id,
        query_type: "insert",
        created_by: users.username,
      })
    );
    _postApi(
      "/customer/post-invoice",
      { newData },
      (res) => {
        setPreview(true);
        _customNotify(res.msg);
        console.log(newData, "LDLLDLDLD");
      },
      (err) => {
        console.log(err);
      }
    );
  };
  let details = data && data.length ? data[0] : {};
  const total = data.reduce((it, i) => it + parseFloat(i.amount), 0);
  return (
    <div>
      {/* {JSON.stringify(users)} */}
      {!preview ? (
        <CustomCard
          back
          header="Invoice Form"
          headerRight={`Invoice No: ${receiptNo}`}
        >
          <CustomForm fields={fields} handleChange={handleChange} />
          <hr />
          <CustomForm fields={fields2} handleChange={handleChange} />
          <center>
            <Button
              color="primary"
              outline
              className="m-2 px-3"
              onClick={handleAdd}
            >
              Click to add
            </Button>
          </center>
          <Table size="sm" bordered striped>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Cost</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item.item_name}</td>
                  <td>{item.quantity}</td>
                  <td>{formatNumber(item.cost)}</td>
                  <td className="text-right">{formatNumber(item.amount)}</td>
                  <td className="text-right">
                    <Button
                      onClick={() => handleDelete(i)}
                      color="danger"
                      size="sm"
                    >
                      <FiDelete /> Del
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <center>
            <CustomButton className="px-5" onClick={handleSubmit}>
              Submit Invoice
            </CustomButton>
          </center>
        </CustomCard>
      ) : (
        <div className="d-flex">
          <div className="m-2">
            <Button
              color="danger"
              onClick={() => {
                history.push("/app/report/invoice-report");
                setPreview(false);
              }}
            >
              Close
            </Button>
          </div>
          <PDFViewer height="700" width="1100">
            <FinalInvoice
              data={data}
              info={details}
              //  page={page}
              receiptNo={details.invoice_no}
              busInfo={user}
              users={users}
              _customerName={details.name}
              total={total}
            />
          </PDFViewer>
        </div>
      )}
    </div>
  );
}
