import React from "react";
import { Container, Row, Col } from "reactstrap";
import Menu from "./Menu";
import { Redirect, Route, Switch } from "react-router";
import SignUp from "../auth/Signup";
import Users from "./Users";
import StaffReview from "./StaffReview";
import ManageStores from "./Stores";
import { AccountChart } from "./AccountSetup";

function AdminPage() {
  return (
    <Container fluid>
      <Row>
        <Col md={3}>
          <Menu />
        </Col>
        <Col>
          <Switch>
            <Redirect from="/app/admin" to="/app/admin/manage-users" exact />
            <Route path="/app/admin/manage-users/new-user" component={SignUp} />
            <Route path="/app/admin/manage-users" component={Users} />
            <Route path="/app/admin/manage-stores" component={ManageStores} />
            <Route path="/app/admin/staffreview/:id" component={StaffReview} />
            <Route path="/app/admin/account-setup" component={AccountChart} />
          </Switch>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminPage;
