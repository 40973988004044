import React from "react"
import { AiOutlineShareAlt } from "react-icons/ai"
import { Card, CardHeader, CardBody,Table,Button} from "reactstrap"
import DaterangeSelector from "../../app/components/DaterangeSelector"
import SearchBar from "../../app/components/SearchBar"
export default function DailyReport() {
    return (
        <>
         <Card style={{border:"1px solid green"}}>
             <CardHeader className="text-center font-weight-bold">Daily Report</CardHeader>
             <CardBody>
                 <Button style={{float:"right"}} color="success" className="mb-5"><AiOutlineShareAlt />Export</Button>
                 <DaterangeSelector className="mt-5"/>
                 <SearchBar placeholder="search for daily report" />
                   <Table bordered>
                  <tr>
                      <th>S/N</th>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Amount</th>
                  </tr>
                  <tr>
                      <td>1</td>
                      <td>2/2/12</td>
                      <td>none</td>
                      <td>100</td>
                      <td>100</td>
                  </tr>
                   </Table>
             </CardBody>
         </Card>
        </>
    )
}