import React from "react";
import VerticalMenu from "../../components/vertical-menu/VerticalMenu";
import HorizontalMenu from "../../components/vertical-menu/HorizontalMenu";
import { FaStore, FaUsers } from "react-icons/fa";

function Menu() {
  return (
    <VerticalMenu title='Menu'>
      {/* <HorizontalMenu route="/app//new-user">Create User</HorizontalMenu> */}
      <HorizontalMenu route='/app/admin/manage-users'>
        <FaUsers size={26} fontWeight='bold' style={{ marginRight: 10 }} />
        Manage Users
      </HorizontalMenu>
      <HorizontalMenu route='/app/admin/manage-stores'>
        <FaStore size={26} fontWeight='bold' style={{ marginRight: 10 }} />
        Manage Stores
      </HorizontalMenu>

      {/* <HorizontalMenu route='/app/admin/staffreview'>
        <FaUsers size={26} fontWeight='bold' style={{ marginRight: 10 }} />
        Staff Review
      </HorizontalMenu> */}
      {/* <HorizontalMenu route='/app/admin/account-setup'>
        <FaCalculator size={26} fontWeight='bold' style={{ marginRight: 10 }} />
        Account Setup
      </HorizontalMenu> */}
      {/* <HorizontalMenu route="/app/admin/users">Creat New Facility</HorizontalMenu> */}
    </VerticalMenu>
  );
}

export default Menu;
