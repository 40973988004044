import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import TabBar from "./TabBar";

function TabWrapper(props) {
  const theme = useSelector((state) => state.auth.activeBusiness);
  const { steps = [], barWidth = "20vw", outline = false, route, step } = props;
  // const [step, setStep] = useState(0);
  const history = useHistory();

  return (
    <div
      className={`${outline ? " rounded" : ""} `}
      style={{
        borderColor: theme.primary_color,
      }}
    >
      <div>
        <center>
          <TabBar
            barWidth={barWidth}
            current={step}
            items={steps}
            onItemClick={(i) => history.push(`${route}?tab=${i}`)}
          />
        </center>
      </div>
      <div className="mt-2">
        {props.children}
      </div>
    </div>
  );
}

export default TabWrapper;
