import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { Nav, NavItem, NavLink } from "reactstrap";

const TabBar = ({ items = [], current = "", onItemClick = (f) => f }) => {
  const theme = useSelector((state) => state.auth.activeBusiness);
  return (
    <Nav
      className='nav-fill flex-column flex-sm-row'
      id='tabs-text'
      pills
      role='tablist'
    >
      {items.map((_item, index) => (
        <NavItem>
          <NavLink
            style={{
              backgroundColor:
                _item === current ? theme.primary_color : "#f1f1f1",
              color: _item !== current ? "black" : "",
            }}
            aria-selected={_item === current}
            className={classNames("mb-sm-3 mb-md-0 mr-1 rounded-right-3", {
              active: _item === current,
            })}
            onClick={() => onItemClick(_item)}
            href='#'
            role='tab'
            key={index}
          >
            {_item}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export default TabBar;
