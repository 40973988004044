import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Form, FormGroup, Row } from "reactstrap";
import CustomButton from "../../app/components/Button";
import { signup } from "../../redux/actions/auth";
import { primaryColor } from "../../theme";
import CustomForm from "../components/CustomForm";
import logo from "../../images/logo.png";
import "./login.css";
import { BsEyeSlashFill } from "react-icons/bs";
import { BiShow } from "react-icons/bi";
import background from "../../images/design.png";

export default function Signup() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form, setForm] = useState({
    username: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    busName: "",
    address: "",
  });
  const [signupLoading, setSignupLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const showPassword = (e) => {
    e.preventDefault();
    setShowPass((p) => !p);
  };

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({
      ...p,
      [name]: value,
    }));
  };

  const {
    username,
    email,
    phone,
    password,
    confirmPassword,
    busName,
    address,
  } = form;

  const dSame = password === confirmPassword;

  const fields = [
    { name: "username", value: username, label: "User Name", col: 6 },
    { name: "busName", value: busName, label: "Business Name", col: 6 },
    {
      name: "address",
      value: address,
      label: "Business Address",
      col: 12,
      type: "textarea",
    },
    { name: "phone", value: phone, label: "Phone number", col: 6, type: "tel" },
    { name: "email", value: email, label: "Email", col: 6, type: "email" },
    // {
    //   name: "password",
    //   value: password,
    //   label: "Enter your password",
    //   type: "password",
    //   col: 6,
    // },
    // {
    //   name: "confirmPassword",
    //   value: confirmPassword,
    //   label: "Confirm Password",
    //   type: "password",
    //   col: 6,
    // },
  ];

  const resetForm = () => {
    setForm({
      username: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      busName: "",
      address: "",
    });
  };

  const goHome = () => {
    resetForm();
    setSignupLoading(false);
    history.push("/app");
  };

  const hadleSubmit = () => {
    if (username === "" || phone === "") {
      alert("Incomplete form");
    } else if (!dSame) {
      alert("Unmatch password!");
    } else {
      setSignupLoading(true);
      const error = () => setSignupLoading(false);
      dispatch(signup(form, goHome, error));
      // console.log(form)
      // alert('Successfully!')
    }
    // console.log(form)
  };
  let passwordErr = dSame ? "The password matched!" : "Password does not match"
  return (
    <div
      style={{
        paddingTop: "2%",
        paddingBottom: "2%",
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover'
        // display: "flex",
      }}
    >
      {/* <div className="text-right">
        <img
          src={require("../../images/design12.png").default}
          alt="sign up img"
          // className="img-fluid"
          style={{ width: "130%" }}
        />
      </div> */}
      <div className="box_n shadow">
        <center className="d-flex justify-content-between">
          <Link to="/" className="text-center">
            <img src={logo} className="logo" alt="inventria_logo" />
          </Link>
          <h2 className="mt-4 font-weight-bold" style={{ color: primaryColor }}>
            SIGN UP
          </h2>
        </center>
        <Form>
          <Row className="p-0 m-0">
            {/* {JSON.stringify(data)} */}
            <CustomForm fields={fields} handleChange={handleChange} />
          </Row>
          <FormGroup row className="m-1">
            <div className="col-md-6 col-lg-6">
              <label className="font-weight-bold">Password</label>
              <div class="input-group">
                <input
                  type={!showPass ? "password" : "text"}
                  name="password"
                  value={password}
                  onChange={handleChange}
                  className="form-control "
                />
                <div class="input-group-append">
                  <CustomButton
                    class="btn btn-success size-sm"
                    onClick={showPassword}
                  >
                    {showPass ? <BiShow /> : <BsEyeSlashFill />}
                  </CustomButton>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <label className="font-weight-bold">Confirm Password</label>
              <div class="input-group">
                <input
                  type={!showPass ? "password" : "text"}
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleChange}
                  className="form-control "
                />
                <div class="input-group-append">
                  <CustomButton
                    class="btn btn-success size-sm"
                    onClick={showPassword}
                  >
                    {showPass ? <BiShow /> : <BsEyeSlashFill />}
                  </CustomButton>
                </div>
              </div>
            </div>
          </FormGroup>
        </Form>
        <div>
          <div
            style={{ color: dSame ? "green" : "red", textAlign: "center" }}
            className="my-1"
          >
            {password !=="" && passwordErr}
          </div>
        </div>
        <center>
          <CustomButton
            className="px-5 text-white m-2"
            outline
            loading={signupLoading}
            onClick={() => hadleSubmit()}
          >
            Sign up now
          </CustomButton>

          <div className="pb-2">
            <p className="my-1">
              Already have an account?
              <span
                onClick={() => history.push("/login")}
                className="font-weight-bold"
                style={{ color: primaryColor, cursor: "pointer" }}
              >
                Login
              </span>
            </p>
          </div>
        </center>
      </div>
      
    </div>
  );
}
