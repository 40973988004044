import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import CustomTypeahead from '../../components/CustomTypeahead'
import { getStores } from "../../redux/actions/stores";
import SelectInput from "../components/SelectInput";

function ActiveStoresList({ setActiveStore = (f) => f, activeStore = "", label='' }) {
  const [list, setList] = useState([]);
  const buz_id = useSelector(
    (state) => state.auth.activeBusiness.business_admin
  );
  const user_id = useSelector((state) => state.auth.user);
  const check = parseInt(buz_id) === parseInt(user_id.id);

  const users = useSelector((state) => state.auth.user);
  const stores = users.store;
  let nerStore = stores.split(",");

  const getList = useCallback(() => {
    getStores(
      "list",
      (data) => {
        if (data && data.results) {
          let list = [...data.results, { storeName: "Show All Stores" }];
          // setList(check ? list : nerStore);
          setList(check ? list : data.results);
          if (list.length) {
            let selected = list[0];
            setActiveStore(users.branch_name || selected.storeName);
            // searchRef.current.setState({ text: selected.storeName })
            // searchRef.current.focus()
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, [setActiveStore, users,check]);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <>
      {/* {JSON.stringify({ nerStore, stores, users,list })} */}
      <SelectInput label={label}
        value={activeStore}
        options={!check ? nerStore : list.map((a) => a.storeName)}
        onChange={({ target: { value } }) => setActiveStore(value)}
      />
      {/* {JSON.stringify(list)} */}
      {/* // <CustomTypeahead
    //   _ref={searchRef}
    //   options={list}
    //   labelKey="storeName"
    //   onChange={(v) => {
    //     if (v.length) {
    //       setActiveStore(v[0].storeName)
    //     }
    //   }}
    // /> */}
    </>
  );
}

export default ActiveStoresList;
