import React from "react";
import { View, Text, Document, Page, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import { formatNumber } from "../../../app/utilities";

export default function FinalInvoice({
  data = [],
  total = 0,
  info,
  busInfo,
  grandTotal,
  balance,
  page,
  receiptNo,
  users,
  _customerName,
}) {
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>
          {info.type || "Sales Invoice"}
        </Text>
        {/* <View style={styles.circle} /> */}

        <View style={{ flexDirection: "row", marginTop: "10" }}>
          <View style={styles.text}>
            <Text>From</Text>
            <Text style={styles.subtitle}>{busInfo.business_name}</Text>
            <Text style={styles.text}>{busInfo.business_address}</Text>
            {/* <Text style={styles.text}>State: Nigeria</Text> */}
            <Text style={styles.text}>Phone: {busInfo.business_phone}</Text>
            <Text style={styles.text}>Operator Name:{users.username}</Text>
          </View>

          <View style={{ marginLeft: 200 }}>
            <Text>For</Text>
            <Text style={styles.subtitle}>Customer Name:</Text>
            <Text style={styles.subtitle1}>{_customerName}</Text>
            {/* <Text style={styles.text}>name@client.com </Text> */}
            <Text style={styles.text}>Client Address:</Text>
            <Text style={styles.subtitle1}>{info.address}</Text>
            {/* <Text style={styles.text}>State: Country</Text> */}
            <Text style={styles.text}>Phone: {info.phone || ""}</Text>
          </View>
        </View>

        <View style={{ borderBottom: "1", marginTop: "20" }} />

        <View
          style={{
            marginTop: "20",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text style={styles.text}>Invoice Number: {receiptNo}</Text>
          <Text style={styles.text}>
            Date: {moment(info.createdAt).format("DD/MM/YYYY")}
          </Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Description</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Price </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Qty</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Amount</Text>
            </View>
          </View>
          {data.map((item, index) => (
            <View style={styles.tableRo} key={index}>
              <View style={styles.tableCo}>
                <Text style={([styles.tableCel], [{ textAlign: "left" }])}>
                  {item.description || item.item_name}
                </Text>
              </View>
              <View style={styles.tableCo}>
                <Text style={([styles.tableCel], [{ textAlign: "right" }])}>
                  {formatNumber(item.selling_price || item.cost)}
                </Text>
              </View>
              <View style={styles.tableCo}>
                <Text style={([styles.tableCel], [{ textAlign: "center" }])}>
                  {formatNumber(item.quantity)}
                </Text>
              </View>
              <View style={styles.tableCo}>
                <Text style={([styles.tableCel], [{ textAlign: "right" }])}>
                  {formatNumber(parseInt(item.selling_price * item.quantity))}
                </Text>
              </View>
            </View>
          ))}

          <View style={styles.tableRo}>
            {/* <View style={styles.tableCo}></View> */}
            <View style={([styles.tableCo], [{ marginTop: "5%" }])}>
              <View style={{ fontSize: "16", textAlign: "center" }}>
                <Text>Subtal: N{formatNumber(total)}</Text>
                <Text>Discount (0%): 0.00</Text>
                <Text>Grand Total: N{formatNumber(total)}</Text>
              </View>
            </View>
            {/* <View style={styles.tableC}></View> */}
          </View>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    // fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    // fontFamily: "Oswald",
  },
  subtitle1: {
    fontSize: 13,
    // fontFamily: "Oswald",
  },
  text: {
    margin: 2,
    fontSize: 17,
    textAlign: "justify",
    // fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 20,
    marginBottom: 10,
    textAlign: "center",
    color: "black",
  },
  circle: {
    backgroundColor: "grey",
    borderRadius: 100,
    height: 100,
    width: 100,
    left: 450,
  },
  table: {
    display: "table",
    width: "auto",
    // borderStyle: "solid",
    border: 1,
    // borderRightWidth: 0,
    // borderBottomWidth: 0,
    marginTop: 20,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    border: 1,
    backgroundColor: "green",
    color: "white",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 15,
    // border: 1,
  },

  // another one

  tableRo: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCo: {
    width: "25%",
    // borderStyle: "solid",
    // borderWidth: 1,
    // borderLeftWidth: 0,
    // borderTopWidth: 0
    // borderBottom: '1'
    border: 1,
  },
  tableC: {
    width: "25%",
    marginTop: "5%",
    // borderStyle: "solid",
    // borderWidth: 1,
    // borderLeftWidth: 0,
    // borderTopWidth: 0
    // border: 1,
  },
  tableCel: {
    margin: "auto",
    marginTop: 12,
    fontSize: 12,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
