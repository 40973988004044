// import { Button } from "bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Input, Label, Card, CardBody, Table } from "reactstrap";
import { _fetchApi, _postApi } from "../../redux/actions/api";
// import { getCustomers } from "../../redux/actions/customer";
import { getPurchasedItems } from "../../redux/actions/purchase";
// import { useState } from "react/cjs/react.production.min";
import CustomTypeahead from "../../components/CustomTypeahead";
import CustomScrollbar from "../../components/CustomScrollbar";
// import Button from "../../app/components/Button";
import CustomButton from "../../app/components/Button";
import { getStoresList } from "../../redux/actions/stores";
import { CASH, STORE } from "../../constants";
import CustomCard from "../../components/CustomCard";
import { _customNotify, _warningNotify } from "../../redux/helper";
import SearchBar from "../components/SearchBar";
import { formatNumber } from "../../app/utilities";

function Form() {
  let _form = {
    quantity: "",
    price: 0,
    qty: 0,
  };
  const dispatch = useDispatch();
  const [form, setForm] = useState(_form);
  const [data, setData] = useState([]);
  const itemList = useSelector((state) => state.purchase.purchaseList);
  const [usersList, setUsersList] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [selectedStock, setSelectedStock] = useState({
    quantity: 0,
    selling_price: 0,
  });
  const [agent, setAgent] = useState({});

  const [searchTerm, setSearchTerm] = useState("");
  const selected_store = "Show All Stores";
  const { activeBusiness, user } = useSelector((state) => state.auth);
  const { storeList } = useSelector((state) => state.stores);

  const [loading, setLoading] = useState(false);

  const handleAdd = () => {
    if (parseInt(selectedStock.quantity) < parseInt(form.quantity)) {
      _warningNotify("Stock exceeded limit in the store");
    } else {
      setData((p) => [...p, form]);
      setForm(_form);
    }
  };
  // const customers = customerList.length?customerList.map(c=>c.accName):[]
  const handleDelete = (index) => {
    let item = data.filter((i, idx) => index !== idx);
    setData(item);
    console.log(index);
  };

  const handleChange = ({ target: { name, value } }) => {
    if (name === "item_name") {
      setSelectedStock(itemList.filter((item) => item.item_name === value)[0]);
    } else {
      setForm((p) => ({ ...p, [name]: value }));
    }
  };

  const impChange = useCallback(() => {
    if (selectedStock) {
      setForm((p) => ({
        ...p,
        stock: selectedStock,
        storeTo: agent.branch_name,
        storeFrom: selectedStock.storeName,
        id: selectedStock._id,
        quantity: p.quantity,
        price: parseInt(selectedStock.selling_price),
        cost: selectedStock.cost,
        markup: selectedStock.markup,
        expiry_date: selectedStock.expiry_date,
        storeName: selectedStock.storeName,
        supplierName: selectedStock.supplierName,
        item_name: selectedStock.item_name,
        supplier_code: selectedStock.supplier_code,
        item_code: selectedStock.item_code,
      }));
    }
  }, [selectedStock, agent]);

  useEffect(() => {
    impChange();
  }, [impChange]);

  const getPurchaseList = useCallback(() => {
    // alert(activeStore)
    dispatch(getPurchasedItems(user.busName, () => console.log("Loading...")));
  }, [dispatch, user]);

  useEffect(() => {
    getPurchaseList(user.busName);
  }, [getPurchaseList, user]);

  const getStores = useCallback(() => {
    dispatch(
      getStoresList(
        () => {},
        () => {}
      )
    );
  }, [dispatch]);

  useEffect(() => {
    getStores();
  }, [getStores]);

  const getUsers = useCallback(() => {
    setLoading(true);
    _fetchApi(
      `/api/v1/get-users-by-facility/${activeBusiness.id}`,
      (data) => {
        setLoading(false);
        if (data.success) {
          setUsersList(data.results);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, [activeBusiness]);

  const handleSubmit = () => {
    setLoading(true);
    const arr = [];
    data.forEach((element) => {
      console.log({ element });
      arr.push({
        ...element,
        agent,
      });
    });
    if (data.length < 1) {
      _warningNotify("Form is empty");
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } else {
      console.log({ data });
      _postApi(
        "/account/good/transfer",
        { data: arr },
        (res) => {
          if (res.status) {
            _customNotify("Successfully Submit");
            setLoading(false);
            setData([]);
          }
        },
        (err) => {
          _warningNotify("error occured");
          console.log(err);
          setLoading(false);
        }
      );
    }
  };
  const handleSearchTermChange = (value) => {
    // console.log(value);
    setSearchTerm(value);
  };

  const handleSearch = useCallback(() => {
    setFiltered(
      searchTerm.length > 2 && data.length
        ? data.filter((item) => {
            return item.item_name
              ?.toLowerCase()
              ?.includes(searchTerm.toLowerCase());
          })
        : data
    );
  }, [data, searchTerm]);

  useEffect(() => {
    getUsers();
    handleSearch();
  }, [getUsers, handleSearch]);

  return (
    <CustomCard className="shadow">
      <Row>
        {/* {JSON.stringify(storeList)} */}
        <CardBody>
          {/* <Container>
                <Row>
                  <Col md="6">
                    <Label>
                      <Input type="radio" name="gender" checked />
                      Recived
                    </Label>
                  </Col>
                  <Col md="6">
                    <Label>
                      <Input type="radio" name="gender" checked />
                      Returned
                    </Label>
                  </Col>
                </Row>
                  </Container> */}
          <Row>
            <Col md="6">
              <Label>Select Agent's store</Label>
              {/* <Input
                      type="select"
                      name="agent"
                      value={form.agent}
                      onChange={handleChange}
                    >{usersList.map((itm,i)=><option key={i}>{itm.username}</option>)}
                    </Input> */}
              <CustomTypeahead
                allowNew={true}
                placeholder="Search agent's POS"
                labelKey="branch_name"
                clearButton
                options={storeList.filter(
                  (st) => st.branch_name !== user.busName
                )}
                onInputChange={(v) => {
                  if (v.length) {
                    console.log(v, "KDDDDDDDK");
                  }
                }}
                onChange={(v) => {
                  if (v.length) {
                    setAgent(v[0]);
                    console.log(v[0], "KDDDDDDDK");
                  }
                }}
              />
            </Col>

            <Col md="6">
              <Label>Select Stock</Label>
              {/* <Input
                      type="select"
                      name="stock"
                      value={form.stock}
                      required="required"
                      onChange={handleChange}
                    ><option value=''>Select stock</option>
                      {itemList.map((itm,i)=><option key={i}>{itm.item_name}</option>)}
                    </Input> */}
              <CustomTypeahead
                allowNew={true}
                placeholder="Search stock by name"
                labelKey="item_name"
                clearButton
                options={itemList}
                onInputChange={(v) => {
                  console.error({ v });
                  if (v.length) {
                    handleChange({ target: { name: "item_name", value: v } });
                  }
                }}
                onChange={(v) => {
                  console.error({ v });
                  if (v.length) {
                    handleChange({
                      target: { name: "item_name", value: v[0].item_name },
                    });
                  }
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md="6" className="mt-4">
              <Label>Quntity in store: {selectedStock.quantity}.</Label>
            </Col>
            <Col md="6" className="mt-4">
              <Label>Price: {formatNumber(form.price)}</Label>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Input
                type="number"
                name="quantity"
                value={form.quantity}
                onChange={handleChange}
              />
            </Col>

            {/* <Col md="6">
                    <Label>Quntity Returned</Label>
                    <Input
                      type="number"
                      name="qtyReturned"
                      value={form.qtyReturned}
                      onChange={handleChange}
                    />
                  </Col> */}
            <Col md="6">
              <CustomButton size="sm" className="btn-block" onClick={handleAdd}>
                Add
              </CustomButton>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <SearchBar
                filterText={searchTerm}
                onFilterTextChange={handleSearchTermChange}
                placeholder="Search users by name"
              />
            </Col>
          </Row>
          <Row>
            {/* <Col md="12">
              <Input type="search" placeholder="search...." className="mt-3" />
            </Col> */}
            <Col md={12}>
              <Table className="mt-3 table table-bordered">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Product</th>
                    <th>Model</th>
                    <th>Recived</th>
                    <th>Stock</th>
                    <th>Price</th>
                    <th>Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filtered &&
                    filtered.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.item_name}</td>
                        <td></td>
                        <td>{item.quantity}</td>
                        <td className="text-center">
                          {parseInt(item.stock.quantity) -
                            parseInt(item.quantity)}
                        </td>
                        <td className="text-right">
                          {formatNumber(item.price)}
                        </td>
                        <td className="text-right">
                          {formatNumber(
                            parseInt(item.quantity) * parseFloat(item.price)
                          )}
                        </td>
                        <td>
                          <CustomButton
                            color="success "
                            size="sm"
                            onClick={() => handleDelete(index)}
                          >
                            Delete
                          </CustomButton>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <CustomButton
                color="success "
                size="lg"
                onClick={handleSubmit}
                loading={loading}
              >
                Submit
              </CustomButton>
            </Col>
          </Row>
        </CardBody>
      </Row>
    </CustomCard>
  );
}
export default Form;
