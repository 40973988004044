import React, { useCallback, useEffect, useState } from 'react'
import {
Table,
Button,
// FormGroup,
} from 'reactstrap'
import { MdDelete, MdRateReview, MdEdit } from 'react-icons/md'
// import { useHistory } from "react-router-dom";
// import { Scrollbars } from 'react-custom-scrollbars'
import { _fetchApi, _deleteApi, apiURL } from '../../redux/actions/api'
import { _customNotify } from '../../redux/helper'
// import SearchBar from "../../SearchBar";
import { useSelector } from 'react-redux'
import SearchBar from '../../inventria/components/SearchBar'
// import CustomCard from '../../inventria/components/CustomCard'
import { useHistory } from 'react-router'
import CustomButton from '../components/Button'
import { FaPlus } from 'react-icons/fa'
import CustomCard from '../../components/CustomCard'
import useQuery from "../../inventria/hooks/useQuery"; 
import User from './User'

export default function Users() {
const activeBusiness = useSelector((state) => state.auth.activeBusiness)
// eslint-disable-next-line no-unused-vars
const [, setLoading] = useState(false)
const [searchTerm, setSearchTerm] = useState('')
const [usersList, setUsersList] = useState([])

const history = useHistory()
const query = useQuery()
const userId = parseInt(query.get('user'))

const getUsers = useCallback(() => {
  setLoading(true)
  _fetchApi(
    `/api/v1/get-users-by-facility/${activeBusiness.id}`,
    (data) => {
      setLoading(false)
      if (data.success) {
        setUsersList(data.results)
      }
    },
    (err) => {
      setLoading(false)
      console.log(err)
    },
  )
}, [activeBusiness.id])

useEffect(() => getUsers(), [getUsers])

const handleSearchTermChange = (value) => {
  // console.log(value);
  setSearchTerm(value)
}

// const handleActionSelectChange = async (user, value) => {
// switch (value) {
//   case "approve": {
//     // toggle(true);
//     let userId = user.id;
//     let response = await fetch(`${apiURL()}/users/approve/${userId}`, {
//       method: "PUT",
//       headers: { "Content-Type": "aplication/json" },
//     });
//     let data = await response.json();
//     if (data) {
//       console.log(data);
//       _customNotify("Data updated!");
//       this.fetchData();
//     }
//     // toggle(false);
//     break;
//   }
//   case "suspend": {
//     // toggle(true);
//     let userId = user.id;
//     let response = await fetch(`${apiURL()}/users/suspend/${userId}`, {
//       method: "PUT",
//       headers: { "Content-Type": "aplication/json" },
//     });
//     let data = await response.json();
//     if (data) {
//       console.log(data);
//       _customNotify("Data updated!");
//       this.fetchData();
//     }
//     // toggle(false);
//     break;
//   }
//   default:
//     return null;
// }
// }

const handleDelete = (userId) => {
  //  console.log(id, facilityId)
  _deleteApi(
    `/users/delete/${userId}/${activeBusiness.id}`,
    {},
    (data) => {
      // if(data.success){
      _customNotify('Deleted successfully')
      getUsers()
      // }
    },
    (err) => {
      console.log(err)
    },
  )
}

// render() {
//   const { users, searchTerm } = this.state;

const rows = []
if (usersList.length) {
  usersList.forEach((user, i) => {
    if (
      // user.fullname.toLowerCase().indexOf(searchTerm.toLowerCase()) ===
      //   -1 &&
      user.username.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1
    )
      return

    rows.push(
      <TableRow
        index={i + 1}
        user={user}
        fullname={user.fullname}
        username={user.username}
        phone={user.phone}
        role={user.role}
        id={user.id}
        // facilityId={user.facilityId}
        // handleActionSelectChange={this.handleActionSelectChange}
        handleDelete={handleDelete}
        key={i}
      />,
    )
  })
}

return  userId>0?(<User user={usersList.filter(user=>user.id===userId)[0]}/>): (
  <CustomCard header="Manage your users">
    <CustomButton
      onClick={() => history.push('/app/admin/manage-users/new-user?type=new_user')}
    >
      <FaPlus className="mr-2" />
      Create New User
    </CustomButton>
    {/* {JSON.stringify(usersList)} */}
    <SearchBar
      filterText={searchTerm}
      onFilterTextChange={handleSearchTermChange}
      placeholder="Search users by name"
    />

    <div style={{ maxHeight: '70vh' }}>
      <Table size="sm" bordered hover striped>
        <thead>
          <tr>
            <th>S/N</th>
            <th className="text-center">Name</th>
            <th className="text-center">Phone No.</th>
            <th className="text-center">Role</th>
            <th className="text-center" colSpan={2}>Action</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  </CustomCard>
)
// }
}

const TableRow = ({
// user,
username,
role,
// handleActionSelectChange,
handleDelete,
id,
index,
phone,
}) => {
const history = useHistory()
// const match = useRouteMatch();
// const ids = match.params.id;

const handleClick = () => {
  history.push(`/app/admin/staffreview/${id}`)
}
return (
  <>
    {/* {JSON.stringify(ids)} */}
    <tr>
      <td>{index}</td>
      <td>{username}</td>
      <td>{phone}</td>
      <td>{role}</td>
      {/* <td>{user.status}</td> */}
      {/* <td>
        <FormGroup>
          <select
            className="mt-2 form-control"
            onChange={(e) => handleActionSelectChange(user, e.target.value)}
          >
            <option value="">Select Action</option>
            <option value="approve">Approve</option>
            <option value="suspend">Suspend</option>
          </select>
        </FormGroup>
      </td> */}
      <td className="text-center">
      {/* <Button
        color="success"
        size="sm"
        onClick={() => handleClick()}
        className="mr-1"
      >
        <MdRateReview
          size={20}
          fontWeight="bold"
          // style={{ marginRight: 10 }}
        />
        Edit
      </Button> */}
      <Button color="success" size="sm" onClick={() => history.push(`/app/admin/manage-users?user=${id}`)}>
        <MdEdit size={20} fontWeight="bold" />
        Edit
      </Button>
    </td>
      <td className="text-center">
        {/* <Button
          color="success"
          size="sm"
          onClick={() => handleClick()}
          className="mr-1"
        >
          <MdRateReview
            size={20}
            fontWeight="bold"
            // style={{ marginRight: 10 }}
          />
          Edit
        </Button> */}
        <Button color="danger" size="sm" onClick={() => handleDelete(id)}>
          <MdDelete size={20} fontWeight="bold" />
          Delete
        </Button>
      </td>
    </tr>
  </>
)
}

TableRow.defaultProps = {
users: [],
}
