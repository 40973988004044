import React, { useEffect, useState } from "react";
import CheckBoxItem from "../../components/checkbox";
import { FormGroup, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getStoresList } from "../../redux/actions/stores";
import CustomTypeahead from "../../components/CustomTypeahead";
import { Typeahead } from "react-bootstrap-typeahead";
import { BsEyeSlashFill } from "react-icons/bs";
import { BiShow } from "react-icons/bi";
import CustomButton from "../components/Button";
function NewUserForm({
  handleChange,
  handleCheckboxChange,
  username,
  handleTypeaheadChange,
  form,
  phone,
  password,
  role,
  accessTo,
  multiSelections,
  setMultiSelections,
}) {
  const dispatch = useDispatch();
  const options = useSelector((state) => state.stores.storeList);
  useEffect(() => {
    dispatch(getStoresList());
    // dispatch(getStoresList());
  }, [dispatch]);
  const [showPass, setShowPass] = useState(false);
  const showPassword = () => {
    setShowPass((p) => !p);
  };
  return (
    <>
      {/* {JSON.stringify({multiSelections,form})} */}
      {/* <FormGroup row>
        <div className="col-md-6 col-lg-6">
          <label>Full Name</label>
          <input
            className="form-control"
            type="text"
            name="fullname"
            autoFocus
            value={fullname}
            onChange={handleChange}
          />
        </div>
      </FormGroup> */}
      {/* {JSON.stringify(form)} */}
      <FormGroup row>
        <div className="col-md-4 col-lg-4">
          <label>User Name</label>
          <input
            className="form-control"
            type="text"
            name="username"
            value={username}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4 col-lg-4">
          <label>Phone No.</label>
          <input
            className="form-control"
            type="phone"
            name="phone"
            value={phone}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4 col-lg-4">
          <label>Password</label>
          <div class="input-group">
            <input
              type={!showPass ? "password" : "text"}
              name="password"
              value={password}
              onChange={handleChange}
              className="form-control "
            />
            <div class="input-group-append">
              <CustomButton
                class="btn btn-success size-sm"
                onClick={showPassword}
              >
                {showPass ? <BiShow /> : <BsEyeSlashFill />}
              </CustomButton>
            </div>
          </div>
        </div>
      </FormGroup>

      <FormGroup row>
        <div className="col-md-4 col-lg-4">
          <label>Role</label>
          <input
            onChange={handleChange}
            name="role"
            type="text"
            list="role"
            autoComplete="disabled"
            value={role}
            className="form-control"
            // placeholder="Role"
          />
          <datalist id="role">
            <option value="Store Owner" />
            <option value="Receptionist" />
            <option value="Sales Agent" />
            <option value="Store Keeper" />
            {/*  <option value="Lab Technologist" /> */}
          </datalist>
        </div>
        <div className="col-md-4 col-lg-4">
          <CustomTypeahead
            label="Default store"
            options={options}
            // defaultSelected={["Main Store"]}
            labelKey="storeName"
            onChange={(val) => {
              if (val) {
                handleTypeaheadChange(val[0]);
              }
            }}
            onInputChange={(val) => {
              console.log(val);
            }}
          />
        </div>
        <div className="col-md-4 col-lg-4">
          <Label>Assign Store</Label>
          <Typeahead
            id="basic-typeahead-multiple"
            labelKey="branch_name"
            multiple
            onChange={setMultiSelections}
            options={options}
            placeholder="Choose several store..."
            selected={multiSelections}
          />
        </div>
      </FormGroup>

      <FormGroup>
        {/* <div className="col-md-6 col-lg-6"> */}
        <label>Access (User's Privilege)</label>
        <div className="row">
          {/* <div className=""> */}
          {[
            "Purchase",
            "Reports",
            "Customers",
            "Sales",
            "Settings",
            "Admin",
            "Dashboard",
          ].map((item, i) => (
            <div className="col-md-2" key={i}>
              <CheckBoxItem
                label={item}
                name="accessTo"
                handleCheck={handleCheckboxChange}
                value={accessTo}
              />
            </div>
          ))}
          {/* </div> */}
        </div>
        {/* </div> */}
      </FormGroup>
    </>
  );
}

export default NewUserForm;
