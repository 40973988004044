import React from "react";
import MakeSale from "./MakeSale";
import ReturnItems from "./ReturnItems";
import SoldItems from "./Soldtems";
import TabWrapper from "../components/tab-wrapper/TabWrapper";
// import PendingSales from './pendingSales'
// import { useParams } from "react-router";
import useQuery from "../hooks/useQuery";

function Sales() {
  const query = useQuery();
  const salesTab = query.get("tab");
  const arr = ["POS transfer", "Sold Items", "Return Items"];
  const navigate = () =>{
    switch (salesTab){
      case arr[0]:
        return <MakeSale />;
      case arr[1]:
        return <SoldItems />;
      case arr[2]:
        return <ReturnItems />;
      default:
        return <MakeSale />;
    }
  };
  return (
    <>
      {arr.includes(salesTab) ? (
        <div>
          <TabWrapper steps={arr} route={`/app/custom-sales`} step={salesTab}>
            {navigate()}
          </TabWrapper>
        </div>
      ) : (
        <TabWrapper steps={arr} route={`/app/custom-sales`} step={arr[0]}>
          {navigate()}
        </TabWrapper>
      )}
    </>
  );
}

export default Sales;
