import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row, Table } from "reactstrap";
import { useHistory } from "react-router";
import { FaPlus } from "react-icons/fa";
// import { getPurchasedItems } from "../../redux/actions/purchase";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../app/components/Button";
import SearchBar from "../../app/components/SearchBar";
import Loading from "../../app/components/Loading";
import ViewItem from "./ViewItem";
import CustomCard from "../../components/CustomCard";
import { Checkbox } from "evergreen-ui";
// import moment, { isMoment } from "moment";
import OtherOptions from "./purchase-table/OtherOptions";
import CustomScrollbar from "../../components/CustomScrollbar";
import { formatNumber } from "../../app/utilities";
// import { _fetchApi } from "../../redux/actions/api";
// import store from "../../redux/store";
import { getPurchasedItems } from "../../redux/actions/purchase";
import { _customNotify, _warningNotify } from "../../redux/helper";
import { _postApi } from "../../redux/actions/api";

export default function PurchaseTable({ type = "" }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [preview, setPreview] = useState(false);
  const [item_name, setItem_name] = useState("");
  const [selected, setSelected] = useState({});
  const [showAllPurchase, setShowAllPurchase] = useState(false);
  const data = useSelector((state) => state.purchase.purchaseList);
  const [activeStore, setActiveStore] = useState(null);
  // const [data, setData] = useState([]);
  // const activeBusiness = store.getState().auth.activeBusiness;
  const history = useHistory();
  const items = data.length > 0 && showAllPurchase ? data : data.slice(-15);
  const [isOpen, setIsOpen] = useState(false)
  const [toggle, setToggle] = useState(false)
  const toggleModal = useCallback(() => {
    setToggle(!toggle);
    setIsOpen(!isOpen)
  }, [setIsOpen, setToggle, isOpen, toggle])

  useEffect(() => {
    setLoading(true)
    dispatch(getPurchasedItems());
    setLoading(false)
  }, [dispatch, activeStore]);



  // console.error({ data, items });
  const handleDelete = (item) => {
    if(window.confirm(`Are you sure you want to delete  "${item.item_name}" stock?`)===true){
    setLoading(true);
    _postApi(`/account/delete-stock`,
      { ...item, query_type: 'delete' },
      (res) => {
        setLoading(false);
        dispatch(getPurchasedItems());
        _customNotify("Deleted");
        toggleModal();
      },
      (err) => {
        setLoading(false);
        _warningNotify("Error: " + err.toString());
        toggleModal();
      }
    );
    }
  }

  const list =
    searchText.length > 2 && items.length
      ? items.filter((item) => {
        return item.item_name
          ?.toLowerCase()
          ?.includes(
            searchText.toLowerCase() ||
            item.supplierName.toLowerCase().includes(searchText)
          );
      })
      : items;

  const pageTitle = preview ? "Item Details" : "Stock Balances";

  const total_saling_price = items.reduce(
    (a, b) =>
      parseFloat(a) + parseFloat(b.selling_price) * parseFloat(b.quantity),
    0
  );

  return (
    <CustomCard
      header={
        preview ? (
          <div className="row m-0">
            <div className="col-md-3">
              <CustomButton
                onClick={() => setPreview(false)}
              >{`< Back`}</CustomButton>
            </div>
            <div className="col-md-6 text-center">
              <h5>Stock Purchase</h5>
            </div>
          </div>
        ) : (
          <h5 className="text-center">{pageTitle}</h5>
        )
      }
    >
      {!preview ? (
        <>
          {type === "display" ? null : (
            <CustomButton
              className="mb-1"
              onClick={() =>
                history.push(
                  `/app/purchase/purchase-list/new?store=${activeStore}`
                )
              }
            >
              <FaPlus />
              Add New Stock
            </CustomButton>
          )}
          {loading ? <Loading /> : false}

          <OtherOptions
            activeStore={activeStore}
            total_selling_price={total_saling_price}
            items={items}
            setActiveStore={setActiveStore}
          />
          <Row>
            <Col md={10}>
              <SearchBar
                placeholder="Search by supplier name"
                onFilterTextChange={(val) => setSearchText(val)}
                filterText={searchText}
              />
            </Col>
            <Col className="d-flex flex-direction-row align-items-center">
              <Checkbox
                label="Show All Items"
                checked={showAllPurchase}
                onChange={() => setShowAllPurchase((p) => !p)}
              />
            </Col>
          </Row>
          <Row>
            <div
              style={{
                marginLeft: "auto",
                marginRight: 0,
                paddingRight: "20px",
              }}
            ></div>
          </Row>
          <CustomScrollbar height="68vh">
            {/* {JSON.stringify(list)} */}
            <Table bordered style={{ padding: 0 }}>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Item name </th>
                  <th>Supplier</th>
                  <th>Qty Available</th>
                  <th>Selling price (₦)</th>
                  <th>Amount (₦)</th>
                  <th>Expiry date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, i) => (
                  <tr key={i}>
                    <th scope="row">{i + 1}</th>
                    <td>{item.item_name}</td>
                    <td>{item.supplierName}</td>
                    <td className="text-center">{item.quantity}</td>
                    <td className="text-right">
                      {item.selling_price}
                      {/* {formatNumber(parseInt(item.cost) + parseInt(item.markup))} */}
                    </td>
                    <td className="text-right">
                      {formatNumber(
                        parseInt(item.selling_price) * parseInt(item.quantity)
                      )}
                    </td>
                    <td className="text-center">
                      {item.expiry_date === "" ? "-" : item.expiry_date}
                    </td>
                    <td>
                      <CustomButton
                        size="sm"
                        color="primary"
                        onClick={() => {
                          setPreview(true);
                          setSelected(item);
                          setItem_name(item.item_name);
                          history.push(
                            `/app/purchase/purchase-list?supplier_code=${item.supplier_code}&item_name=${item.item_name}&store=${item.storeName}`
                          );
                        }}
                      >
                        View
                      </CustomButton>
                    </td>
                    <td>
                      <Button
                        size="sm"
                        color="danger" onClick={() => handleDelete(item)}>
                        Delete
                      </Button>
                     
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <center>
              {!list.length && (
                <h4 className="text-secondary">
                  No data please come back later
                </h4>
              )}
            </center>
          </CustomScrollbar>
        </>
      ) : (
        <>
          {/* {JSON.stringify(selected)} */}
          <ViewItem
            info={selected}
            setPreview={setPreview}
            item_name={item_name}
          />
        </>
      )}
    </CustomCard>
  );
}
