import React from "react";
import TabWrapper from "../../components/tab-wrapper/TabWrapper";
import CreatePurchase from "../../purchase/CreatePurchase";

export default function PageSettingIndex() {
  return (
    <div>
      <TabWrapper
        steps={[
          "PURCHASE SETUP",
          "SERVICE SETUP",
          "POS SETUP",
          "DASHBORD SETUP",
        ]}
      >
        <CreatePurchase />
        <h1>Commmmm</h1>
        <h1>jjjjjjj</h1>
      </TabWrapper>
    </div>
  );
}
