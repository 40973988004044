import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Label, Row, Table } from "reactstrap";
import SearchBar from "../components/SearchBar";
import { FaCoins, FaPlus } from "react-icons/fa";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getCustomers } from "../../redux/actions/customer";
import Loading from "../components/Loading";
import { formatNumber } from "../utilities";
import CustomButton from "../components/Button";
import CustomCard from "../../components/CustomCard";
import { Checkbox } from "evergreen-ui";
import { FiPrinter } from "react-icons/fi";
import PrintWrapper from "../../inventria/components/PrintWrapper";
import ActiveStoresList from "../../inventria/purchase/ActiveStoresList";
// import CustomCard from '../../inventria/components/CustomCard'

export default function CustomerTable() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showAllPurchase, setShowAllPurchase] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);
  const customers = useSelector((state) => state.customer.customerList);
  const customerList = showAllPurchase ? customers : customers.slice(-15);
  const [activeStore, setActiveStore] = useState(null);

  const rows =
    filterText.length && customerList.length
      ? customerList.filter(
          (item) =>
            item.accName &&
            item.accName.toLowerCase().includes(filterText.toLowerCase())
        )
      : customerList;

  const getList = useCallback(() => {
    setLoading(true);
    dispatch(
      getCustomers(
        activeStore,
        () => setLoading(false),
        () => setLoading(false)
      )
    );
    setLoading(false);
  }, [activeStore, dispatch]);

  useEffect(() => {
    getList();
  }, [getList]);

  const printReportPDF = () => {
    window.frames["print_frame"].document.body.innerHTML =
      document.getElementById("reportsPDF").innerHTML;
    window.frames["print_frame"].window.focus();
    window.frames["print_frame"].window.print();
  };

  return (
    <CustomCard header={"Customers Table"}>
      <CustomButton
        color="primary"
        className="mb-3 mr-3"
        onClick={() => history.push("/app/customer/customer-registration")}
      >
        <FaPlus className="mr-1" />
        Add New Customer
      </CustomButton>
      <CustomButton
        color="primary"
        className="mb-3 mr-3"
        onClick={() => history.push("/app/customer/customer-deposit")}
      >
        <FaCoins className="mr-1" />
        Customer Deposit
      </CustomButton>
      <Row>
        <Col md="10">
          <SearchBar
            placeholder="Search for a customer by name or address"
            onFilterTextChange={(val) => setFilterText(val)}
            filterText={filterText}
          />
        </Col>
        <Col className="d-flex flex-direction-row align-items-center">
          <Checkbox
            label="Show All Items"
            checked={showAllPurchase}
            onChange={() => setShowAllPurchase((p) => !p)}
          />
        </Col>
      </Row>
      <div className="d-flex justify-content-between">
        <div>
          <Label>Select Store Name</Label>
          <ActiveStoresList
            activeStore={activeStore}
            setActiveStore={setActiveStore}
          />
        </div>
        <div className="text-right">
          <CustomButton onClick={printReportPDF}>
            <FiPrinter className="mr-2" />
            Print Now
          </CustomButton>
        </div>
      </div>
      <iframe
        title="print_report"
        name="print_frame"
        width="0"
        height="0"
        src="about:blank"
        // style={styles}
      />
      {loading && <Loading />}
      <div style={{ height: "60vh", overflow: "scroll" }}>
        <div id="reportsPDF">
          <PrintWrapper title="Customer Report">
            <Table bordered size="sm">
              <thead>
                <tr>
                  <th className="text-center">S/N</th>
                  <th className="text-center" style={{ width: "20%" }}>
                    Name
                  </th>
                  <th className="text-center" style={{ width: "25%" }}>
                    Address
                  </th>
                  <th className="text-center" style={{ width: "25%" }}>
                    Email
                  </th>
                  <th className="text-center" style={{ width: "15%" }}>
                    Phone
                  </th>
                  <th className="text-center" style={{ width: "15%" }}>
                    Balance
                  </th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item.accName}</td>
                    <td>{item.contactAddress}</td>
                    <td>{item.contactEmail}</td>
                    <td>{item.contactPhone}</td>
                    <td className="text-right">{formatNumber(item.balance)}</td>
                    <td>
                      <CustomButton
                        size="sm"
                        onClick={() =>
                          history.push(
                            `/app/customer/customer-view?id=${item.accountNo}&balance=${item.balance}`
                          )
                        }
                      >
                        View
                      </CustomButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </PrintWrapper>
        </div>
      </div>
    </CustomCard>
  );
}
