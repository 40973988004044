import store from "../store";

let localEndpoint = "http://localhost:42790";
let remoteEndpoint = "https://galaxybis.ebudgetkano.ng/inventria-backend";
export const ipAddr = "127.0.0.1";
export const apiURL =
  process.env.NODE_ENV === "production" ? remoteEndpoint : localEndpoint;

const token = localStorage.getItem("@@__token");

const _postApi = (url, data = {}, success = (f) => f, error = (f) => f) => {
  const facilityId = store.getState().auth.activeBusiness.id;

  data.facilityId = facilityId;
  // console.log(data)
  fetch(`${apiURL}${url}`, {
    method: "POST",
    headers: { "Content-Type": "application/json", authorization: token },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((response) => {
      console.log(response);
      if (response.status >= 400) {
        error(response);
      } else success(response);
    })
    .catch((err) => error(err));
};

const _fetchApi = (
  url,
  success = (f) => f,
  error = (f) => f,
  empty = (f) => f
) => {
  // const { facilityId } = store.getState().auth.user;
  // let actualURL = `${url}/${facilityId}`;
  // console.log(apiURL)
  fetch(`${apiURL}${url}`, {
    method: "GET",
    headers: { "Content-Type": "application/json", authorization: token },
  })
    .then((raw) => raw.json())
    .then((response) => {
      if (response) {
        success(response);
      } else {
        console.log("Empty response");
        empty();
      }
    })
    .catch((err) => {
      error(err);
    });
};

const _deleteApi = (
  route,
  data = {},
  callback = (f) => f,
  err_cb = (f) => f
) => {
  // const { facilityId, username } = store.getState().auth.user;
  // data.facilityId = facilityId;
  // data.userId = username;

  fetch(`${apiURL}${route}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: data ? JSON.stringify(data) : null,
  })
    .then(function (response) {
      // if the status of the response is greater than 400, then error is returned
      if (response.status >= 400) {
        if (err_cb) err_cb();
      }
      if (callback) callback();
    })
    .catch(function (err) {
      return err_cb(err);
    });
};
export { _postApi, _fetchApi, _deleteApi };

// // export const apiURL = `http://${ipAddr}:42426`
// // export const apiURL = 'http://192.168.0.108:42426'
// // export const apiURL = 'http://192.168.43.253:42426'
// // export const apiURL = 'http://192.168.2.108:48485/api/v1';
// // export const apiURL = 'https://pscprime.com/test/inventria-server'
// // export const apiURL = 'http://127.0.0.1:42426';
// // export const apiURL = 'http://127.0.0.1:42423/api'
// // let localEndpoint = "http://localhost:42426";
// // let remoteEndpoint = "https://yge.wvi.mybluehost.me/test/inventria-server";
// // export const apiURL = process.env.NODE_ENV=== 'production' ? remoteEndpoint : localEndpoint
// export const apiURL = "https://yge.wvi.mybluehost.me/test/inventria-server";

// /**
//  * _fetchApi()
//  * helper function that fetches data from the database using a
//  * specified route and performs the callback function on the returned data
//  * @params url (string) => the api route
//  * @params success (func) => the action to perform on that data successful return
//  * @params error (func) => the action to perform on failure event
//  * @params empty (func) => the action to perform if respose is empty
//  *      that is being returned
//  */
// const token = localStorage.getItem("@@__token");
// const _fetchApi = (
//   url,
//   success = (f) => f,
//   error = (f) => f,
//   empty = (f) => f,
// ) => {
//   //   const { facilityId } = store.getState().auth.user;
//   //   let actualURL = `${url}/${facilityId}`
//   fetch(url)
//     .then((raw) => raw.json())
//     .then((response) => {
//       console.log(url);
//       if (response) {
//         success(response);
//       } else {
//         console.log("Empty response");
//         empty();
//       }
//     })
//     .catch((err) => {
//       console.log(url);
//       error(err);
//     });
// };

// /**
//  * _postApi()
//  * An helper function that posts data to the database
//  * @params route (string) => the api route to submit on
//  * @params data (object) => item to be submitted
//  * @params callback => optional callback function
//  */
// const _postApi = (url, data = {}, success = (f) => f, error = (f) => f) => {
//   //   const { facilityId } = store.getState().auth.user;
//   //   data.facilityId = facilityId;
//   const created_by = "test_user";
//   const obj = { ...data, created_by };
//   // console.log('here', obj);

//   fetch(url, {
//     method: "POST",
//     headers: {
//       headers: { "Content-Type": "application/json", authorization: token },
//     },
//     body: JSON.stringify(obj),
//   })
//     .then((resp) => resp.json())
//     .then((response) => {
//       console.log(response);
//       success(response);
//     })
//     .catch((err) => {
//       console.log(url);
//       error(err);
//     });
// };

// /**
//  * _deleteData()
//  * An helper function that deletes data from the database
//  * @params route (String) => the api route
//  * @params data (object) => object containing the details of
//  * the item to be deleted
//  * @params callback (func) => optional callback
//  */
// const _deleteApi = (
//   route,
//   data = {},
//   callback = (f) => f,
//   err_cb = (f) => f,
// ) => {
//   //   const { facilityId } = store.getState().auth.user;
//   //   data.facilityId = facilityId

//   fetch(route, {
//     method: "DELETE",
//     headers: { "Content-Type": "application/json" },
//     body: data ? JSON.stringify(data) : null,
//   })
//     .then(function (response) {
//       // if the status of the response is greater than 400, then error is returned
//       if (response.status >= 400) {
//         if (err_cb) {
//           err_cb();
//         }
//       }
//       if (callback) {
//         callback();
//       }
//     })
//     .catch(function (err) {
//       return err_cb(err);
//     });
// };

// const _updateApi = (url, data, success, error) => {
//   //   const { facilityId } = store.getState().auth.user;
//   //   data.facilityId = facilityId
//   fetch(`${url}`, {
//     method: "PUT",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   })
//     .then(function (response) {
//       if (response.status >= 400) {
//         error(response);
//       } else {
//         success();
//       }
//     })
//     .catch((err) => error(err));
// };

// export { _fetchApi, _postApi, _deleteApi, _updateApi };
