import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  Button,
  FormGroup,
  Form,
  Col,
  Row,
  Label,
  CardBody,
  Input,
} from "reactstrap";
import { MdDelete, MdRateReview, MdEdit, MdCheck } from "react-icons/md";
// import { useHistory } from "react-router-dom";
// import { Scrollbars } from 'react-custom-scrollbars'
import {
  _fetchApi,
  _deleteApi,
  apiURL,
  _postApi,
} from "../../redux/actions/api";
import { _customNotify } from "../../redux/helper";
// import SearchBar from "../../SearchBar";
import { useSelector, useDispatch } from "react-redux";
import SearchBar from "../../inventria/components/SearchBar";
// import CustomCard from '../../inventria/components/CustomCard'
import { useHistory } from "react-router";
import CustomButton from "../components/Button";
import { FaPlus } from "react-icons/fa";
import CustomCard from "../../components/CustomCard";
import useQuery from "../../inventria/hooks/useQuery";
import { Checkbox } from "evergreen-ui";
import CustomTypeahead from "../../components/CustomTypeahead";
import { Typeahead } from "react-bootstrap-typeahead";
import { BiShow } from "react-icons/bi";
import { BsEyeSlashFill } from "react-icons/bs";
import { getStoresList } from "../../redux/actions/stores";

export default function User({ user }) {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [multiSelections, setMultiSelections] = useState([]);
  const activeBusiness = useSelector((state) => state.auth.activeBusiness);
  const access = user && user.accessTo && user.accessTo.split(",");
  const [form, setForm] = useState({
    roles: [
      "Admin",
      "Customers",
      "Inventory",
      "Administration",
      "Management",
      "Supplier",
      "Purchases",
      "Make Sales",
      "Expenses",
      "Transfer",
      "Pending Sales",
      "Reports",
      "Settings",
      "Custom Sales",
    ],
    checked: access?.length ? access : roles,
    ...user,
    password: "",
    query_type: "update",
  });

  const options = useSelector((state) => state.stores.storeList);
  const [showPass, setShowPass] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const showPassword = () => {
    setShowPass((p) => !p);
  };
  const handleChange = ({ target: { name, value } }) => {
    setForm({
      ...form,
      error: "",
      [name]: value,
    });
  };

  const handleTypeaheadChange = (val) => {
    if (val) {
      setForm((p) => ({
        ...p,
        branch_name: val.storeName,
      }));
    }
  };
  const syncRoles = useCallback(() => {
    _fetchApi(
      `/api/v1/get-users-by-facility/${activeBusiness.id}`,
      (data) => {
        setLoading(false);
        if (data.success) {
          // setUsersList(data.results)
        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, [activeBusiness.id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    form.accessTo = form.checked.toString();
    _postApi(
      `/api/auth/sign-up`,
      form,
      (resp) => {
        if (resp.success) {
          setLoading(false);
          history.push("/app/admin/manage-users");
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  useEffect(() => {
    dispatch(getStoresList());
    syncRoles();
  }, [dispatch, syncRoles]);
  return (
    <CustomCard back header="Manage your users">
      {/* {JSON.stringify(form)} */}
      <Col>
        <CustomButton
          onClick={() =>
            history.push("/app/admin/manage-users/new-user?type=new_user")
          }
        >
          <FaPlus className="mr-2" />
          Create New User
        </CustomButton>
        <br />
      </Col>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup row>
            <div className="col-md-4 col-lg-4">
              <label>User Name</label>
              <input
                className="form-control"
                type="text"
                name="username"
                value={form.username}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-4 col-lg-4">
              <label>Phone No.</label>
              <input
                className="form-control"
                type="phone"
                name="phone"
                value={form.phone}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-4 col-lg-4">
              <label>Password</label>
              <div class="input-group">
                <input
                  type={!showPass ? "password" : "text"}
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  className="form-control "
                />
                <div class="input-group-append">
                  <CustomButton
                    class="btn btn-success size-sm"
                    onClick={showPassword}
                  >
                    {showPass ? <BiShow /> : <BsEyeSlashFill />}
                  </CustomButton>
                </div>
              </div>
            </div>
          </FormGroup>

          <FormGroup row>
            <div className="col-md-4 col-lg-4">
              <label>Role</label>
              <Input
                onChange={handleChange}
                name="role"
                type="select"
                autoComplete="disabled"
                value={form.role || user?.role}
                className="form-control"
                options={[
                  "Store Owner",
                  "Receptionist",
                  "Sales Agent",
                  "Store Keeper",
                ]}
                // placeholder="Role"
              >
                {[
                  "Store Owner",
                  "Receptionist",
                  "Sales Agent",
                  "Store Keeper",
                ].map((role, i) => (
                  <option selected={user?.role === role} key={i}>
                    {role}
                  </option>
                ))}
                {/*  <option value="Lab Technologist" /> */}
              </Input>
            </div>
            <div className="col-md-4 col-lg-4">
              <CustomTypeahead
                label="Default store"
                options={options}
                // defaultSelected={["Main Store"]}
                labelKey="storeName"
                onChange={(val) => {
                  if (val) {
                    handleTypeaheadChange(val[0]);
                  }
                }}
                defaultInputValue={options[0]?.storeName || ""}
                onInputChange={(val) => {
                  console.log(val);
                }}
              />
            </div>
            <div className="col-md-4 col-lg-4">
              <Label>Assign Store</Label>
              <Typeahead
                id="basic-typeahead-multiple"
                labelKey="branch_name"
                multiple
                onChange={(e) => {
                  setMultiSelections();
                  setForm({
                    ...form,
                    store:
                      form.store.length > 0 && e[0].storeName !== form.store
                        ? `${form.store},${e[0].storeName}`
                        : e[0].storeName,
                  });
                  console.error({ ZELENZKYY: e, form });
                }}
                onInputChange={(val) => {
                  console.error({ ZELENZKYY: val.toString() });
                }}
                options={options}
                defaultInputValue={options[0]?.store || ""}
                placeholder="Choose several store..."
                clearButton={true}
                selected={multiSelections}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <Row>
              {form.roles.map((role) => (
                <Col md={3}>
                  <Checkbox
                    onChange={({ target: { value } }) => {
                      console.error(value);
                      setForm((p) =>
                        p.checked.includes(value)
                          ? {
                              ...p,
                              checked: [
                                ...p.checked.filter((rl) => rl !== value),
                              ],
                            }
                          : { ...p, checked: [...p.checked, value] }
                      );
                    }}
                    name="role"
                    label={role}
                    value={role}
                    checked={form.checked.includes(role)}
                  />
                </Col>
              ))}
            </Row>
          </FormGroup>
          <Row>
            <Col md={12} className="text-center">
              <CustomButton
                color="success"
                size="sm"
                loading={loading}
                onClick={(e) => handleSubmit(e)}
              >
                <MdCheck size={20} fontWeight="bold" />
                Save changes
              </CustomButton>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </CustomCard>
  );
}
