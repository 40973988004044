import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Row, Table } from "reactstrap";
import CustomButton from "../../app/components/Button";
import CustomCard from "../../components/CustomCard";
import SideBar from "../Account/SideBar"

export default function Account() {
    const _form = {
        expenditure_type: "",
        date: "",
        select_source_account: "",
        collected_by: "",
        total_amount: "",
        mode_of_payment: "",
        narrition: "",
    }

    const [form, setForm] = useState(_form)
    const [data, setData] = useState([])

    const handleChange = ({ target: { name, value } }) => {
        setForm((p) => ({ ...p, [name]: value }))
    }

    return (
        <div>
            {/* <SideBar /> */}
            <Row>
                <CustomCard header="Expenditure Form">

                    <Container>
                        <Card>
                            <Row>
                                <CardBody>
                                    <Row>
                                        <Col md={6}>
                                            <Label>Expenditure Type</Label>
                                            <Input type="text" name="expenditure_type"
                                                value={form.expenditure_type}
                                                onChange={handleChange} />
                                        </Col>
                                        <Col md={6}></Col>
                                        <Col md={4}>
                                            <Label>Date:</Label>
                                            <Input type="date" name="date"
                                                value={form.date}
                                                onChange={handleChange} />
                                        </Col>
                                        <Col md={4}>
                                            <Label>Select Source Account:</Label>
                                            <Input type="text" name="select_source_account"
                                                value={form.select_source_account}
                                                onChange={handleChange} />
                                        </Col>
                                        <Col md={4}>
                                            <Label>Collected By</Label>
                                            <Input type="text" name="collected_by"
                                                value={form.collected_by}
                                                onChange={handleChange} />
                                        </Col>
                                        <Col md={4}>
                                            <Label>Total Amount:</Label>
                                            <Input type="text" name="total_amount"
                                                value={form.total_amount}
                                                onChange={handleChange} />
                                        </Col>
                                        <Col md={4}>
                                            <Label>Mode of Payment:</Label>
                                            <Input type="text" name="mode_of_payment"
                                                value={form.mode_of_payment}
                                                onChange={handleChange} />
                                        </Col>
                                        <Col md={4}>
                                            <Label>Narration</Label>
                                            <Input type="text" name="narration"
                                                value={form.narrition}
                                                onChange={handleChange} />
                                        </Col>
                                    </Row>
                                    <center>
                                        <CustomButton
                                            className="mt-2"
                                        >
                                            save
                                        </CustomButton>
                                    </center>
                                </CardBody>
                            </Row>
                            <Row>
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <th>Delete</th>
                                            <th>Descriptyion</th>
                                            <th>Accoount</th>
                                            <th>Collected By</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Row>
                        </Card>
                    </Container>
                </CustomCard>
            </Row>
        </div>
    )
}