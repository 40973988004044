// import { Button } from "bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Input, Label, Card, CardBody, Table } from "reactstrap";
import { _fetchApi, _postApi } from "../../redux/actions/api";
// import { getCustomers } from "../../redux/actions/customer";
import { getPurchasedItems, sellItem } from "../../redux/actions/purchase";
// import { useState } from "react/cjs/react.production.min";
import CustomTypeahead from "../../components/CustomTypeahead";
import CustomScrollbar from "../../components/CustomScrollbar";
// import Button from "../../app/components/Button";
import CustomButton from "../../app/components/Button";
import { getStoresList } from "../../redux/actions/stores";
import { TRANSACTION_TYPES } from "../../constants";
import { saveTransaction } from "../../redux/actions/transactions";
import moment from "moment";
import { v4 as UUIDV4 } from "uuid";
import { saveCustomerTxnToCache } from "../../redux/actions/customer";
import CustomCard from "../../components/CustomCard";
import SearchBar from "../components/SearchBar";
import { _customNotify, _warningNotify } from "../../redux/helper";
import { formatNumber } from "../../app/utilities";

function Form() {
  let _form = {
    qtySold: "",
    price: 0,
    qty: 0,
  };
  const dispatch = useDispatch();
  const [sold, setSold] = useState({});
  const [data, setData] = useState([]);
  const itemList = useSelector((state) => state.purchase.purchaseList);
  // const [usersList, setUsersList] = useState([]);

  const [agent, setAgent] = useState({});
  // const [selected_store, setSelected_store] = "Show All Stores";
  const { activeBusiness, user } = useSelector((state) => state.auth);
  const { storeList } = useSelector((state) => state.stores);
  const [, setSubmitting] = useState(false);
  const [, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [filtered, setFiltered] = useState([]);

  const getPurchaseList = useCallback(() => {
    // alert(activeStore)
    dispatch(
      getPurchasedItems(agent.branch_name, () => console.log("Loading..."))
    );
  }, [dispatch, agent]);

  const handleSearch = useCallback(() => {
    let list = itemList.filter((item) => item.storeName === agent.branch_name);
    setFiltered(
      searchTerm.length > 2 && itemList.length
        ? list.filter((item) => {
            return item.item_name
              ?.toLowerCase()
              ?.includes(searchTerm.toLowerCase());
          })
        : list
    );
  }, [itemList, searchTerm, agent]);

  useEffect(() => {
    handleSearch();
    getPurchaseList();
  }, [getPurchaseList, handleSearch]);

  // const getStores = useCallback(() => {
  //   setLoading(true);
  //   dispatch(
  //     getStoresList(
  //       () => setLoading(false),
  //       () => setLoading(false)
  //     )
  //   );
  // }, [dispatch]);

  const checkout = (data, callback = (f) => f) => {
    console.log("Called checkout...........", data);
    // setIsNewCustomerModalOpen(false);

    setLoading(true);
    let receiptNo = moment().format("YYMDhms");
    // let totalAmount = parseFloat(form.price);
    // let sumIndividualDiscount = data.reduce(
    //   (a, b) => a + parseFloat(b.discount),
    //   0
    // )
    let totalDiscount = 0;
    let txn = [];
    data.forEach((item, index) => {
      // const { item, agent, store } = dt;
      // let lastIndex = data.length - 1;
      let itemList = data.map((item) => item.item_name).join(", ");
      txn.push({
        _id: UUIDV4(),
        source: "STORE",
        // source: item.branch_name,
        branch_name: item.storeName,
        product_code: item.item_code,
        dr: parseFloat(item.selling_price),
        amount: parseFloat(item.selling_price),
        selling_price: item.selling_price,
        cr: 0,
        business_bank_acc_no: activeBusiness.id,
        destination: "CASH",
        // quantity_sold: dt.qtySold,
        quantity: item.qtySold,
        description: item.item_name,
        category: item.item_category,
        receiptNo,
        discount: 0,
        // customerId: agent.id,
        // clientAccount: agent.accountNo,
        // customerName: agent.branch_name,
        transaction_type: "NEW_SALE",
        amountPaid: item.selling_price,
        totalAmount: item.selling_price,
        modeOfPayment: "CASH",
        imeiText: item.imeiText,
        imeiImage: item.imeiImage,
        truckNo: item.truckNo,
        waybillNo: item.waybillNo,
        itemList,
        txn_type: "Full Payment",
        supplier_code: item.supplier_code,
        supplierName: item.supplierName,
        supplier_name: item.supplier_name,
        item_id: item.item_id,
        salesFrom: item.storeName,
        item_code: item.item_code,
        expiry_date: item.expiry_date,
        expiring_date: item.expiry_date,
      });
    });
    // console.error({ selectedCustomer });
    saveTransaction(
      txn,
      () => {
        setLoading(false);
        _customNotify("Submitted");
        callback();
        // setData([]);
        // saveCustomerTxnToCache(agent, data, 0);
        // sellItem(txn, () => {
        //   history.push(
        //     `/app/sales?tab=Make Sales&transaction_id=${receiptNo}&customerName=${
        //       selectedCustomer.accName || form.customerName
        //     }`
        //   );
        // });

        // if (selectedCustomer.accountNo) {
        //   chargeCustomer(selectedCustomer.accountNo, totalAmount);
        // }
      },
      (err) => {
        setSubmitting(false);
        _warningNotify("An error occured", err);
      },
      TRANSACTION_TYPES.NEW_SALE
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // alert(JSON.stringify(data));
    const arr = [];
    data.forEach((element) => {
      console.log({ element });
      arr.push({
        ...element,
        agent,
      });
    });
    if (data.length < 1) {
      _warningNotify("Form is empty");
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else if (parseInt(data[0].qtySold) > parseInt(data[0].quantity)) {
      _warningNotify("Sold items exceeded avaible items in the store");
    } else {
      console.log({ data });
      checkout(arr, () => {
        getPurchaseList();
      });
    }
  };
  const handleSearchTermChange = (value) => {
    // console.log(value);
    setSearchTerm(value);
  };

  return (
    <CustomCard className="shadow">
      {JSON.stringify({ filtered, sold })}
      <CardBody>
        <Row>
          <Col md="6">
            <Label>Select Agent's store</Label>
            <CustomTypeahead
              allowNew={true}
              placeholder="Search agent's POS"
              labelKey="branch_name"
              clearButton
              options={storeList.filter(
                (st) => st.branch_name !== user.busName
              )}
              onInputChange={(v) => {
                if (v.length) {
                  console.log(v, "KDDDDDDDK");
                }
              }}
              onChange={(v) => {
                if (v.length) {
                  setAgent(v[0]);
                  console.log(v[0], "KDDDDDDDK");
                }
              }}
            />
          </Col>

          <Col md="6"></Col>
        </Row>

        <Row>
          <Col md={6}>
            <SearchBar
              filterText={searchTerm}
              onFilterTextChange={handleSearchTermChange}
              placeholder="Search item by name or code"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Table className="mt-3 table table-bordered">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Product</th>
                  <th>Model</th>
                  <th>Qty</th>
                  <th>Price</th>
                  <th>Total</th>
                  <th>Sold</th>
                </tr>
              </thead>
              <tbody>
                {filtered &&
                  filtered
                    .filter((item) => item.storeName === agent.branch_name)
                    .map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.item_name}</td>
                        <td>{item.model}</td>
                        <td className="text-center">{item.quantity}</td>
                        <td className="text-right">
                          {formatNumber(item.selling_price)}
                        </td>
                        <td className="text-right">
                          {parseInt(sold[index]) > 0
                            ? formatNumber(
                                parseInt(sold[index]) *
                                  parseFloat(item.selling_price)
                              )
                            : 0}
                        </td>
                        <td style={{ width: 100 }}>
                          <Input
                            type="number"
                            color="success "
                            size="4"
                            value={item.qtySold}
                            onChange={({ target: { value } }) => {
                              setData((p) => [
                                { ...p, ...item, qtySold: value },
                              ]);

                              setSold((p) => ({
                                ...p,
                                [index]: sold[index] + value,
                              }));
                            }}
                          />
                        </td>
                        <td>
                          <CustomButton
                            color="success "
                            size="sm"
                            onClick={(e) => {
                              handleSubmit(e);
                            }}
                          >
                            Save
                          </CustomButton>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        {/* <Row>
          <Col className="text-center">
            <CustomButton
              color="success "
              size="lg"
              onClick={(e) => handleSubmit(e)}
              loading={loading}
            >
              Submit
            </CustomButton>
          </Col>
        </Row> */}
      </CardBody>
    </CustomCard>
  );
}
export default Form;
